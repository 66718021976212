import { Objects } from '../models/objects';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ServiceSubjectTimeProvide } from 'src/app/shared/tools/service-subject-provider.tools';
import { ServiceSubjectTime } from 'src/app/shared/tools/service-subject-time.tools';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReglasCancelacionAutoService {

    subjetGetListZones: ServiceSubjectTime<Objects.GetReglaCancelacionRequest, Objects.GetReglaCancelacionResponse>;
    constructor(
      serviceSubject: ServiceSubjectTimeProvide) {
        this.subjetGetListZones = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getReglaCancelacionAuto}`);
      }

      GetReglas(request: Objects.GetReglaCancelacionRequest, forceRefresh: boolean = false): Observable<Objects.GetReglaCancelacionResponse> {
        return this.subjetGetListZones._httpGet(request, {force: forceRefresh, spinner: false});
      }
}
