import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout.component';
import { RouterModule } from '@angular/router';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [MainLayoutComponent, NavMenuComponent, NavHeaderComponent],
    imports: [CommonModule, RouterModule, MatMenuModule, MatListModule, MatTableModule, MatExpansionModule, MatSnackBarModule, FormsModule, MatProgressSpinnerModule],
})
export class MainLayoutModule {}
