import { Directive} from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
    selector: '[trimValidation]',
    providers: [{provide: NG_VALIDATORS, useExisting: TrimValidationDirective, multi: true}]
})
export class TrimValidationDirective implements Validator {
    constructor(){}

    validate(control: AbstractControl): ValidationErrors | null {
        return  this.esValido(control.value)(control);
    }  
    
    esValido(input: string): ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          const forbidden = input ? input.trim() == '' : false;
          return forbidden ? {trimValidator: {value: control.value}} : null;
        };
    }
}