<div class="modal__contanier">
  <div class="modal__header">
    <h2>Recibir solicitud de análisis</h2><a href="javascript:void(0);" [routerLink]="" [mat-dialog-close]="false"><em class="bi bi-x-lg"></em></a>
  </div>
  <div class="modal__content">
    <div class="msn msn--vertical msn--success">
      <span class="msn__icon">
        <em class="bi bi-check-circle-fill"></em>
      </span>
      <span class="msn__text">El frasco <b>{{data.muestra.muestraId}}</b> ha quedado ingresado en el sistema del laboratorio.</span>
    </div>
    <div class="d-flex justify-content-center align-items-start my-4 flex-column flex-md-row">
      <div class="definition definition--vertical" *ngIf="muestra">
        <div class="definition__visual"><em class="ico ico-sample"></em></div>
        <div class="definition__terms"><small>Anota el siguiente número de laboratorio en el frasco</small><strong>{{muestra.correlativo }}</strong></div>
      </div>
      <div class="definition definition--vertical">
        <div class="definition__visual"><em class="bi bi-inbox-fill"></em></div>
        <div class="definition__terms" *ngIf="!data.nuevoLote">Coloca el frasco en el siguiente lote de análisis<small></small><strong>{{data.muestra.loteId}}</strong></div>
        <div class="definition__terms" *ngIf="data.nuevoLote">Anota el siguiente número en un nuevo lote de análisis vacío<small></small><strong>{{data.muestra.loteId}}</strong></div>
      </div>
      <div class="definition definition--vertical" *ngIf="data.nuevoLote">
        <div class="definition__visual"><em class="bi bi-inbox"></em></div>
        <div class="definition__terms"><small>Coloca el frasco en el nuevo lote.</small></div>
      </div>
    </div>
    <div class="alert alert--info justify-content-center">
      <div class="alert__message" *ngIf="nroFrascosPendientes>0"><span>Aún quedan {{nroFrascosPendientes}} frascos de esta solicitud de análisis por ingresar</span></div>
      <div class="alert__message" *ngIf="nroFrascosPendientes===0"><span>{{nroFrascosPendientes}} frascos de esta solicitud de análisis por ingresar</span></div>
    </div>
  </div>
  <div class="modal__foot flex-column justify-content-center align-items-center flex-md-row justify-content-md-end flex-nowrap">
    <a class="link order-1 order-md-0 mb-3 mb-md-0" [routerLink]="" (click)="verLoteAnalisis()">VER LOTE DE ANÁLISIS</a>
    <a class="link ms-md-3 order-2 order-md-1 mb-3 mb-md-0" [routerLink]="" (click)="verSolicitud()">VER SOLICITUD DE ANÁLISIS</a>
    <a class="link ms-md-3 order-0 order-md-3 mb-3 mb-md-0" [routerLink]="" (click)="ingresarOtroFrasco()">INGRESAR OTRO FRASCO</a>
  </div>
</div>
