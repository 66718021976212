export namespace Objects {

    export enum Role {
        Admin = 'Admin',
        Usuario = 'Usuario'
    }

    export interface Pagination {
        page: number;
        pageSize: number;
        totalElements: number;
    }

    export interface Faena {
        faenaId: string;
        nombre: string;
        numeroSap: string;
        transporte: string;
        rubro?: string;
        direccion?: string;
    }

    export interface UsuarioAws {
        email: string;
        nombre: string;
        userId: string;
        rut: string;
    }

    export interface Dependency {
        laboratorioId: string,
        nombre: string,
        lubricantes: string
    }

    export interface UserInfo {
        rut: string;
        phone: string;
        nombre: string;
        userId: string;
        email: string;
        apellido: string;
        dependency: Dependency[];
        roles: string[];
        firma?:{
            url?:string;
            nombre?:string;
        }
    }

    export interface ReglaCancelacionEnsayo{
        ensayo:string;
        reglasId:string;
        descripcion:string;
        condicion:{
            isNumeric:boolean;
            operador:string;
            valor:string;
        }
    }

    export interface GetReglaCancelacionResponse extends Response<ReglaCancelacionEnsayo[]> {}
    export interface GetReglaCancelacionRequest extends Request {}

    export interface UserProfile {
        applicationId: string;
        birthdate: Date;
        email: string;
        email_verified: boolean;
        family_name: string;
        given_name: string;
        middle_name: string;
        name: string;
        phone_number: string;
        preferred_username: string;
        roles: string[];
        sub: string;
        access_token: string;
        // UserInfo
        userInfo: UserInfo;
    }

    export interface Select {
        value: number;
        displayText: string;
    }

    export interface Request {
        version?: string;
    }

    export interface Response<T> {
        message: string;
        code: number;
        success: boolean;
        data: T;
        date?:Date;
        cacheReq?:any;
    }

    export interface ItemTable {
        index: number;
        name: string;
        value: string;
    }
    export interface RowTable {
        columns: ItemTable[];
    }
    export interface Filter {
        name: string;
        visible: boolean;
    }

    export interface FilterDefinition {
        pageName: string;
        filters: Filter[];
    }


    export interface SampleBoxData {
        clienteId: string,
        faenaId: string,
        usuario?: Usuario, // siempre el ultimo en registar muestra
        fechaCreacion: Date,
        fechaActualizacion: Date
        muestras: RegistroMuestra[];

    }
    export interface Lote {
        loteId:string;
        laboratorioId:string;
        usuario:Objects.Usuario;
        fecha:string;
        estado:string
        cliente:Cliente;
        muestras:RegistroMuestra[];
        traces:{
            fecha:string;
            estado:string;
        }[]
        clientes?: Cliente [];
        avance?: number;
        n_ensayos?:{
            completados:number;
            total:number;
        }
        repiteEnsayos?:boolean
      }
    export interface RegistroMuestra {
        componente: Objects.Componente;
        fechaMuestreo: string;
        usoTotalComponente?: string;
        unidadUsoTotalComponente?: string;
        usoCambioLubricante?: string;
        unidadUsoCambioLubricante?: string;
        rellenoDesdeUltimoCambio?: string;
        muestraId: string;
        lubricante?: Lubricante;
        planAnalisis: PlanAnalisis;
        otroLubricante: string;
        usuario?: Usuario;
        fechaIngreso?: string;
        correlativo?:string;
        estado?:string;
        observaciones?:string;
        muestraIdExt:string;
        componenteId:string;
        solicitudAnalisis?:string;
        traces?:{fecha:string,estado:string}[],
        resultados?:Resultado[];
        approverUser?: {
            nombre:string,
            userId:string,
            phone:string,
            email:string,
            firma?: {
                url?: string,
                nombre?: string
            }
        },
        cliente:{
            clienteId:string;
            nombre:string;
            numeroSap:string;
            rut:string;
            faena:{
                faenaId:string;
                nombre:string;
                numeroSap:string;
                rubro:string;
                direccion: string;
            }

        },
        repiteEnsayos?:boolean
    }

    export interface Cliente {
        clienteId:string;
        nombre:string;
        numeroSap:string;
        rut:string;
        faena:{
            faenaId:string;
            nombre:string;
            numeroSap:string;
            rubro:string;
            direccion: string;
        }

    }

    export interface PlanAnalisisEnsayos {
        planAnalisisId: string;
        code: string;
        nombre: string;
        ensayos: Ensayo[];
        tiposComponentes: TipoComponente[];
    }

    export interface Ensayo {
        ensayoId: string;
        code: string;
        nombre: string;
        checked?: boolean;
        muestras?:RegistroMuestra[]
        lotesId?:string[]
        stamp?: string;
        tipoDato?: TipoDato,
        result?:Resultado
    }

    export interface TipoDato {
        tipo: string,
        decimals: string,
    }
    export interface Resultado{
        ensayo:{
            code:string;
            ensayoId:string;
            nombre:string;
        },
        estado?:{status:string,color:string}
        fecha:string;
        hora:string;
        muestraId:string;
        resultadoId:string;
        usuario:Objects.Usuario;
        value:string;
        stamp?:string;

    }
    export interface Componente {
        clienteId: string,
        componenteId: string,
        descriptor:string;
        equipo?:Equipo;
        marca: string,
        modelo?: string,
        ubicacion?: string,
        solicitante: string,
        tipoComponente: TipoComponente;
        planAnalisis: PlanAnalisis;
        lubricante: Lubricante;
    }
    export interface TipoComponente {
        tipoComponenteId:string;
        nombre: string;
    }
    export interface Equipo {
        equipoId: string,
        tipoEquipo: {
            tipoEquipoId: string;
            nombre: string;
        },
        marca?: string;
        modelo?: string;
        descriptor?: string;
        alias?: [string];
        tags?: [string];
    }


    export interface TipoComponente {
        tipoComponenteId: string;
        nombre: string;
    }
    export interface Lubricante {
        lubricanteId: string;
        nombre: string;
        alias?: string[];
    }
    export interface PlanAnalisis {
        nombre: string;
        planAnalisisId: string;
    }

    export interface Usuario {
        userId: string;
        nombre: string;
    }

    export interface TipoEquipo{
        tipoEquipoId: string;
        nombre: string;
    }

    export interface LogInput{
        elemento: string;
        tipoElemento: string;
        subElemento?: string;
        tipoSubElemento?: string;
        valor?: any;
        accion: string;
        fecha?: string;
        usuario: string;
        usuarioId: string;
        comentario?: string;
        ambiente: string;
    }

    export interface LogRequest {
        log: LogInput;
    }

    export interface LogResponse extends Response<LogInput[]> {}

}
