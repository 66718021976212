import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitudAnalisisModels } from '../../models/solicitud-analisis.models';
import { Objects } from 'src/app/shared';

@Component({
  selector: 'app-receive-bottle-success',
  templateUrl: './receive-bottle-success.component.html',
  styleUrls: ['./receive-bottle-success.component.css']
})
export class ReceiveBottleSuccessComponent implements OnInit {
  nroFrascosPendientes:number=0;
  muestra:Objects.RegistroMuestra | undefined;
  lote:boolean = true;
  constructor(
    public dialogRef: MatDialogRef<ReceiveBottleSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {infoSolicitud:SolicitudAnalisisModels.SolicitudAnalisis, nuevoLote?:boolean,muestra:SolicitudAnalisisModels.RegistroMuestra}

  ) {

   }

  ngOnInit(): void {
    this.nroFrascosPendientes= this.data.infoSolicitud.solicitud.muestras.filter(m=>m.muestraId!==this.data.muestra.muestraId).filter(m=>{
      return !m.estado || m.estado.toLocaleUpperCase() === 'REGISTERED'
    }).length;

    this.muestra=  this.data.infoSolicitud.solicitud.muestras.find(m=>{
      return m.muestraId === this.data.muestra.muestraId
    })
  }


  ingresarOtroFrasco(){
      this.dialogRef.close('ingresar-otro-frasco');
  }

  verSolicitud(){
    this.dialogRef.close('ver-solicitud-analisis');
  }
  verLoteAnalisis(){
    this.dialogRef.close('ver-lote-analisis');
  }
}
