import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SolicitudAnalisisModels } from '../../models/solicitud-analisis.models';
import { Enums } from 'src/app/shared';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';

@Component({
  selector: 'app-receive-bottle-observation',
  templateUrl: './receive-bottle-observation.component.html',
  styleUrls: ['./receive-bottle-observation.component.css']
})
export class ReceiveBottleObservationComponent implements OnInit {
  approveDifference: boolean = false;
  muestra: SolicitudAnalisisModels.RegistroMuestra | undefined;
  selectedObservation: string = '0';
  observationOther: string = '';
  observationType: { name: string, value: string }[] = [
    {
      name: 'Sin Observaciones',
      value: '0'
    },
    {
      name: 'Frasco quebrado',
      value: '1'
    },
    {
      name: 'Volumen insuficiente',
      value: '2'
    },
    {
      name: 'Frasco no proporcionado por Copec',
      value: '3'
    },
    {
      name: 'otro',
      value: '4'
    },
  ];
  batchsOptions: { name: string, value: number, status: string }[] = [
    { name: 'lote 01 (20 muestras)', value: 1, status: 'enviado' },
    { name: 'lote 02 (12 muestras)', value: 2, status: 'enviado' },
    { name: 'lote 03 (14 muestras)', value: 3, status: 'pendiente' },
    { name: 'Nuevo lote', value: -1, status: 'nuevo' },
  ]
  selectedBatch: number | undefined;

  statusBatch: { [key: string]: string } =
    {
      'pending': Enums.EstadosLote.pending,
      'created': Enums.EstadosLote.created,
      'in_analysis': Enums.EstadosLote.in_analysis
    }
  constructor(
    public dialogRef: MatDialogRef<ReceiveBottleObservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { infoSolicitud: SolicitudAnalisisModels.SolicitudAnalisis, muestraId: string, esDeOtraSolicitud: boolean, batchsOptions: { status: string; value: number; name: string; selected?: boolean }[] },
    public authService: AuthenticationService

  ) {
    this.muestra = <SolicitudAnalisisModels.RegistroMuestra>this.data.infoSolicitud.solicitud.muestras.find(m => {
      return m.muestraId.replace('-', '').toLowerCase() === this.data.muestraId.toLowerCase()
    })
  }

  ngOnInit(): void {
    // console.log(this.data.batchsOptions);

    this.data.batchsOptions = this.data.batchsOptions.filter(l => l.status !== "reportado");

    this.data.batchsOptions.forEach(opt => {
      if (opt.selected) {
        this.selectedBatch = opt.value;
        this.batchChange();
      }
    });
  }
  batchChange() {
    if (this.muestra) {
      this.muestra.loteId = String(this.selectedBatch);
    }
  }

  recepcionarFrasco() {    
    if (this.selectedObservation !== '0' && this.muestra) {
      this.muestra.observaciones = this.selectedObservation === '4' ? this.observationOther : this.observationType.find(o => o.value === this.selectedObservation)?.name;
      this.muestra.estado = 'received_observations';
    }
    this.dialogRef.close(this.muestra)
  }





}
