<div class="modal__contanier">
  <div class="modal__header">
    <h2 id="modal_ingresar_frasco_title">Ingresar frasco</h2>
    <a href="javascript:void(0);" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content">
    <div class="d-flex justify-content-center align-items-start my-4 flex-column flex-md-row">
      <div class="definition definition--vertical">
        <div class="definition__visual"><em class="ico ico-sample"></em></div>
        <div class="definition__terms  align-items-start align-items-md-center text-md-center">
          <small>Número del frasco</small>
          <strong id="modal_ingresar_frasco_muestra_id">{{data.muestraId}}</strong></div>
      </div>
      <div class="definition definition--vertical">
        <div class="definition__visual"><em class="bi bi-building"></em></div>
        <div class="definition__terms  align-items-start align-items-md-center text-md-center">
          <strong id="modal_ingresar_frasco_cliente" >{{data.infoSolicitud.solicitud.cliente.razonSocial}}</strong><span>{{data.infoSolicitud.solicitud.cliente.faena.nombre}}</span></div>
      </div>
      <div class="definition definition--vertical">
        <div class="definition__visual"><em class="bi bi-file-post"></em></div>
        <div class="definition__terms  align-items-start align-items-md-center text-md-center">
          <small>Solicitud de análisis</small>
          <strong id="modal_ingresar_frasco_solicitud_numero">{{data.infoSolicitud.solicitud.solicitudAnalisisId}}</strong></div>
      </div>
    </div>
    <form class="form d-flex flex-column mb-4"  #form="ngForm">
      <div class="form__field mb-3">
        <label>Observaciones</label>
        <ng-select id="modal_ingresar_frasco_observaciones" class="ng-select" name="observation" placeholder="Seleccionar Observacion" [(ngModel)]="selectedObservation" [appendTo]="'body'">
          <ng-option *ngFor="let obs of observationType" [value]="obs.value" >{{obs.name}}</ng-option>
       </ng-select>
      </div>
      <div class="form__field  mb-3" *ngIf="selectedObservation === '4'">
        <label>Otras observaciones</label>
        <input id="modal_ingresar_frasco_otras_observaciones" class="input" type="text" name="observationOther"  maxlength="300" [(ngModel)]="observationOther" required #obsOther="ngModel">
        <div *ngIf="obsOther.invalid && (obsOther.dirty || obsOther.touched)" class="text-danger">
          <div *ngIf="obsOther.errors?.required">
                Campo requerido.
          </div>
        </div>
      </div>
      <div class="form__field">
        <label>Asignar a un lote</label>
        <ng-select [items]="data.batchsOptions" 
              id="selectBatch"
              name="selectBatch"
              placeholder="Seleccionar un lote"
              bindLabel="name"
              bindValue="value"
              groupBy="status"
              required
              [appendTo]="'body'"
              [(ngModel)]="selectedBatch"
              (change)="batchChange()"
              >
        </ng-select>
      </div>
    </form>
    <div class="alert alert--warning" *ngIf="data.esDeOtraSolicitud">
      <figure class="alert__figure"><em class="bi bi-exclamation-triangle-fill"></em></figure>
      <div class="alert__message w-100">
        <span>¡Cuidado! Ten en cuenta que este frasco pertenece a una<br>solicitud de análisis distina a la que estás recepcionando.</span>
        <section class="mt-3">
          <mat-checkbox [(ngModel)]="approveDifference" color="primary">Estoy consciente que esta muestra es de otra solicitud</mat-checkbox>
        </section>
      </div>
    </div>
  </div>
  <div class="modal__foot flex-column justify-content-center align-items-center flex-md-row justify-content-md-between flex-nowrap">
    <a id="modal_ingresar_frasco_volver" class="link link--icon order-1 order-md-0" [routerLink]="" [mat-dialog-close]="false">
      <em class="bi bi-arrow-left-short"></em>
      <span>Volver</span>
    </a>
    <button id="modal_ingresar_frasco_recepcion" class="btn btn--small btn--success ms-md-3 order-0 order-md-1 mb-3 mb-md-0" [routerLink]="" (click)="recepcionarFrasco()" [disabled]="data.esDeOtraSolicitud && !approveDifference || !selectedBatch || form.invalid">Recepcionar frasco</button>
  </div>
</div>
