import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SubscribersTools } from "../tools/subscribers.tools";
import { RequestCounterTools } from '../tools/request-counter.tools';

import { ClientsChoresModels } from "src/app/modules/maintainer-clients/models/clientsChores.model";
import { ClientsChoresService } from "src/app/modules/maintainer-clients/services/clientChoresService.services";

@Injectable({
    providedIn: 'root'
})
export class ClienteFaenaProvider {
    subsTools: SubscribersTools = new SubscribersTools();

    clientes_faenas: {
        data: ClientsChoresModels.Cliente[];
        reqCounter: RequestCounterTools;
        subject: Subject<ClientsChoresModels.Cliente[]>
    };

    clientes_faenas_list: ClientsChoresModels.Cliente[] = [];
    clientesFaenasFinish: boolean = false;
    clientesFaenasAux: ClientsChoresModels.Cliente[] = [];


    constructor(
        private service: ClientsChoresService,
    ){
        this.clientes_faenas={
            data:[],
            reqCounter:new RequestCounterTools(),
            subject: new BehaviorSubject(this.clientes_faenas_list)
        }
    }

    public getClientesFaenas(refresh: boolean = false) {
        let rq: ClientsChoresModels.GetClientsChoresRequest = {
            page_size: 500
        }
        if(!this.clientes_faenas.reqCounter.inProgress){
            this.clientesFaenas(rq, refresh);
        }
        return this.clientes_faenas.subject;
    }

    private clientesFaenas(rq: ClientsChoresModels.GetClientsChoresRequest, refresh: boolean = false){
        this.clientes_faenas.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('getClientesFaenasNumber', this.service.GetClientesFaenas(rq, {force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {
                if(key != "") {
                    rq.page_key = key;
                    this.clientesFaenas(rq);
                }else{
                    this.clientesFaenasFinish = true;
                    this.setClientesFaenasStorage(this.clientesFaenasAux);
                    this.clientesFaenasAux = [];
                }
                this.clientes_faenas.reqCounter.endRequest();
            }})
        .subscribe(r => {
            if(r.code == 200) {
                this.clientesFaenasAux = [...this.clientesFaenasAux, ...r.data.items]; 

                if(!this.clientesFaenasFinish || this.clientes_faenas.data.length == 0){
                    this.setClientesFaenasStorage(this.clientes_faenas.data.concat(r.data.items));
                }

                if(r.data.page_key){
                    key = r.data.page_key;
                }
            }
        }));
    }

    private setClientesFaenasStorage(sol: ClientsChoresModels.Cliente[]) {
        this.clientes_faenas.data = sol.sort((a, b) => {
            return (Number(b.deudorId) - Number(a.deudorId))
        });  
        this.clientes_faenas.subject.next(this.clientes_faenas.data);
    }

}