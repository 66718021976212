<header class="header">
  <a class="toggle header__btn" id="toggle" href="javascript:void(0);" (click)="handToggle();">
    <em class="bi bi-list"></em>
  </a>
  <span class="user__name ms-auto me-md-3 text-end">{{ this.authService.currentUserValue.userInfo.apellido }} {{ this.authService.currentUserValue.userInfo.nombre }} 
    <small class="d-block text-end">{{ this.authService.currentUserValue.userInfo.dependency[0]?.nombre}}</small>  </span>    
  <a href="javascript:void(0);" class="user header__btn" mat-button [matMenuTriggerFor]="menu" #menuTrigger>    
    <em class="bi bi-person-circle"></em>
  </a>


  <mat-menu #menu="matMenu">
    <mat-selection-list #shoes [multiple]="false">
      <mat-list-option>
        <mat-icon mat-list-icon>
          <em class="bi bi-person"></em>
        </mat-icon>
        <div mat-line>Profile</div>
      </mat-list-option>
      <mat-divider></mat-divider>
      <mat-list-option (click)="logOut()">
        <mat-icon mat-list-icon>
          <em class="bi bi-box-arrow-left"></em>
        </mat-icon>
        <div mat-line>Log out</div>
      </mat-list-option>
    </mat-selection-list>
  </mat-menu>
</header>
