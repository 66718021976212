import { environment } from 'src/environments/environment';
import { ServiceSubjectTime } from '../../../shared/tools/service-subject-time.tools';
import { StateSampleModels } from '../models/state-sample.models';
import { ServiceSubjectTimeProvide } from '../../../shared/tools/service-subject-provider.tools';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StateSampleService {
    
    subjetGetMuestras: ServiceSubjectTime<StateSampleModels.GetMuestraRequest,StateSampleModels.GetMuestraResponse>;
    subjetGetMuestrasAprobadas: ServiceSubjectTime<StateSampleModels.GetMuestrasPorEstadoRequest, StateSampleModels.GetMuestraAprobadasResponse>;
    subjetGetMuestrasEnAnalisis: ServiceSubjectTime<StateSampleModels.GetMuestrasPorEstadoRequest, StateSampleModels.GetMuestraAprobadasResponse>;

    subjetGetMuestraById: ServiceSubjectTime<StateSampleModels.GetMuestraByIdRequest,StateSampleModels.GetMuestraByIdResponse>;

    subjetGetPlans:ServiceSubjectTime<StateSampleModels.GetPlanesAnalisisRequest,StateSampleModels.GetPlanesAnalisisResponse>;

    constructor(
        serviceSubject: ServiceSubjectTimeProvide,

    ) {
        this.subjetGetMuestras = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);
        this.subjetGetMuestraById = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);
        this.subjetGetMuestrasAprobadas = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);
        this.subjetGetMuestrasEnAnalisis = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);
        this.subjetGetPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
    }

    GetMuestrasPorAprobar(request: StateSampleModels.GetMuestrasPorEstadoRequest, opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false, ignoreStore: false }): Observable<StateSampleModels.GetMuestraResponse> {        
        return this.subjetGetMuestras._httpGet(request, opts);
    }

    GetMuestrasAprobadas(request: StateSampleModels.GetMuestrasPorEstadoRequest, opts: { force: boolean, fnCallback?: () => {}, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false }): Observable<StateSampleModels.GetMuestraAprobadasResponse> {    
        return this.subjetGetMuestrasAprobadas._httpGet(request, opts);
    }
    
    GetMuestrasEnAnalisis(request: StateSampleModels.GetMuestrasPorEstadoRequest, opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false, ignoreStore: false }): Observable<StateSampleModels.GetMuestraAprobadasResponse> {        
        return this.subjetGetMuestrasEnAnalisis._httpGet(request, opts);
    }
    
    GetPlanesAnalisis(request: StateSampleModels.GetPlanesAnalisisRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<StateSampleModels.GetPlanesAnalisisResponse> {
        return this.subjetGetPlans._httpGet(request,opts)
    }

    GetMuestraPorId(request: StateSampleModels.GetMuestraByIdRequest, opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: true, spinner: false}): Observable<StateSampleModels.GetMuestraByIdResponse> {
        return this.subjetGetMuestraById._httpGet(request, opts);
    }

}