<div class="modal__contanier d-flex justify-content-between flex-column h-100" [class]="{'bloqueado': is_request_call }">
  <mat-spinner  matSuffix [diameter]="18" class="center" *ngIf="is_request_call" ></mat-spinner>
  <div class="modal__header align-items-center is-sticky">
    <h2 class="d-flex align-items-center modal__title">
      <em class="ico ico-sample-2 me-2"></em>
      <b id="modela_muestra_title">Muestra</b>
    </h2>
    <a href="#" (click)="close()" [routerLink]="" >
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content">
    <div class="d-flex justify-content-between gap-4 p-4">
      <dl class="description">
        <dd class="description__definition txt--lg txt--light txt--third mb-2">{{data.muestra.correlativo}}</dd>
        <dt class="description__term txt--gray">Nº laboratorio</dt>
      </dl>
      <dl class="description justify-content-end text-end">
        <dd class="description__definition txt--gray txt--black mb-2">{{data.muestra.cliente.nombre}}</dd>
        <dt class="description__term txt--gray">{{data.muestra.cliente.faena.faenaId}}</dt>
      </dl>
    </div>
    <div class="d-flex justify-content-between gap-4 px-4">
      <dl class="description">
        <dd class="description__definition txt--lg txt--light txt--action mb-2" (click)="verLote()">{{data.muestra.loteId}}</dd>
        <dt class="description__term txt--gray">Lote</dt>
      </dl>
    </div>
    <mat-accordion [hidden]="!visibleCollpase">
      <mat-expansion-panel hideToggle class="mat-elevation-z0" (opened)="sampleOpenState = true" (closed)="sampleOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <em *ngIf="!sampleOpenState" class="bi bi-plus-square-fill"></em>
            <em *ngIf="sampleOpenState" class="bi bi-dash-square-fill"></em>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex justify-content-between gap-4 mt-4">
          <dl class="description">
            <dd class="description__definition txt--normal txt--third">{{data.muestra.muestraId}}</dd>
            <dt class="description__term txt--gray">Frasco Copec</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--third">-</dd>
            <dt class="description__term txt--gray">Frasco cliente</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--action" (click)="verSolicitud()">{{data.muestra.solicitudAnalisis}}</dd>
            <dt class="description__term txt--gray">Solicitud</dt>
          </dl>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="bg-darkblue" [hidden]="!visibleCollpase">
      <mat-expansion-panel hideToggle class="mat-elevation-z0" (opened)="stepOpenState = true" (closed)="stepOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex justify-content-between align-items-center">
            <dl class="description">
              <dd class="description__definition txt--normal txt--white">
                {{model.fechaActual.fecha | date: 'dd-MM-yyyy, H:mm'}}
              </dd>
              <dt class="description__term txt--gray">{{model.enumFechas[model.fechaActual.tipo]}}</dt>
            </dl>
            <div>
              <em *ngIf="!stepOpenState" class="bi bi-plus-square-fill"></em>
              <em *ngIf="stepOpenState" class="bi bi-dash-square-fill"></em>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex justify-content-between flex-column gap-4 mt-4 mb-4">
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">{{model.registroFrasco.recibido ? (model.registroFrasco.recibido | date: 'dd-MM-yyyy, H:mm') : 'No asignado' }}</dd>
            <dt class="description__term txt--gray">Frasco recepcionado</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">{{this.model.registroFrasco.ingresadoLote ? (this.model.registroFrasco.ingresadoLote | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term txt--gray">Frasco ingresado al lote</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">{{model.registroFrasco.enviadoLaboratorio ? (model.registroFrasco.enviadoLaboratorio | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term txt--gray">Enviada al laboratorio</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">{{model.registroFrasco.analisisFinalizado ? (model.registroFrasco.analisisFinalizado | date: 'dd-MM-yyyy, H:mm') : '---' }}</dd>
            <dt class="description__term txt--gray">Análisis finalizado</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">---</dd>
            <dt class="description__term txt--gray">Almacenado</dt>
          </dl>
          <dl class="description">
            <dd class="description__definition txt--normal txt--white">---</dd>
            <dt class="description__term txt--gray">Disposición final</dt>
          </dl>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="plan pb-0" [hidden]="!visibleCollpase">
      <div class="plan__graph">
        <div class="ring-chart">
          <svg viewBox="0 0 36 36" class="circular-chart">
            <path class="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
            <path class="circle" [attr.stroke-dasharray]="percent+', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
            <g class="circle-chart__info">
              <text class="circle-chart__percent" x="18" y="23" alignment-baseline="central" text-anchor="middle" font-size="16">
                {{getFilteredEnsaysLength()}}
            </text>
                        </g>
          </svg>
        </div>
      </div>
      <div class="plan__header">
        <span class="txt--first txt--uppercase txt--md">{{data.muestra.planAnalisis?.nombre}}</span>
        <small class="txt--gray txt--sm">Muestras pendientes</small>
      </div>
    </div>
    <mat-accordion [hidden]="!visibleCollpase">
      <mat-expansion-panel id="modela_muestra_ensayos_expand" hideToggle class="mat-elevation-z0" (opened)="planOpenState = true" (closed)="planOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <em *ngIf="!planOpenState" class="bi bi-plus-square-fill"></em>
            <em *ngIf="planOpenState" class="bi bi-dash-square-fill"></em>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="grid grid--descriptions mt-4" *ngIf="load">
          <ng-container *ngFor="let ensayo of   model.planResultados[data.muestra.muestraId].ensayos" >

          <dl class="description" *ngIf="resultadoEnPlanActual(ensayo)">
            <dt class="description__term txt--first">{{ ensayo.nombre }}</dt>

            <dd class="description__definition txt--action d-flex justify-content-between" *ngIf="!ensayo.result">
              <div class="badge badge--test badge--first" [ngClass]="{'clickable':isAdmin}"
                (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-play-fill"></em>
                <span>iniciar</span>
              </div>
              <div class="d-flex justify-content-center">
                <span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span>
              </div>
            </dd>
            <dd class="description__definition txt--action d-flex justify-content-between"
              *ngIf="ensayo.result && ensayo.result.stamp! == 'no_iniciado'">
              <div class="badge badge--test badge--first" [ngClass]="{'clickable':isAdmin}"
                (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-play-fill"></em>
                <span>iniciar</span>
              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span></div>
            </dd>
            <dd class="description__definition txt--warning d-flex justify-content-between"
              *ngIf="ensayo.result && ensayo.result.stamp! == 'por_repetir'">
              <div class="badge badge--test badge--first" [ngClass]="{'clickable':isAdmin}"
                (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-play-fill"></em>
                <span>iniciar</span>
              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span></div>
            </dd>
            <dd class="description__definition txt--warning clickable d-flex justify-content-between"
              *ngIf="ensayo.result && ensayo.result.stamp! == 'falta_iniciar'">
              <div class="badge badge--test badge--orange" (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-pause-fill"></em>
                <span *ngIf="ensayo.tipoDato?.tipo !== 'text'">
                  {{model.traduccion.resultadoEnsayo[ensayo.result.value] || ensayo.result.value}} (falta iniciar)
                </span>
              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span></div>
            </dd>
            <dd class="description__definition txt--warning d-flex justify-content-between"
              *ngIf="ensayo.result && ensayo.result.stamp! == 'iniciado'">
              <div class="badge badge--test badge--orange clickable" (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-pause-fill"></em>
                <span *ngIf="ensayo.tipoDato?.tipo !== 'text'">
                  Iniciado
                </span>
              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span></div>
            </dd>
            <dd class="description__definition d-flex justify-content-between"
              *ngIf="ensayo.result && ensayo.result.stamp! == 'no_realizable'">
              <div class="badge badge--test">
                <em class="bi bi-stop-fill"></em>
                <span>No realizable</span>
              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--action border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResult(data.muestra,ensayo)">R</span></div>
            </dd>
            <dd class="description__definition d-flex justify-content-between"
              *ngIf="ensayo.result && ['falta_iniciar','iniciado','no_realizable','no_iniciado','por_repetir'].indexOf(ensayo.result.stamp || 'iniciado') === -1">
              <div class="badge badge--test clickable" (click)="showModalResultEntry(ensayo)">
                <em class="bi bi-check"></em>
                <span *ngIf="ensayo.result.value && ensayo.result.value.length<10 ||  ensayo.tipoDato?.tipo !== 'text'">
                  {{model.traduccion.resultadoEnsayo[ensayo.result.value] || ensayo.result.value}}

                </span>

              </div>
              <div class="d-flex justify-content-center"><span class="ms-2 ds-chip ds-chip--warning border-0"
                  *ngIf="data.muestra && ensayo && sampleTools.checkRepeatResultLastLoaded(data.muestra,ensayo)">R</span></div>

            </dd>
          </dl>
        </ng-container>

        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="modal__foot justify-content-between gap-4 p-0 pt-4" >
    <div>
      <button id="modela_muestra_ver_informe" class="btn btn--small btn--second" (click)="getPdf()" *ngIf="data.muestra.estado=='reported'" >VER INFORME</button>
    </div>
    <div>
      <button id="modela_muestra_editar_muestra" class="btn btn--small btn--first" (click)="edit()">EDITAR MUESTRA</button>

    </div>
  </div>
</div>
