import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleModels } from 'src/app/modules/sample/models/sample.models';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { SolicitudAnalisisModels } from '../../models/solicitud-analisis.models';
import { ComponenteService } from '../../services/componente.services';
import { SolicitudAnalisisService } from '../../services/solicitud-analisis.services';
import { CreateAnalysisRequestSuccessComponent } from '../create-analysis-request-success/create-analysis-request-success.component';
import { Objects } from 'src/app/shared';
import { SnackBarService } from '../../../../shared/services/snackBar.service';

@Component({
    selector: 'app-add-bottle',
    templateUrl: './add-bottle.component.html',
    styleUrls: ['./add-bottle.component.css']
})
export class AddBottleComponent implements OnInit {
    todaysdate=new Date();
    model: any = {
        componentes: [],
        muestra: {
            componente: undefined,
            muestraId: undefined,
            fechaMuestreo: undefined
        },
        validation:{muestraId:{isValid:true}}
    } 

    constructor(
        public dialogRef: MatDialogRef<AddBottleComponent>,
        private service: SolicitudAnalisisService,
        private authService: AuthenticationService,
        private componentService: ComponenteService,
        public dialog: MatDialog,
        private snackSrv:SnackBarService,
        @Inject(MAT_DIALOG_DATA) public data:
            {
                solicitud: SolicitudAnalisisModels.Solicitud,
            }) {

    }

    ngOnInit(): void {
        this.load_components();
    }

    load_components(): void {
        this.componentService
            .GetComponentes({ clienteId: this.data.solicitud.cliente.clienteId, faenaId: this.data.solicitud.cliente.faena.faenaId })
            .subscribe(response => {
                this.model.componentes = response.data;
            });
    }

    add_sample(): void {       
        this.model.muestra.muestraId=this.model.muestra.muestraId.toUpperCase();
        let newSample=<Objects.RegistroMuestra>this.new_sample();

       

        let promises:Promise<SolicitudAnalisisModels.SetMuestraValidResponse>[]=[];
            promises.push(this.service.setMuestraValid({ 
                clienteId:this.data.solicitud.cliente.clienteId,
                faenaId:this.data.solicitud.cliente.faena.faenaId,
                muestraId:newSample.muestraId
            }).toPromise())        

        Promise.all(promises).then(validationResponses=>{
            if(validationResponses.filter(muestra=>!muestra.data.isValid).length>0){
                validationResponses.forEach((err,index)=>{
                    if(!err.data.isValid){
                            this.model.validation.muestraId.isValid=false;
                    }
                });
            }else{    

                this.data.solicitud.muestras.push(newSample);
                let request = {
                    solicitudAnalisisId: this.data.solicitud.solicitudAnalisisId,
                    clienteId: this.data.solicitud.cliente.clienteId,
                    faenaId: this.data.solicitud.cliente.faena.faenaId,
                    userId: this.authService.currentUserValue.userInfo.email ?? '',
                    
                    muestras: this.data.solicitud.muestras
                };   
                this.registerSolicitudAnalisis(request);
            }
        })

    }

    change_muestraId(){
        this.model.validation.muestraId.isValid=true;

    }

    private registerSolicitudAnalisis(request: { solicitudAnalisisId: string; clienteId: string; faenaId: string; userId: string; muestras:Objects.RegistroMuestra[]; }) {
        this.service.RegisterSolicitudAnalisis(request)
            .then(response => {
                if (response.success && response.code == 200) {
                    this.dialogRef.close();
                    
                    this.snackSrv.success("Frasco ingresado correctamente")
                    // this.dialog.open(CreateAnalysisRequestSuccessComponent, {
                    //     panelClass: 'ds-modal',
                    //     data: {
                    //         numero: response.data.solicitudAnalisisId,
                    //         cliente: this.model.cliente?.nombre,
                    //         faena: this.model.faena?.nombre,
                    //         muestras:request.muestras
                    //     }
                    // });

                }else{

                    this.snackSrv.warning("Ocurrio un problema");
                    console.error("Ocurrio un problema al ingresar frasco:",response)
                }
            });
    }

    new_sample(): SolicitudAnalisisModels.RegistroMuestra {
        return {
            componente: this.model.muestra.componente,
            lubricante: this.model.muestra.componente.lubricante,
            planAnalisis: this.model.muestra.componente.planAnalisis,
            fechaMuestreo: this.model.muestra.fechaMuestreo,
            muestraId: this.model.muestra.muestraId,
            otroLubricante: "",
            clienteId: this.data.solicitud.cliente.clienteId,
            muestraIdExt: "",
            componenteId: this.model.muestra.componente.componenteId,
            usuario: {
                rut: this.authService.currentUserValue.userInfo.rut,
                phone: this.authService.currentUserValue.userInfo.phone,
                apellido: this.authService.currentUserValue.userInfo.apellido,
                nombre: this.authService.currentUserValue.userInfo.nombre,
                userId: this.authService.currentUserValue.userInfo.userId,
                email: this.authService.currentUserValue.userInfo.email
            },
            cliente: {
                clienteId: this.data.solicitud.cliente.clienteId,
                nombre: this.data.solicitud.cliente.razonSocial,
                numeroSap: this.data.solicitud.cliente.numeroSap,
                rut: this.data.solicitud.cliente.rut!,
                faena: {
                    faenaId: this.data.solicitud.cliente.faena.faenaId,
                    nombre: this.data.solicitud.cliente.faena.nombre,
                    numeroSap: this.data.solicitud.cliente.faena.numeroSap,
                    rubro: this.data.solicitud.cliente.faena.rubro!,
                    direccion: this.data.solicitud.cliente.faena.direccion!
                }
            }
        }
    }

}
