<aside class="sidebar" id="sidebar" [ngClass]="{ collapsed: collapsed }">
  <div class="sidebar__search">
    <mat-spinner matSuffix [diameter]="18" [ngClass]="{ 'd-block': searching }" style="float: right; margin-left: 8px"></mat-spinner>
    <div class="finder">
      <input class="" type="text" placeholder="Buscar muestra" [(ngModel)]="muestraId" (keyup.enter)="getSample()" aria-label="Show an example snack-bar" [disabled]="searching"/>
      <button type="button" (click)="getSample()" *ngIf="!searching"></button>
    </div>
    <small class="sidebar__info mt-1 d-block mx-auto w-100 text-center">Busca por N° de frasco / laboratorio</small>
  </div>
  <nav class="sidebar__nav">
    <ul class="sidebar__list">
      <li class="sidebar__item" *ngIf="isVisible('home')">
        <a
          class="sidebar__link"
          href="javascript:void(0)"
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          routerLinkActive="active"
          [ngClass]="{ active: isActive('/home') }"
        >
          <em class="bi bi-house"></em>
          <span>Inicio</span>
        </a>
      </li>

      <li class="sidebar__item" *ngIf="isVisible('solicitud-analisis/recepcion')">
        <a id="recepcion" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['solicitud-analisis/recepcion']">
          <em class="bi bi-file-post"></em>
          <span>Recepción de solicitudes</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('solicitud-analisis/solicitudes')">
        <a id="solicitudes" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="'solicitud-analisis/solicitudes'">
          <em class="bi bi-file-earmark-medical"></em>
          <span>Solicitudes de análisis</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('lotes/list')">
        <a
          id="lotes"
          class="sidebar__link"
          href="javascript:void(0)"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['lotes/list']"
        >
          <em class="bi bi-inbox-fill"></em>
          <span>Lotes de análisis</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('lotes/list/analisis')">
        <a
          id="analisis"
          class="sidebar__link"
          href="javascript:void(0)"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['lotes/list/analisis']"
        >
          <em class="bi bi-droplet-half"></em>
          <span>En análisis</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('estado-muestra')">
        <a
          id="muestras"
          class="sidebar__link"
          href="javascript:void(0)"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['estado-muestra']"
        >
          <em class="bi bi-calendar2-minus-fill"></em>
          <span>Muestras</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('facturacion/documentos_ventas')">
        <a
          id="documentos_ventas"
          class="sidebar__link"
          href="javascript:void(0)"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="'facturacion/documentos_ventas'"
        >
          <em class="bi bi-receipt"></em>
          <span>Documentos de ventas</span>
        </a>
      </li>
      <li class="sidebar__item" *ngIf="isVisible('facturacion/informe_prefacturacion')">
        <a
          id="informe_prefacturacion"
          class="sidebar__link"
          href="javascript:void(0)"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="'facturacion/informe_prefacturacion'"
        >
          <em class="bi bi-card-list"></em>
          <span>Informe de prefacturación</span>
        </a>
      </li>
      <mat-accordion class="sidebar__item mat-elevation-z0" *ngIf="isVisible('mantenedor')">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header class="sidebar__link">
            <mat-panel-title>
              <em class="bi bi-filter-circle-fill"></em>
              <span>Mantenedores</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <li class="sidebar__item">
            <a id="clientes_faenas" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['mantenedor/clientes_y_faenas']">
              <em class="bi bi-file-earmark-medical-fill"></em>
              <span>Clientes y faenas</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a id="prioridades_clientes" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['clientes']">
              <em class="bi bi-people-fill"></em>
              <span>Prioridad clientes</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a
              id="clientes_administradores"
              class="sidebar__link"
              href="javascript:void(0)"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['mantenedor/clientes_administradores']"
            >
              <em class="bi bi-calendar2-minus-fill"></em>
              <span>Administradores clientes</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a
              id="usuarios_admintradores"
              class="sidebar__link"
              href="javascript:void(0)"
              routerLinkActive="active"
              [routerLink]="['mantenedor/usuarios_admintradores']"
            >
              <em class="bi bi-person-lines-fill"></em>
              <span>Usuarios Copec</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a
              id="protocolos"
              class="sidebar__link"
              href="javascript:void(0)"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['protocolos']"
            >
              <em class="bi bi-file-bar-graph-fill"></em>
              <span>Protocolos</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a
              id="lubricantes"
              class="sidebar__link"
              href="javascript:void(0)"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLink]="['lubricantes']"
            >
              <em class="bi bi-droplet-fill"></em>
              <span>Lubricantes</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a id="ensayos" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['analisis']">
              <em class="bi bi-tag-fill"></em>
              <span>Ensayos</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a id="laboratorios" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['laboratorios']">
              <em class="bi bi-geo-fill"></em>
              <span>Laboratorios</span>
            </a>
          </li>

          <li class="sidebar__item">
            <a id="comentarios" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['comentarios']">
              <em class="bi bi-sticky-fill"></em>
              <span>Comentarios</span>
            </a>
          </li>

          <li class="sidebar__item">
            <a id="instrumentos" class="sidebar__link" href="javascript:void(0)" (click)="openInstrumentos()">
              <em class="bi bi-sliders"></em>
              <span>Instrumentos</span>
            </a>
          </li>

          <li class="sidebar__item">
            <a id="precios" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['precios']">
              <em class="bi bi-coin"></em>
              <span>Precios de planes</span>
            </a>
          </li>

          <li class="sidebar__item">
            <a id="zonas" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['zonas']">
              <em class="bi bi-pin-map-fill"></em>
              <span>Zonas</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a id="componentes" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['componentes']">
              <em class="bi bi-arrow-repeat"></em>
              <span>Mover Componentes</span>
            </a>
          </li>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="sidebar__item mat-elevation-z0">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header class="sidebar__link">
            <mat-panel-title>
              <em class="bi bi-file-earmark-ruled"></em>
              <span>Reportes</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <li class="sidebar__item">
            <a id="reportes_historicos" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['reportes/historicos']">
              <em class="bi bi-clock-history"></em>
              <span>Resultados históricos</span>
            </a>
          </li>
          <li class="sidebar__item">
            <a id="reportes_componentes" class="sidebar__link" href="javascript:void(0)" routerLinkActive="active" [routerLink]="['reportes/componentes']">
              <em class="bi bi-cpu"></em>
              <span>Descargar reportes de componentes</span>
            </a>
          </li>
        </mat-expansion-panel>
      </mat-accordion>
    </ul>
  </nav>
  <div class="sidebar__footer">
    <a id="home" href="javascript:void(0)" [routerLink]="['/home']">
      <img src="assets/images/side-footer.svg" alt="copec Laboratorio Lubricantes" />
    </a>
  </div>
</aside>
