import { NgModule } from '@angular/core';
import { BlockSpecialCharactersDirective } from './directives/blockSpecialCharacters.directive';
import { BlockEmailSpecialCharactersDirective } from './directives/blockEmailSpecialCharacters.directive';
import { onlyFloat } from './directives/numbers/onlyFloat.directive';
import { NumericDirective } from './directives/numeric.directive';
import { ValidaMuestraIdDirective } from './directives/validacionMuestraId.directive';
import { RutValidationDirective } from './directives/validators/rutValidator.directive';
import { TrimValidationDirective } from './directives/validators/trim.directive';
import { NumericRangeDirective } from './numericRange';
import { UnicoNumeroEnvaseValidatorDirective } from './directives/unicoNumeroEnvase.directive';
import { MaximumValueDirective } from './directives/validators/maximumValueDirective.directive';
import { ValidarFechaDirective } from './directives/validators/validar-fecha.directive';
import { MinimumValueDirective } from './directives/validators/minimumValueDirective.directive';
import { MaximumDecimalDirective } from './directives/validators/maximumDecimalDirective.directive';
import { EmailValidationDirective } from './directives/validators/emailValidatorDirective.directive';
import { FormatLimitDirective } from './directives/report-format-limit/formatLimitReport.directive';
import { NameDirective } from './directives/nameDirective.directive';
import { IdDirective } from './directives/idDirective.directive';
import { PhoneDirective } from './directives/phoneDirective.directive';
import { StateBarComponent } from './components/state-bar/state-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectorStateComponent } from './components/selector-state/selector-state.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { LimitePersonalizadoDirective } from './directives/report-format-limit/limitePersonalizado.directive';
@NgModule({
    declarations: [
        NumericDirective,
        ValidaMuestraIdDirective,
        TrimValidationDirective,
        BlockSpecialCharactersDirective,
        BlockEmailSpecialCharactersDirective,
        NumericRangeDirective,
        onlyFloat,
        RutValidationDirective,
        UnicoNumeroEnvaseValidatorDirective,
        MaximumValueDirective,
        MinimumValueDirective,
        MaximumDecimalDirective,
        ValidarFechaDirective,
        EmailValidationDirective,
        FormatLimitDirective,
        LimitePersonalizadoDirective,
        NameDirective,
        IdDirective,
        PhoneDirective,
        StateBarComponent,
        SelectorStateComponent

    ],
    imports: [CommonModule, MatTooltipModule, NgbModule,MatSlideToggleModule,FormsModule],
    exports: [
        NumericDirective,
        ValidaMuestraIdDirective,
        TrimValidationDirective,
        BlockSpecialCharactersDirective,
        BlockEmailSpecialCharactersDirective,
        NumericRangeDirective,
        onlyFloat,
        RutValidationDirective,
        UnicoNumeroEnvaseValidatorDirective,
        MaximumValueDirective,
        MinimumValueDirective,
        MaximumDecimalDirective,
        ValidarFechaDirective,
        EmailValidationDirective,
        FormatLimitDirective,
        LimitePersonalizadoDirective,
        NameDirective,
        IdDirective,
        PhoneDirective,
        StateBarComponent,
        SelectorStateComponent
    ],
})
export class SharedModule {}
