import { OAuthStorage } from 'angular-oauth2-oidc';

export class LocalStorageOAuthStorage implements OAuthStorage {

    private needsLocal(key: string) {
        // console.log("needsLocal", key === 'nonce' || key === 'PKCE_verifier');
        return key === 'nonce' || key === 'PKCE_verifier';
    }

    getItem(key: string): string | null {
        // console.log("LocalStorageOAuthStorage get key =>", key)
        // console.log("LocalStorageOAuthStorage get value =>", localStorage.getItem(key))
        if (this.needsLocal(key)) {
            return localStorage.getItem(key);
        }
        return sessionStorage.getItem(key);
    }

    removeItem(key: string): void {
        // console.log("LocalStorageOAuthStorage del key =>", key)
        if (this.needsLocal(key)) {
            // return localStorage.removeItem(key);
        }
        return sessionStorage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        // console.log("LocalStorageOAuthStorage set key =>", key, value)
        if (this.needsLocal(key)) {
            return localStorage.setItem(key, value);
        }
        return sessionStorage.setItem(key, value);
    }
}
