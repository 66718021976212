import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SampleService } from '../../services/sample.services';
import { CancelEntryResultsComponent } from '../cancel-entry-results/cancel-entry-results.component';
import { Subscription } from 'rxjs';
import { SampleModels } from '../../models/sample.models';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';
import { Enums, Objects } from 'src/app/shared';
import { SampleViewModels } from '../../models/sample.view.models';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { StorageTools } from 'src/app/shared/tools/storage.tools';
import { ModalCancelAutoInfo } from '../modal-cancel-auto-info/modal-cancel-auto-info';

@Component({
    selector: 'app-manual-entry-results',
    templateUrl: './manual-entry-results.component.html',
    styleUrls: ['./manual-entry-results.component.css'],
})
export class ManualEntryResultsComponent implements OnInit {
    is_request_call: boolean = false;

    @ViewChild('analysisForm') analysisForm: any;
    subscription?: Subscription;
    saved: boolean = false;
    tryToSave: boolean = false;
    traduccion = {
        resultadoEnsayo: Enums.ResultadoGeneralEnsayo,
    };
    reglasCancelacionEnsayo: Objects.ReglaCancelacionEnsayo[]=[];
    model: SampleViewModels.ViewResultEntryModel = {
        manual: false,
        automaticoIniciado: false,
        automaticoDetenenido: false,
        canBeAutomatic: false,
        ensayo: {
            code: '',
            ensayoId: '',
            nombre: '',
            tipoDato: {
                decimals: '',
                tipo: '',
            },
        },
        inputValue: '',
        selectedValue: '',
        loading: false,
        analysis: {
            ensayoId: '',
            nombre: '',
            code: '',
            equipo: [],
            norma: '',
            tipoDato: {
                decimals: '',
                tipo: '',
            },
            limites: {
                inputMin: '1',
                inputMax: '10',
                minimo: '2',
                maximo: '9',
            },
            clasificacion: {
                subTipo: '',
                tipo: '',
            },
        },
        ensayoAutoIniciado: false,
        estado: 'no_iniciado',
        tipo: 'automatico',
        detailView: false,
        faltaIniciar: false,
        estadosResultado: Enums.StatusValueSample,
        tipoImg: false,
        tipoTexto: false,
        tipoValor: false,
        ensayoCancelado: false,
        doNotClose: false,
        ensayoPendienteIniciado: false,
        results: [],
        hideTable: false,
    };

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ManualEntryResultsComponent>,
        private service: SampleService,
        private authService: AuthenticationService,
        private eventSrv: EventEmitterService,
        private storage: StorageTools,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            numero: number;
            total: number;
            muestra?: SampleModels.RegistroMuestra;
            muestras: SampleModels.RegistroMuestra[];
            ensayo: SampleModels.Ensayo;
        }
    ) {
        if (data) {
            data.muestras =
                data.muestras?.sort((x, y) =>
                    parseInt(x.correlativo ?? '0') > parseInt(y.correlativo ?? '0')
                        ? 1
                        : parseInt(x.correlativo ?? '0') === parseInt(y.correlativo ?? '0')
                        ? 0
                        : -1
                ) || [];
            // console.log(data.muestras);
            if (data.ensayo) {
                if (data.muestras && data.muestras.length === 1) {
                    data.ensayo.result = this.getLastResult(data.muestras[0]);
                }
                this.model.ensayo = JSON.parse(JSON.stringify(data.ensayo));
                let TypesCanBeAutomatic = ['integer', 'float'];
                if (this.model.ensayo.tipoDato && TypesCanBeAutomatic.indexOf(this.model.ensayo.tipoDato.tipo) > -1) {
                    this.model.canBeAutomatic = true;
                    this.model.tipo = 'manual';
                } else {
                    this.setManualSpecial();
                }
            }
        }
    }

    ngOnInit(): void {
        this.model.loading = true;
        this.reglasCancelacionEnsayo = JSON.parse(this.storage.getItem('reglasCancelacion'))??[];
        if (!this.data.muestras && this.data.muestra) {
            this.data.muestras = [];
            this.data.muestras.push(this.data.muestra);
        }

        if (this.isMultiple || !this.data.numero || !this.data.total) {
            this.data.numero = 1;
            this.data.total = this.data.muestras.length;
            this.data.muestra = this.data.muestras[0];
        } else if (!this.data.muestra && this.data.muestras) {
            this.data.muestra = this.data.muestras[0];
        }

        if (this.lastResult!) {
            this.generateDetailView();
        }

        this.subscription = this.service.GetEnsayos({}).subscribe((response) => {
            if (response.code === 200) {
                this.model.analysis = response.data.find((ensayo) => ensayo.ensayoId === this.model.ensayo.ensayoId)!;
                this.model.loading = false;
            }
        });

        this.model.inputValue = this.lastResult?.value || '';
        this.model.selectedValue = this.lastResult?.value || '';
    }
    ngOnDestroy(): void {
        this.subscription ? this.subscription.unsubscribe() : '';
    }

    saveAndContinue() {
        this.is_request_call = true;
        this.tryToSave = true;
        this.model.loading = true;
        if (!this.analysisForm.invalid && this.model.analysis && this.data.muestra) {
            let value = this.tipoDeDato === 'boolean' ? this.model.selectedValue : this.model.inputValue;

            let request: SampleModels.SetResultadosRequest = {
                ensayo: this.data.ensayo,
                correlativo: this.data.muestra.correlativo?.toString() || '',
                muestraId: this.data.muestra.muestraId,
                resultadoId: this.data.ensayo.result?.resultadoId,
                usuario: this.authService.usuario,
                value: value,
                stamp: 'cargado',
                tipo: this.model.tipo,
            };

            this.model.doNotClose = true;

            this.service
                .SetResultados(request)
                .toPromise()
                .then((r) => {
                    //Promise.all(promises).then(r=>{
                    if (r.code === 200) {
                        this.saved = true;
                        this.model.loading = false;
                        let resultados = this.data.muestra?.resultados || [];
                        resultados.push(r.data);
                        this.setResults(resultados);
                        setTimeout(() => {
                            if (this.data.muestras[this.data.numero]) {
                                this.data.muestra = this.data.muestras[this.data.numero];
                                this.data.numero++;
                                this.model.doNotClose = false;
                                this.saved = false;
                                this.tryToSave = false;
                                this.model.inputValue = '';
                            } else {
                                this.dialogRef.close({
                                    status: 'saved',
                                    data: this.data,
                                    result: r.data,
                                    stamp: 'cargado',
                                    results: this.model.results,
                                });
                            }
                        }, 2000);
                    } else {
                        alert('ocurrio un problema');
                    }
                })
                .catch((e) => {
                    alert('ocurrio un problema');
                });
        } else {
            this.model.loading = false;
        }
    }

    get maximo(): number {
        return this.model.analysis && this.model.analysis.limites.maximo ? Number(this.model.analysis.limites.maximo) : 1;
    }
    get minimo(): number {
        return this.model.analysis && this.model.analysis.limites.minimo ? Number(this.model.analysis.limites.minimo) : 0;
    }

    get esTipoDatoNumerico(): boolean {
        return (
            (this.model.analysis && (this.model.analysis.tipoDato.tipo === 'integer' || this.model.analysis.tipoDato.tipo === 'float')) ||
            false
        );
    }

    get tipoDeDato() {
        return this.esTipoDatoNumerico ? 'numerico' : this.model.analysis?.tipoDato.tipo || '';
    }

    public cancelEntryResult() {
        if (!this.model.loading && !this.model.doNotClose) {
            // if( (!this.model.estado || this.model.estado === 'no_iniciado')  && !this.saved && this.tipoDeDato != 'image' && this.data.total > 1){
            if (this.isMultiple && this.data.numero > 1 && this.data.numero <= this.data.total) {
                const modal = this.dialog.open(CancelEntryResultsComponent, {
                    panelClass: 'ds-submodal',
                    disableClose: true,
                });

                modal.afterClosed().subscribe((response) => {
                    switch (response) {
                        case 'cancel':
                            this.dialogRef.close({ status: 'cancel', data: this.data, results: this.model.results });
                            break;
                        case 'continue':
                        default:
                            break;
                    }
                });
            } else {
                this.dialogRef.close({
                    status: this.saved ? 'saved' : '',
                    ensayoId: this.model.ensayo.ensayoId,
                    result: { value: this.model.inputValue },
                    stamp: this.model.estado,
                    results: this.model.results,
                    data: this.data,
                });
            }
        }
    }

    public setManual() {
        this.model.manual = true;
        this.model.automaticoDetenenido = false;
        this.model.ensayoAutoIniciado = false;
        this.model.automaticoIniciado = false;
        this.model.tipo = 'manual';
        this.model.hideTable = true;
        if (this.isMultiple) {
            this.data.muestra = this.data.muestras[this.data.numero - 1];
        }
    }

    private setManualSpecial() {
        if (!this.lastResult?.value) {
            // this.model.manual = true;
            this.model.automaticoDetenenido = false;
            this.model.ensayoAutoIniciado = false;
            this.model.automaticoIniciado = false;
            this.model.tipo = 'manual';
        }
    }

    public goAutomatic(): void {
        this.is_request_call = true;
        this.model.loading = true;

        this.model.automaticoIniciado = true;
        this.model.ensayoAutoIniciado = true;
        this.model.estado = 'iniciado';
        this.model.automaticoDetenenido = false;
        this.model.faltaIniciar = false;

        if (this.model.analysis) {
            let promises: Promise<SampleModels.SetResultadosResponse>[] = [];
            this.data.muestras.forEach((muestra) => {
                let request: SampleModels.SetResultadosRequest = {
                    ensayo: this.data.ensayo,
                    correlativo: muestra.correlativo?.toString() || '',
                    muestraId: muestra.muestraId,
                    //  resultadoId:this.getLastResult(muestra)?.resultadoId,
                    usuario: this.authService.usuario,
                    value: '',
                    stamp: this.model.estado,
                    tipo: 'automatico',
                };
                promises.push(this.service.SetResultados(request).toPromise());
            });

            Promise.all(promises)
                .then((r) => {
                    this.model.loading = false;
                    if (r[0].code === 200) {
                        this.saved = true;
                        this.data.ensayo.result = r[0].data;
                        this.setResults(r.map((rs) => rs.data).filter((data) => data));
                        setTimeout(() => {
                            this.dialogRef.close({
                                status: 'saved',
                                data: this.data,
                                result: r[0].data,
                                stamp: 'cargado',
                                results: this.model.results,
                            });
                        }, 2000);
                    } else {
                        alert('ocurrio un problema');
                    }
                })
                .catch((e) => {
                    alert('ocurrio un problema');
                })
                .finally(() =>{
                    this.is_request_call = false;
                });
        } else {
            this.model.loading = false;
            this.is_request_call = false;
        }
    }

    public stopAtomatic(): void {
        this.is_request_call = true;

        this.model.automaticoDetenenido = true;
        this.model.ensayoAutoIniciado = false;
        this.model.automaticoIniciado = false;
        this.model.detailView = false;
        this.model.estado = 'no_iniciado';

        this.model.loading = true;
        if (this.model.analysis) {
            let promises: Promise<SampleModels.SetResultadosResponse>[] = [];
            this.data.muestras.forEach((muestra) => {
                let request: SampleModels.SetResultadosRequest = {
                    ensayo: this.data.ensayo,
                    correlativo: muestra.correlativo?.toString() || '',
                    muestraId: muestra.muestraId,
                    resultadoId: this.getLastResult(muestra)?.resultadoId,
                    usuario: this.authService.usuario,
                    value: '',
                    stamp: 'no_iniciado',
                    tipo: 'automatico',
                };
                promises.push(this.service.SetResultados(request).toPromise());
            });
            Promise.all(promises)
                .then((r) => {
                    if (r[0].code === 200) {
                        this.model.loading = false;
                        this.saved = false;
                        this.data.muestras.forEach((muestra) => {
                            let results = muestra.resultados?.filter((r) => r.ensayo.ensayoId === this.data.ensayo.ensayoId);
                            results
                                ?.sort((a, b) => {
                                    if (a.resultadoId < b.resultadoId) {
                                        return -1;
                                    }
                                    if (a.resultadoId > b.resultadoId) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .pop();
                            let otherResults = muestra.resultados?.filter((r) => r.ensayo.ensayoId !== this.data.ensayo.ensayoId) || [];
                            muestra.resultados = otherResults?.concat(results || []);
                            this.eventSrv.sendResult(this.eventSrv.eventNames.UPDATED_SAMPLE, muestra);
                        });
                        this.data.ensayo.result = undefined;
                    } else {
                        alert('ocurrio un problema');
                    }
                })
                .catch((e) => {
                    alert('ocurrio un problema');
                })
                .finally(()=>{
                    this.is_request_call = false;
                });
        } else {
            this.model.loading = false;
            this.is_request_call = false;
        }
    }

    private setResults(results: SampleModels.Resultado[]) {
        if (!results || results.length < 1) {
            return;
        }
        this.model.results = results;
        this.data.muestras.forEach((m) => {
            let result = this.model.results.find((result) => result.muestraId === m.muestraId);
            m.resultados = m.resultados ? m.resultados : [];
            if (result) {
                m.resultados?.push(result);
                this.eventSrv.sendResult(this.eventSrv.eventNames.UPDATED_SAMPLE, m);
            }
        });
    }

    private generateDetailView(): void {
        if (this.lastResult?.stamp != 'no_iniciado' && this.lastResult?.stamp != 'por_repetir') {
            this.model.detailView = true;
            this.model.estado = this.lastResult?.stamp ? this.lastResult?.stamp : 'cargado';
            if (this.model.estado === 'iniciado') {
                this.model.ensayoAutoIniciado = true;
            }
            this.model.selectedValue = this.lastResult?.value!;
            switch (this.model.ensayo.tipoDato?.tipo) {
                case 'text':
                    this.model.tipoTexto = true;
                    break;
                case 'integer':
                case 'float':
                    this.model.tipoValor = true;
                    break;
                default:
                    this.model.tipoImg = true;
                    break;
            }

            if (this.model.estado === 'falta_iniciar') {
                this.model.faltaIniciar = true;
            }
        }
    }

    public pendingInitiate(): void {
        this.is_request_call = true;

        this.model.ensayoPendienteIniciado = true;
        this.model.faltaIniciar = false;
        this.model.estado = 'iniciado';

        this.model.loading = true;
        if (this.model.analysis) {
            let promises: Promise<SampleModels.SetResultadosResponse>[] = [];
            this.data.muestras.forEach((muestra) => {
                let request: SampleModels.SetResultadosRequest = {
                    ensayo: this.data.ensayo,
                    correlativo: muestra.correlativo?.toString() || '',
                    muestraId: muestra.muestraId,
                    resultadoId: this.getLastResult(muestra)?.resultadoId,
                    usuario: this.authService.usuario,
                    value: this.getLastResult(muestra)?.value || '',
                    stamp: this.model.estado,
                    tipo: 'automatico',
                };

                promises.push(this.service.SetResultados(request).toPromise());
            });

            Promise.all(promises)
                .then((r) => {
                    if (r[0].code === 200) {
                        this.model.estado = 'cargado';
                        this.model.loading = false;
                        this.saved = true;
                        this.setResults(r.map((rs) => rs.data).filter((data) => data));
                    } else {
                        alert('ocurrio un problema');
                    }
                })
                .catch((e) => {
                    alert('ocurrio un problema');
                })
                .finally(()=>{
                    this.is_request_call = false;
                });
        } else {
            this.model.loading = false;
            this.is_request_call = false;
        }
    }

    noRealizarConfirm(): void {
        this.is_request_call = true;

        this.model.ensayoCancelado = true;
        this.model.estado = 'no_realizable';

        this.model.loading = true;
        if (this.model.analysis) {
            let promises: Promise<SampleModels.SetResultadosResponse>[] = [];
            this.data.muestras.forEach((muestra) => {
                let request: SampleModels.SetResultadosRequest = {
                    ensayo: this.data.ensayo,
                    correlativo: muestra.correlativo?.toString() || '',
                    muestraId: muestra.muestraId,
                    resultadoId: undefined,
                    usuario: this.authService.usuario,
                    value: '',
                    stamp: 'no_realizable',
                    tipo: 'manual',
                };
                promises.push(this.service.SetResultados(request).toPromise());
            });

            Promise.all(promises)
                .then((r) => {
                    if (r[0].code === 200) {
                        this.model.loading = false;
                        this.saved = true;
                        this.model.automaticoDetenenido = false;
                        this.setResults(r.map((rs) => rs.data).filter((data) => data));
                        setTimeout(() => {
                            this.dialogRef.close({ status: 'saved', data: this.data, result: {}, stamp: 'no_realizable', results: [] });
                        }, 2000);
                    } else {
                        alert('ocurrio un problema');
                    }
                })
                .catch((e) => {
                    alert('ocurrio un problema');
                })
                .finally(() =>{
                    this.is_request_call = false;
                });
        } else {
            this.model.loading = false;
            this.is_request_call = false;
        }
    }
    valueChange() {
        this.model.inputValue = this.model.inputValue.trim();
        if (this.tipoDeDato === 'numerico' && this.model.inputValue.indexOf('.') === 0) {
            this.model.inputValue = '0' + this.model.inputValue;
        }
    }

    get isMultiple(): boolean {
        return (this.data.muestras && this.data.muestras.length > 1) === true;
    }

    get allowsChooseOptions() {
        return !this.model.manual && !this.model.detailView && !this.model.ensayoCancelado && this.tipoDeDato != 'image';
    }

    getLastResult(muestra?: SampleModels.RegistroMuestra) {
        let ultimoResultado: SampleModels.Resultado | undefined;
        if (this.data.ensayo && muestra) {
            let resultados = muestra.resultados || [];
            resultados = resultados.filter((r) => r.ensayo.ensayoId === this.data.ensayo.ensayoId);
            ultimoResultado = resultados
                ?.sort((a, b) => {
                    if (a.resultadoId < b.resultadoId) {
                        return -1;
                    }
                    if (a.resultadoId > b.resultadoId) {
                        return 1;
                    }
                    return 0;
                })
                .pop();
        }
        return ultimoResultado;
    }

    get lastResult() {
        return this.getLastResult(this.data.muestra);
    }

    get estado() {
        return this.lastResult?.stamp ? this.lastResult?.stamp : 'cargado';
    }

    validarMostrarInfoAntesDeGuardar() {
        const mensajesCancelacion = this.reglasCancelacionEnsayo.filter(({ensayo}) => ensayo === this.data.ensayo.ensayoId).map(({ descripcion }) => descripcion);

        if (mensajesCancelacion.length > 0) {
            this.mostrarInfoCancelacionAuto(this.data.ensayo.ensayoId, mensajesCancelacion);
        } else {
            this.saveAndContinue();
        }
    }


    mostrarInfoCancelacionAuto(ensayoId: string, mensajeCancelacion: string[]) {
        const modal = this.dialog.open(ModalCancelAutoInfo, {
            panelClass: 'ds-submodal',
            disableClose: true,
            data: mensajeCancelacion,
        });

        modal.afterClosed().subscribe((response) => {
            if (response === 'si') {
                this.saveAndContinue();
            }
        });

    }
}
