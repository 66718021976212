import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-entry-results',
  templateUrl: './cancel-entry-results.component.html',
  styleUrls: ['./cancel-entry-results.component.css']
})
export class CancelEntryResultsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CancelEntryResultsComponent>,

  ) {

   }

  ngOnInit(): void {
  }

  cancel(){
    this.dialogRef.close('cancel');
  }
  
  continue(){
    this.dialogRef.close('continue');
  }
}
