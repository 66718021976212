import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {  Observable, Subscriber } from 'rxjs';
import { Enums } from "src/app/shared";
import { environment } from "src/environments/environment";
import { MockResponse } from "src/mocks/data";
import { BatchModels } from "../models/batch.models";
import { ServiceSubjectTime } from '../../../shared/tools/service-subject-time.tools';
import { ServiceSubjectTimeProvide } from '../../../shared/tools/service-subject-provider.tools';
import { Tools } from '../../../shared/tools/objects.tools';

@Injectable({
    providedIn: 'root'
})
export class BatchService {

    subjetGetBacths:ServiceSubjectTime<BatchModels.GetLotesRequest,BatchModels.GetLotesResponse>;
    subjetGetBacthsNumber:ServiceSubjectTime<BatchModels.GetLotesRequest,BatchModels.GetLotesSkipSamplesResponse>;
    subjetGetBacthsGroupEnsayo:ServiceSubjectTime<BatchModels.GetLotesRequest,BatchModels.GetLotesGroupTestResponse>;
    subjetGetBacth:ServiceSubjectTime<BatchModels.GetLotesRequest,BatchModels.GetLoteResponse>;
    subjetGetBacthsTEMP:ServiceSubjectTime<BatchModels.GetLotesRequest,BatchModels.GetLotesResponseTEMP>;
    subjetGetPlans:ServiceSubjectTime<BatchModels.GetPlanesAnalisisRequest,BatchModels.GetPlanesAnalisisResponse>;
    subjetGetPlan:ServiceSubjectTime<BatchModels.GetPlanesAnalisisRequest,BatchModels.GetPlanesAnalisisResponse>;
    subjetGetPriority:ServiceSubjectTime<BatchModels.GetPriorityRequest,BatchModels.GetPriorityResponse>;
    subjetGetLaboratory:ServiceSubjectTime<BatchModels.GetLaboratory,BatchModels.GetLaboratoryResponse>;
    subjetGetTest:ServiceSubjectTime<BatchModels.GetTestRequest,BatchModels.GetTestResponse>;
    subjetGetMuestrasEnAnalisis:ServiceSubjectTime<BatchModels.GetMuestrasPorEstadoRequest,BatchModels.GetMuestraResponse>;


    constructor(
        private http: HttpClient,
        private tools: Tools,
        serviceSubject:ServiceSubjectTimeProvide
    ) {
        this.subjetGetBacths = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.batchs}`);
        this.subjetGetBacthsNumber = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.batchs}`);
        this.subjetGetBacthsGroupEnsayo = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.batchs}`);
        this.subjetGetBacth = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.batchs}`);
        this.subjetGetBacthsTEMP = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.batchs}`);
        this.subjetGetPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetPlan = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetPriority = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.prioridades}`);
        this.subjetGetLaboratory = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.laboratorios}`);
        this.subjetGetTest=   serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.ensayos}`);
        this.subjetGetMuestrasEnAnalisis = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);

    }

    

    GetBatchs(request: BatchModels.GetLotesRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetLotesResponse> {
        return this.subjetGetBacths._httpGet(request,opts)
    }

    GetTest(request: BatchModels.GetTestRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetTestResponse> {
        return this.subjetGetTest._httpGet(request,opts)
    }

    GetBatchsNumber(request: BatchModels.GetLotesRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetLotesSkipSamplesResponse> {
        return this.subjetGetBacthsNumber._httpGet(request,opts)
    }

    GetLotesGroupTest(request: BatchModels.GetLotesRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetLotesGroupTestResponse> {
        return this.subjetGetBacthsGroupEnsayo._httpGet(request,opts)
    }

    GetLitesBatchs(request: BatchModels.GetLotesRequest, opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Promise<BatchModels.GetLotesSkipSamplesResponse> {
        return this.http.get<BatchModels.GetLotesSkipSamplesResponse>(`${environment.apiUrl}${environment.routes.batchs}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetBatch(request: BatchModels.GetLotesRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean,fnContinue?:(requests:BatchModels.GetLotesRequest[])=>boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetLoteResponse> {
        return this.subjetGetBacth._httpGet(request,opts)
    }

    GetBatchsTEMP(request: BatchModels.GetLotesRequest,opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<BatchModels.GetLotesResponseTEMP> {
        return this.subjetGetBacthsTEMP._httpGet(request,opts)
    }

    GetBatchByNumber(request: BatchModels.GetLotesRequest, opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Promise<BatchModels.GetBatchByNumberResponse> {
        return this.http.get<BatchModels.GetBatchByNumberResponse>(`${environment.apiUrl}${environment.routes.batchs}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    SetBatch(request : BatchModels.SetBatchRequest ):Observable<BatchModels.SetBatchResponse> {
        return this.http.post<BatchModels.SetBatchResponse>(`${environment.apiUrl}${environment.routes.batchs}`, request,{context: new HttpContext().set(Enums.SPINNER_ENABLED, true) });
    }

    DeleteBatch(request : BatchModels.DeleteBatchRequest ):Observable<BatchModels.SetBatchResponse> {
        return this.http.delete<BatchModels.SetBatchResponse>(`${environment.apiUrl}${environment.routes.batchs}`, { body: request});
    }

    GetPlanesAnalisis(request: BatchModels.GetPlanesAnalisisRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean }= {force: false, spinner: false}): Observable<BatchModels.GetPlanesAnalisisResponse> {
        return this.subjetGetPlans._httpGet(request,opts)
    }
    GetPlanAnalisis(request: BatchModels.GetPlanesAnalisisRequest,  opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean,queue?:boolean }= {force: false, spinner: false}): Observable<BatchModels.GetPlanesAnalisisResponse> {
        return this.subjetGetPlan._httpGet(request,opts)
    }

    GetPrioridades(request: BatchModels.GetPriorityRequest, opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<BatchModels.GetPriorityResponse> {
       return this.subjetGetPriority._httpGet(request,opts)
    }

    GetLaboratory(request: BatchModels.GetLaboratory, opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean} = {force: false, spinner: false}): Observable<BatchModels.GetLaboratoryResponse> {
        return this.subjetGetLaboratory._httpGet(request,opts)
    }

    SetLaboratory(request: BatchModels.SetLabRequest, opts: { spinner: boolean } = { spinner: true }): Promise<BatchModels.SetLabResponse> {
        return this.http.post<BatchModels.SetLabResponse>(`${environment.apiUrl}${environment.routes.laboratorios}`, request.lab, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    GetMuestrasEnAnalisis(request: BatchModels.GetMuestrasPorEstadoRequest, opts: { force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = { force: false, spinner: false, ignoreStore: false }): Observable<BatchModels.GetMuestraResponse> {
        return this.subjetGetMuestrasEnAnalisis._httpGet(request, opts);
    }

    SetResultadosMasivo(request: BatchModels.SetResultadosMasivoRequest, opts: { spinner: boolean } = { spinner: true }): Promise<BatchModels.SetResultadosMasivoResponse> {
        return this.http.post<BatchModels.SetResultadosMasivoResponse>(`${environment.apiUrl}${environment.routes.muestraResultadosGroup}`, request, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    SetResultadosBoolMasivo(request: BatchModels.SetResultadosBoolMasivoRequest, opts: { spinner: boolean } = { spinner: true }): Promise<BatchModels.SetResultadosBoolMasivoResponse> {
        return this.http.post<BatchModels.SetResultadosBoolMasivoResponse>(`${environment.apiUrl}${environment.routes.muestraResultadosBool}`, request, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }
}