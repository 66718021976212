import {ToastService} from './toast-service';
import {Component, TemplateRef} from '@angular/core';

@Component({
    selector: 'app-toasts',
    templateUrl: './toast.component.html',
    host: {'[class.ngb-toasts]': 'true'}
  })
  export class ToastsContainer {
    constructor(public toastService: ToastService) {}  
    isTemplate(toast:any) { return toast.textOrTpl instanceof TemplateRef; }
  }