
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
    selector: '[maximumValue]',
    providers: [{provide: NG_VALIDATORS, useExisting: MaximumValueDirective, multi: true}]
})
export class MaximumValueDirective implements Validator {
    constructor(){}
    @Input() maximumValue:string='0';

    validate(control: AbstractControl): ValidationErrors | null {
        return  this.esValido(control.value)(control);
    }  
    
    esValido(input: string): ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          const forbidden = this.maximumValue &&  input !== '' ?  Number(input) > Number(this.maximumValue):false;
          return forbidden ? {maximumValue: {value: control.value}} : null;
        };
    }
}