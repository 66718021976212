import { Injectable } from '@angular/core';

@Injectable()
export class Pkce {
   
    // GENERATING CODE VERIFIER
    private dec2hex(dec: any): any {
        return ('0' + dec.toString(16)).substr(-2);
    }

    public generateCodeVerifier(): string {
        let array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, this.dec2hex).join('');
    }

    private sha256(plain: any): any {
        // returns promise ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest('SHA-256', data);
    }

    private base64urlencode(a: any): any {
        var str = '';
        var bytes = new Uint8Array(a);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            str += String.fromCharCode(bytes[i]);
        }
        return btoa(str)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");
    }

    public async generateChallenge(v: any) {
        let hashed = await this.sha256(v);
        let base64encoded = this.base64urlencode(hashed);
        return base64encoded;
    }
}
