import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {

    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private authenticationService: AuthenticationService) {

        this.configureOAuth();
    }

    public configureOAuth() {
        const authConfig: AuthConfig = environment.authConfig;
        this.oauthService.configure(authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService
            .loadDiscoveryDocumentAndLogin()
            .then((result: boolean) => {                
                if (result && this.oauthService.hasValidAccessToken()) {
                    this.oauthService.loadUserProfile()
                        .then((user: any) => {                            
                            this.authenticationService
                                .set_user_info(user.info)
                                .then(() => {                                    
                                    if (this.router.url.startsWith('/oauth-callback')) {
                                        this.router.navigate(['/home']);
                                    }                                    
                                });
                        });
                }
            })
            .catch((error) => {                
                console.log(error);
                this.logout();                
                this.router.navigate(['/login']);
            });
    }

    public logout() {
        this.oauthService.logOut();
    }

    public getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    public isLoggedIn(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public getUserInfo() {
        return this.oauthService.loadUserProfile();
    }
}
