
    
      <div class="modal-header">
          <h4 class="modal-title" id="modal-title">{{data.title}}</h4>
            <button type="button" class="btn-close"  aria-label="Close" (click)="cancelar()"></button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="data.msg"></p>        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="cancelar()">CANCELAR</button>
        <button type="button" ngbAutofocus class="btn {{data.buttonClassname?data.buttonClassname:'btn-danger'}}" (click)="confirmar()">{{data.titleButtonOK || 'Confirmar'}}</button>
      </div>