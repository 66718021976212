import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

import { Objects } from '../models/objects';
import { LoginModel } from '../../modules/login/models/login';
import { StorageTools } from '../tools/storage.tools';
import { promise } from 'protractor';
import { Tools } from '../tools/objects.tools';
import { Enums } from '../models/enums';
import * as moment from 'moment';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Objects.UserProfile>;
    public currentUser: Observable<Objects.UserProfile>;

    constructor(
        private http: HttpClient,
        private storage: StorageTools,
        private tools: Tools,
        private oauthService: OAuthService) {

        if (this.storage.containsKey('currentUser')) {
            this.currentUserSubject = new BehaviorSubject<Objects.UserProfile>(JSON.parse(storage.getItem('currentUser') ?? '{}'));
            this.currentUser = this.currentUserSubject.asObservable();
        } else {
            this.currentUserSubject = new BehaviorSubject<Objects.UserProfile>(storage.getItem('currentUser') ?? null);
            this.currentUser = this.currentUserSubject.asObservable();
        }
    }

    public set currentPkce(value: string) {
        this.storage.setItem('pkce', value);
    }

    public get currentPkce(): string {
        return this.storage.getItem('pkce') ?? '';
    }

    public get currentUserValue(): Objects.UserProfile {
        return this.currentUserSubject.value;
    }

    public set currentUserValue(value: Objects.UserProfile) {
        this.storage.setItem('currentUser', JSON.stringify(value));
    }

    public isValidSession(): boolean {
        return this.storage.containsKey("currentUser");
    }
    /**
     * Usuario actual logueado
     */
    public get usuario(): Objects.Usuario {
        return { userId: this.currentUserValue.userInfo.userId, nombre: this.currentUserValue.userInfo.nombre + ' ' + this.currentUserValue.userInfo.apellido };
    }

    private getClient(userId: string, token: string): Promise<Objects.Response<Objects.UserInfo>> {
        const httpOptions = {
            headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
            params: this.tools.toHttpParams({ userId: userId }),
            context: new HttpContext().set(Enums.SPINNER_ENABLED, false)
        };

        return this.http.get<Objects.Response<Objects.UserInfo>>(`${environment.apiUrl}${environment.routes.getUserInfo}`, httpOptions).toPromise();
    }

    public async set_user_info(user_info: any) {
        await this.getClient(user_info.email, this.oauthService.getAccessToken())
            .then((response) => {
                if (response.code === 200) {
                    user_info.userInfo = response.data;
                } else {
                    // this.logout_exist_fusion_auth();
                }
            }).finally(() => {
                this.storage.setItem('currentUser', user_info);
                this.currentUserSubject.next(user_info);
            });
    }

    reload_user_info(): void {
        let user_info = this.currentUserValue;
        this.getClient(user_info.email, this.oauthService.getAccessToken())
            .then((response) => {
                if (response.code === 200) {
                    user_info.userInfo = response.data;
                }
            }).finally(() => {
                this.storage.setItem('currentUser', user_info);
                this.currentUserSubject.next(user_info);
            });
    }

    logout(): void {
        // remove user from local storage to log user out
        this.storage.clear();
        this.currentUserSubject.unsubscribe();

        const uri = `${environment.fusionAuth.uri}/oauth2/logout?client_id=${environment.fusionAuth.clientID}`;
        window.location.href = uri;
    }

    logout_exist_fusion_auth(): void {
        // remove user from local storage to log user out
        this.storage.clear();
        this.currentUserSubject.unsubscribe();
        this.storage.setItem('user_copec_not_present_platform', true);
        const uri = `${environment.fusionAuth.uri}/oauth2/logout?client_id=${environment.fusionAuth.clientID}`;
        window.location.href = uri;
    }
}