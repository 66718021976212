import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { BatchModels } from "src/app/modules/batch/models/batch.models";
import { BatchService } from "src/app/modules/batch/services/batch.services";
import { AuthenticationService } from "../auth/authentication.service";
import { SubscribersTools } from "../tools/subscribers.tools";
import { finalize } from 'rxjs/operators';
import { RequestCounterTools } from '../tools/request-counter.tools';

@Injectable({
    providedIn: 'root'
})
export class BatchProvider {
    
    private _sub_analysis: Subject<BatchModels.Lote[]>;

    loteWithoutSamplesFinish: boolean = false;
    batchAux: BatchModels.LoteSkipSamples[] = [];

    lotesAnalysis: BatchModels.Lote[] = [];
    page_key_analysis: string = '';
    subsTools: SubscribersTools = new SubscribersTools();
    
    batchs:{
       data:BatchModels.Lote[];
       reqCounter:RequestCounterTools;
       subject:Subject<BatchModels.Lote[]>       
    };

    batchs_number:{
        data:BatchModels.LoteSkipSamples[];
        reqCounter:RequestCounterTools;
        subject:Subject<BatchModels.LoteSkipSamples[]>       
    };

    subject_for_batch:{[loteId:string]:{
        subject:Subject<BatchModels.Lote | undefined> ;
        reqCounter:RequestCounterTools;
    }}={}
       

    constructor(
        private authService: AuthenticationService,
        private batchService: BatchService,
    ){
        this.batchs={
            data:[],
            reqCounter:new RequestCounterTools(),
            subject:new BehaviorSubject(this.lotesAnalysis)
        }

        this.batchs_number={
            data:[],
            reqCounter:new RequestCounterTools(),
            subject:new BehaviorSubject(this.batchAux)
        }

        this._sub_analysis=new BehaviorSubject(this.lotesAnalysis);
        // this.getBatchInAnalysis();
        // this._getBatchs();
    }

    public getBatchAnalysis(force: boolean = true) {
        if(force && !this.batchService.subjetGetBacthsTEMP.isCalled){
            this.lotesAnalysis=[];
            this._sub_analysis.next(this.lotesAnalysis)
            this.getBatchInAnalysis(true);
        }
        return this._sub_analysis;
    }

    public getBatchs(force: boolean = true) {
        if(force){            
            this.setBatchsStorage([])
            this._getBatchs();
        }
        return this.batchs.subject;
    }

    public getObserverBatchs() {
        return this.batchs.subject;
    }

    public getBatchById(loteId:string,fnCallbackService?:(batch: BatchModels.Lote | undefined )=>void) {          
        this.getBatchFromService(loteId,fnCallbackService);
        return this.getSubForBatch(loteId);
    }

    public reloadBatchById(loteId:string) {          
        this.getBatchFromService(loteId);
    }
    
    public removeBatchById(loteId:string) {    
       let filteredData=this.batchs.data.filter(lote=>lote.loteId!==loteId)
       this.setBatchsStorage(filteredData);
    }
     
    public getBatchSkipSamplesEnAnalisis(){
        let rq: BatchModels.GetLotesRequest = {
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,            
            skipMuestras: "true",
            page_size: 500,
            estado: "in_analysis"
        }

        return this.batchService.GetBatchsNumber(rq, {force: true, spinner: false, ignoreStore: true});
    }

    public getBatchSkipSamples(){
        let rq: BatchModels.GetLotesRequest = {
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
            page_size: 50,            
            skipMuestras: "true",
        }
        if(!this.batchs_number.reqCounter.inProgress){
            this.batchWithoutSamples(rq);
        }
        return this.batchs_number.subject;
    }

    private batchWithoutSamples(rq: BatchModels.GetLotesRequest): void {
        this.batchs_number.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('getBatchsNumber', this.batchService.GetBatchsNumber(rq, {force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {
                if(key != "") {
                    rq.page_key = key;
                    this.batchWithoutSamples(rq);
                }else{
                    this.loteWithoutSamplesFinish = true;
                    this.setBatchsNumberStorage(this.batchAux);
                    this.batchAux = [];
                }
                this.batchs_number.reqCounter.endRequest();
            }})
        .subscribe(r => {
            if(r.code == 200) {
                this.batchAux = [...this.batchAux, ...r.data.items]; 

                if(!this.loteWithoutSamplesFinish || this.batchs_number.data.length == 0){
                    this.setBatchsNumberStorage(this.batchs_number.data.concat(r.data.items));
                }

                if(r.data.page_key){
                    key = r.data.page_key;
                }
            }
        }));
    }
   
    private getSubForBatch(loteId: string) {
        if (!this.subject_for_batch[loteId]) {
            this.subject_for_batch[loteId] = {
                subject: new BehaviorSubject(this.lotesAnalysis.find(lote => lote.loteId === loteId)),
                reqCounter: new RequestCounterTools(),
            };
        }
        return this.subject_for_batch[loteId];
    }

    private _getBatchs(): void {
        this.batchs.data=[];
        let rq: BatchModels.GetLotesRequest = {
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
            page_size: 3
        }
        this.serviceBatchs(rq);
    }

    private getBatchFromService(loteId:string,fnCallback?:(batch: BatchModels.Lote | undefined )=>void ): void {
        this.getSubForBatch(loteId).reqCounter.startRequest();
        this.batchs.reqCounter.startRequest();
        let rq: BatchModels.GetLotesRequest = {
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
            page_size: 1,
            loteId:loteId
        }
        this.subsTools.addSubscribe('getBatchId', this.batchService.GetBatch(rq, {force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {             
                this.subject_for_batch[loteId].reqCounter.endRequest();
                this.batchs.reqCounter.endRequest();
            },
            fnContinue:(request)=>{
               return !request.find(req=>rq.loteId===req.loteId)
            }
        })
        .subscribe(r => {
            if(r.code == 200) {
                let batch=r.data[0]
                if(batch){
                    this.subject_for_batch[batch.loteId].subject.next(batch)
                   // this.setBatchStorage(batch);
                }     
                if(fnCallback){
                    fnCallback(r.data[0])
                }
            }
        }));
    }


    private getBatchInAnalysis(refresh: boolean = false): void {
        let rq: BatchModels.GetLotesRequest = {
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
            estado: 'in_analysis',
            page_size: 3
        }
        this.serviceInAnalysis(rq, refresh);
    }

    private serviceBatchs(rq: BatchModels.GetLotesRequest){
        this.batchs.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('getBatchs', this.batchService.GetBatchs(rq, {force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {
                // console.log("key", key);
                if(key != "") {
                    rq.page_key = key;
                    this.serviceBatchs(rq);
                }
                this.batchs.reqCounter.endRequest();
            }})
        .subscribe(r => {
            if(r.code == 200) { 
                this.setBatchsStorage(this.batchs.data.concat(r.data.items));
                if(r.data.page_key){
                    key = r.data.page_key;
                }
            }
        }));
    }
    
    private setBatchsStorage(batchs: BatchModels.Lote[]) {
        this.batchs.data = batchs; 
        this.batchs.subject.next(this.batchs.data);
    }

    private setBatchsNumberStorage(batchs: BatchModels.LoteSkipSamples[]) {
        this.batchs_number.data = batchs.sort((a, b) => {
            return (Number(b.loteId) - Number(a.loteId))
        }); 
        this.batchs_number.subject.next(this.batchs_number.data);
    }

    public updateBatchsWithoutSamples(batchIN: BatchModels.LoteSkipSamples) {
      
        let isNew=true;
        this.batchs_number.data.forEach((batchStore,index)=>{                
            if(batchIN.loteId===batchStore.loteId){
                this.batchs_number.data[index]=batchIN;
                this.batchs_number.subject.next(this.batchs_number.data);
                isNew=false;
            }
        });
        if(isNew){
            this.batchs_number.data.push(batchIN)
            this.batchs_number.subject.next(this.batchs_number.data);
        }
    
    }

    private setBatchStorage(batchIN: BatchModels.Lote) {

        let isNew=true;
        this.batchs.data.forEach((batchStore,index)=>{                
            if(batchIN.loteId===batchStore.loteId){
                this.batchs.data[index]=batchIN;
                this.batchs.subject.next(this.batchs.data);
                isNew=false;
            }
        });
        if(isNew){
            this.batchs.data.push(batchIN)
            this.batchs.subject.next(this.batchs.data);
        }
    }

    private serviceInAnalysis(rq: BatchModels.GetLotesRequest, refresh: boolean = false){
        this.batchs.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('getBatchAnalysis', this.batchService.GetBatchsTEMP(rq, {force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {
                if(key != "") {
                    rq.page_key = key;
                    this.recall(rq, refresh);
                }
                this.batchs.reqCounter.endRequest();
            }})
        .subscribe(r => {
            if(r.code == 200) {
                if(refresh){
                    this.getChanges(r.data.items)
                }else{  
                    this.lotesAnalysis = this.lotesAnalysis.concat(r.data.items);
                }
                this._sub_analysis.next(this.lotesAnalysis);
                if(r.data.page_key){
                    key = r.data.page_key;
                }
            }
        }));
    }

    private getChanges(lotesData: BatchModels.Lote[]): void{
        lotesData.forEach(l => {
            this.getChangeBatchAnalysis(l);
        });
    }

    private recall(rq: BatchModels.GetLotesRequest, refresh: boolean = false): void {
        this.serviceInAnalysis(rq, refresh);
    }

    realoadBatchs(): void {
        if(!this.batchs.reqCounter.inProgress){
            this.setBatchsStorage([])
            this._getBatchs();
        }
    }

    public getChangeBatchAnalysis(lote: any){
        let index = this.lotesAnalysis.findIndex(x => lote.loteId == x.loteId);
        if(index != -1){
            this.lotesAnalysis[index] = lote;
        }else{
            this.lotesAnalysis.push(lote);
        }
    }

    // private getBatchResponse(rq: BatchModels.GetLotesRequest){
    //     this.batchService.GetBatchs(rq).toPromise().then(r => {
    //         if(r.code == 200) {
    //             this.lotes = [...r.data.items];
    //         }
    //     })
    // }

}