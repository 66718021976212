import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[onlyFloat]',
    providers: [{provide: NG_VALIDATORS, useExisting: onlyFloat, multi: true}]
})

export class onlyFloat {
    
    constructor(
        private el: ElementRef
    ) {
    }
    
    regexStr = /^[0-9.]*$/g;

    @HostListener('keypress', ['$event']) onKeyPress(event:any) {
        let current: string = this.el.nativeElement.value
        if((event.key === '.' || event.key === ',') && (current.includes('.') || current.includes(','))){
            return false;
        }else{
            return new RegExp(this.regexStr).test(event.key);
        }
    }
    @HostListener('paste', ['$event']) onPaste(e:any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if(!new RegExp(this.regexStr).test(pasteData)){
            e.preventDefault();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.esValido(control.value)(control);
    }  
    
    esValido(input: string): ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
            var regExpString = "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"
            const forbidden = input != null ? input.match(new RegExp(regExpString)) ? true : false : true;
            return !forbidden ? {floatValidator: {value: control.value}} : null;
        };
    }
}