import { Injectable } from "@angular/core";
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";
import { environment } from "src/environments/environment";
import { SampleModels } from '../models/sample.models';
import { Observable } from 'rxjs';
import { HttpClient, HttpContext } from "@angular/common/http";
import { Enums, Tools } from "src/app/shared";

@Injectable({
    providedIn: 'root'
})
export class SampleService {

    subjetGetListAnalysis: ServiceSubjectTime<SampleModels.GetEnsayosRequest,SampleModels.GetEnsayosResponse>;
    subjetSetResultados: ServiceSubjectTime<SampleModels.SetResultadosRequest,SampleModels.SetResultadosResponse>;
    subjetGetResultados: ServiceSubjectTime<SampleModels.GetResultadosRequest,SampleModels.GetResultadosResponse>;
    subjetGetMuestras: ServiceSubjectTime<SampleModels.GetMuestraRequest,SampleModels.GetMuestraResponse>;
    subjetGetClientById: ServiceSubjectTime<SampleModels.GetClientsChoresRequest, SampleModels.GetClientsChoresResponse>;
    subjetGetListLubricants: ServiceSubjectTime<SampleModels.GetLubricantsRequest,SampleModels.GetLubricantsResponse>;
    subjetSetMuestra: ServiceSubjectTime<SampleModels.SetMuestraRequest,SampleModels.SetMuestraResponse>;
    subjetGetListPlans: ServiceSubjectTime<SampleModels.GetPlanesAnalisisRequest,SampleModels.GetPlanesAnalisisResponse>;

    constructor(
        private http: HttpClient,
        private tools: Tools,
        serviceSubject: ServiceSubjectTimeProvide,
    ) {
        this.subjetGetListPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetListAnalysis = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.ensayos}`);
        this.subjetSetResultados = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestraResultados}`);
        this.subjetGetResultados = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestraResultados}`);
        this.subjetGetMuestras = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);
        this.subjetGetClientById = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getClientes}`);
        this.subjetGetListLubricants = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.lubricantes}`);
        this.subjetSetMuestra = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.muestra}`);

    }

    GetLubricants(request: SampleModels.GetLubricantsRequest, forceRefresh: boolean = false): Observable<SampleModels.GetLubricantsResponse> {
        return this.subjetGetListLubricants._httpGet(request, {force: forceRefresh, spinner: false});
    }

    GetClienteById(request: SampleModels.GetClientsChoresRequest, forceRefresh: boolean = false): Observable<SampleModels.GetClientsChoresResponse> {
        return this.subjetGetClientById._httpGet(request, {force: forceRefresh, spinner: false,ignoreStore: forceRefresh});
    }

    GetComponentes(request: SampleModels.GetComponentesRequest, forceRefresh: boolean = false): Observable<SampleModels.GetComponentesResponse> {            
        return  this.http.get<SampleModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.componentes}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) });
      }

    GetEnsayos(request: SampleModels.GetEnsayosRequest, forceRefresh: boolean = false): Observable<SampleModels.GetEnsayosResponse> {
        return this.subjetGetListAnalysis._httpGet(request, {force: forceRefresh, spinner: false});
    }
    SetResultados(request: SampleModels.SetResultadosRequest ): Observable<SampleModels.SetResultadosResponse> {
        return this.subjetSetResultados.httpPost(request, { spinner: false});
    }
    GetResultados(request: SampleModels.GetResultadosRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Observable<SampleModels.GetResultadosResponse> {
        return this.subjetGetResultados._httpGet(request,opts);
    }
    GetMuestras(request: SampleModels.GetMuestraRequest, opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean,fnContinue?:(requests:SampleModels.GetMuestraRequest[])=>boolean,queue?:boolean  } = {force: false, spinner: false}): Observable<SampleModels.GetMuestraResponse> {
        return this.subjetGetMuestras._httpGet(request, opts);
    }

    GetMuestras2(request: SampleModels.GetMuestraRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Promise<SampleModels.GetMuestraResponse> {
        return this.http.get<SampleModels.GetMuestraResponse>(`${environment.apiUrl}${environment.routes.muestra}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();        
    }

    GetMuestrasMultimples(request: SampleModels.GetMuestraMultipleRequest,opts: {force:boolean, fnCallback?:()=>{}, spinner: boolean, ignoreStore?:boolean } = {force: false, spinner: false} ): Promise<SampleModels.GetMuestraResponse> {
        return this.http.get<SampleModels.GetMuestraResponse>(`${environment.apiUrl}${environment.routes.muestra}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();        
    }

    GetMuestraInfo(request: SampleModels.GetMuestraInfoRequest): Promise<SampleModels.GetMuestraInfoResponse> {
        return this.http.get<SampleModels.GetMuestraInfoResponse>(`${environment.apiUrl}${environment.routes.getMuestraInfo}`, { params: this.tools.toHttpParams(request) }).toPromise();
    }

    GetSolicitud(request: SampleModels.GetSolicitudAnalisisRequest,opts:{spinner:boolean}={spinner:true}) : Promise<SampleModels.GetSolicitudAnalisisResponse> {
        return this.http.get<SampleModels.GetSolicitudAnalisisResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisis}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise()
        ;
    }

    SetModificarMuestra(request:SampleModels.SetMuestraRequest):Observable<SampleModels.SetMuestraResponse>{
      return this.subjetSetMuestra.httpPost(request,{spinner:true});
    }

    GetPlanesAnalisis(request: SampleModels.GetPlanesAnalisisRequest, forceRefresh: boolean = false): Observable<SampleModels.GetPlanesAnalisisResponse> {
        return this.subjetGetListPlans._httpGet(request, {force: forceRefresh, spinner: false});
    }

}