import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
    // private currentStatusSubject = new BehaviorSubject<boolean>(false);
    // public currentStatus = this.currentStatusSubject.asObservable();

    loading$: Subject<boolean> = new Subject();
    isOn: boolean=true;
    blockOn: boolean = false;
 
    constructor(){ 
        // this.currentStatus = this.currentStatusSubject.asObservable();
    }

    // public get spinnerStatus(): boolean {
    //     return this.currentStatusSubject.value
        
    // }

    // public set spinnerStatus(value: boolean) {
    //     this.currentStatusSubject.next(value);        
    // }
    off(blockOn: boolean = false){
        this.blockOn = blockOn;
        this.isOn=false;
    }
    on(){
        this.isOn=true;
    }
    startLoading() {
        if(this.isOn){
            this.loading$.next(true);
        }else if(!this.blockOn){
            this.on();
        }
    }

    stopLoading() {
        this.loading$.next(false);
    }
}