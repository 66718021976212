<div class="modal__contanier">
  <div class="modal__header flex-column">
    <div class="d-flex w-100">
      <div class="d-flex flex-column flex-fill align-items-start">
        <h2>{{data.ensayo.nombre}}</h2>
        <small class="c-dark" *ngIf="data.total>1">{{data.total - data.numero + 1}} Muestras</small>
      </div>
      <a href="#" [routerLink]="" (click)="cancelEntryResult()">
        <em class="bi bi-x-lg"></em>
      </a>
    </div>
    <div class="d-flex justify-content-between gap-4 mt-4 mb-4 w-100" *ngIf="data.muestra  &&  ( !isMultiple || model.hideTable) ">
      <div class="d-flex gap-3 align-items-start">
        <div>
          <em class="ico ico--big ico-sample txt--gray"></em>
        </div>
        <dl class="description">
          <dt class="description__term txt--gray">Nº laboratorio</dt>
          <dd class="description__definition txt--sm txt--third mb-1">{{data.muestra?.correlativo}}</dd>
        </dl>
      </div>
      <dl class="description">
        <dt class="description__term txt--gray">Lote</dt>
        <dd class="description__definition txt--sm txt--third mb-1">{{data.muestra?.loteId}}</dd>
      </dl>
    </div>

    <div class="d-flex justify-content-between w-100" *ngIf="!isMultiple">

      <dl class="description" *ngIf="!model.detailView && tipoDeDato != 'image'">
        <dt class="description__term txt--gray">Estado</dt>
        <dd class="description__definition txt--sm txt--third mb-1">{{model.estadosResultado[model.estado]}}</dd>
      </dl>

      <dl class="description mb-4" *ngIf="false">
        <dt class="description__term txt--gray">Resultado</dt>
        <dd class="description__definition mb-1">
          <img class="img-fluid" src="https://picsum.photos/400/200" alt="">
        </dd>
      </dl>
      <dl class="description mb-4" *ngIf="model.detailView && model.tipoValor && !model.ensayoAutoIniciado">
        <dt class="description__term txt--gray">Resultado</dt>
        <dd class="description__definition mb-1">
          {{model.selectedValue}}
        </dd>
      </dl>
      <dl class="description mb-4" *ngIf="model.detailView && model.tipoTexto">
        <dt class="description__term txt--gray">Comentario</dt>
        <dd class="description__definition txt--sm txt--third mb-1">{{model.selectedValue}}</dd>
      </dl>
    </div>
  </div>
  <div class="modal__content flex-column" *ngIf="!model.loading && (isMultiple)" [hidden]="model.hideTable">
    <div class="table-scrolling">
        <table class="table w-100 table--striped text-start">
        <thead>
            <tr>
            <th>Nº Laboratorio</th>
            <th>lote</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let muestra of data.muestras">
            <td>{{muestra.correlativo}}</td>
            <td>{{muestra.loteId}}</td>
            </tr>
        </tbody>
        </table>
    </div>
  </div>

  <div class="modal__content flex-column" *ngIf="!model.loading && (model.ensayoAutoIniciado || model.automaticoDetenenido || model.ensayoCancelado || model.faltaIniciar || model.ensayoPendienteIniciado)">
    <div class="alert alert--success" *ngIf="model.ensayoAutoIniciado">
      <figure class="alert__figure">
        <em class="bi bi-check-circle-fill"></em>
      </figure>
      <div class="alert__message">
        <span class="d-block mb-2"  *ngIf="!isMultiple">Ensayo iniciado, el resultado automático quedará asociado a ti.</span>
        <span class="d-block mb-2" *ngIf="isMultiple">Ensayos iniciados, el resultado automático quedarán asociado a ti.</span>
      </div>
    </div>

    <div class="alert alert--info_dark align-items-center" *ngIf="model.automaticoDetenenido">
      <figure class="alert__figure">
        <em class="bi bi-stop-circle-fill"></em>
      </figure>
      <div class="alert__message" *ngIf="!isMultiple">El ensayo ha sido detenido.</div>
      <div class="alert__message" *ngIf="isMultiple">Los ensayos han sido detenido.</div>
    </div>

    <div class="alert alert--warning_dark align-items-center" *ngIf="model.faltaIniciar">
      <figure class="alert__figure">
        <em class="bi bi-pause-circle-fill"></em>
      </figure>
      <div class="alert__message">Ensayo con resultado pero no iniciado.</div>
    </div>

    <div class="alert alert--success" *ngIf="model.ensayoPendienteIniciado">
      <figure class="alert__figure">
        <em class="bi bi-check-circle-fill"></em>
      </figure>
      <div class="alert__message">
        <span class="d-block mb-2">El ensayo se ha asociado a ti.</span>
      </div>
    </div>

    <div class="alert alert--danger align-items-center" *ngIf="model.ensayoCancelado ">
      <figure class="alert__figure">
        <em class="bi bi-stop-circle-fill"></em>
      </figure>
      <div class="alert__message">Los ensayos indicados no se van a realizar.</div>
    </div>
  </div>

  <div class="modal__content p-0 flex-column" *ngIf="model.manual">
    <form class="form bg-gray p-3 mt-3" [hidden]="model.loading || !model.analysis || saved" #analysisForm="ngForm"  name="analysisForm" >
      <ng-container [ngSwitch]="tipoDeDato">
        <ng-container *ngSwitchCase="'numerico'" >
          <div  *ngTemplateOutlet="tmplNumeric" class="form__field">numerico</div>
        </ng-container>
        <ng-container *ngSwitchCase="'text'" >
          <div  *ngTemplateOutlet="tmplText" class="form__field" >texto</div>
        </ng-container>
        <ng-container *ngSwitchCase="'boolean'" tmplBoolean>
          <div  *ngTemplateOutlet="tmplBoolean" class="form__field" >boolean</div>
        </ng-container>
        <ng-container *ngSwitchCase="'image'" tmplBoolean>
          <div  *ngTemplateOutlet="tmplImage" class="form__field" >boolean</div>
        </ng-container>
        <ng-container *ngSwitchDefault >
          {{tipoDeDato}}
        </ng-container>
      </ng-container>

      <ng-template #tmplBoolean >
          <mat-radio-group aria-label="Select an option" class="d-flex gap-2"
          name="selectedValue"
          [(ngModel)]="model.selectedValue"
          #selector="ngModel"
          required
          >
            <mat-radio-button color="primary" value="true">{{traduccion.resultadoEnsayo['true']}}</mat-radio-button>
            <mat-radio-button color="primary" value="false">{{traduccion.resultadoEnsayo['false']}}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="selector.invalid && (selector.dirty || selector.touched || tryToSave) " class="text-danger">
            <div *ngIf="selector.errors?.required">
                Debe seleccionar el valor.
            </div>
          </div>
      </ng-template>

      <ng-template #tmplImage >
        <div class="alert alert--info_dark align-items-center" *ngIf="!model.detailView">
          <figure class="alert__figure">
            <em class="bi bi-stop-circle-fill"></em>
          </figure>
          <div class="alert__message">La imagen debe ser cargada externamente.</div>
        </div>

        <div class="alert alert--success" *ngIf="model.detailView">
          <figure class="alert__figure">
            <em class="bi bi-check-circle-fill"></em>
          </figure>
          <div class="alert__message">
            <span class="d-block mb-2">Imagen cargada correctamente.</span>
          </div>
        </div>
      </ng-template>

      <ng-template #tmplNumeric>
          <label class="label mb-1 d-block" for="particles">Resultado</label>
          <div class="particles">
            <input autocomplete="off"
            class="input" type="text"
            onlyNumber
            numeric
            [decimals]="model.analysis ? model.analysis.tipoDato.decimals : '0'"
            [maxlength]="30"
            maximumDecimal
            [maximumDecimal]="model.analysis ? model.analysis.tipoDato.decimals : '0'"
             numericRange
            [max]="maximo"
            [min]="minimo"
            id="particles"
            name="particles"
            [(ngModel)]="model.inputValue"
            #inputNumber="ngModel"
            [ngClass]="{'is-invalid':inputNumber.invalid && (inputNumber.dirty || inputNumber.touched || tryToSave) }"
            [value]="model.inputValue"
            required
            (change)="valueChange()"
            >
            <small>{{  model.analysis && model.analysis.unidad ? model.analysis.unidad : 'ppm'}}</small>
          </div>
          <div *ngIf="inputNumber.invalid && (inputNumber.dirty || inputNumber.touched || tryToSave) " class="text-danger">
            <div *ngIf="inputNumber.errors?.required">
                Debe ingresar el valor.
            </div>
            <div *ngIf="inputNumber.value && inputNumber.errors?.numericRange">
              Debe ingresar un valor entre los rangos {{minimo}} - {{maximo}}.
            </div>
            <div *ngIf="inputNumber.value && inputNumber.errors?.maximumDecimal">
                Excede los decimales permitidos ({{model.analysis.tipoDato.decimals}})
            </div>

          </div>
      </ng-template>
      <ng-template #tmplText>
        <label class="label mb-1 d-block" for="particles">Resultado</label>
        <div class="particles">
          <input autocomplete="off"
          class="input" type="text"
          [maxlength]="model.analysis?.limites?.inputMax || 10"
          id="particles"
          name="particles"
          [(ngModel)]="model.inputValue"
          #inputNumber="ngModel"
          [ngClass]="{'is-invalid':inputNumber.invalid && (inputNumber.dirty || inputNumber.touched || tryToSave) }"
          [value]="model.inputValue"
          trimValidation
          required
          (change)="valueChange()"
          >
        </div>
        <div *ngIf="inputNumber.invalid && (inputNumber.dirty || inputNumber.touched || tryToSave) " class="text-danger">
          <div *ngIf="inputNumber.errors?.required">
              Debe ingresar el texto.
          </div>
        </div>
      </ng-template>

      <div class="form__field mt-4" *ngIf="!model.loading && tipoDeDato != 'image'">
        <button class="btn btn--first btn--small w-100" type="submit" *ngIf="data.numero<data.total" (click)="validarMostrarInfoAntesDeGuardar()" > Guardar y continuar</button>
        <button class="btn btn--first btn--small w-100" type="submit" *ngIf="data.numero==data.total || data.total===1 " (click)="validarMostrarInfoAntesDeGuardar()" [disabled]="analysisForm.invalid"> Guardar </button>
      </div>

    </form>

    <div class="py-5 px-3 mt-3 bg-gray d-flex justify-content-center align-items-center" *ngIf="saved">
      <div class="checkmark-circle">
        <div class="background"></div>
        <div class="checkmark draw"></div>
      </div>
    </div>

  </div>

  <div class="d-flex justify-content-center align-items-center py-5" *ngIf="model.loading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>



  <div class="modal__foot flex-column flex-sm-row justify-content-sm-between gap-3 gap-sm-0 mt-4" *ngIf="!model.loading && allowsChooseOptions">
    <button id="model_resultado_no_realizar" class="link c-danger order-3 order-sm-1" mat-button [matMenuTriggerFor]="menu" #menuTrigger *ngIf="!model.automaticoIniciado">No realizar</button>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="above" class="ds-popup px-2 py-1">
      <span class=" d-block mb-3 text-center">Por favor confirma que no se realizar&aacute;n estos ensayos.</span>
      <div class="d-flex flex-column gap-3 align-items-center">
        <button id="model_resultado_confirmar" class="btn btn--small btn--danger" (click)="noRealizarConfirm()">Si, CONFIRMAR</button>
        <a>NO, CANCELAR</a>
      </div>
    </mat-menu>
    <button id="model_resultado_ingreso_manual" class="link  order-2 order-sm-2" *ngIf="!model.automaticoIniciado" (click)="setManual()">Ingreso manual</button>
    <button id="model_resultado_iniciar" class="btn btn--first order-1 order-sm-3" *ngIf="model.canBeAutomatic && !model.automaticoIniciado" (click)="goAutomatic()">Iniciar</button>
  </div>

  <div class="modal__foot flex-column flex-sm-row justify-content-sm-between gap-3 gap-sm-0 mt-4" *ngIf="!model.loading && model.ensayoAutoIniciado && tipoDeDato != 'image'">
    <button id="model_resultado_detener" class="link c-danger order-2 order-sm-2" (click)="stopAtomatic()">Detener</button>
  </div>
  <div class="modal__foot flex-column flex-sm-row justify-content-sm-between gap-3 gap-sm-0 mt-4" *ngIf=" !model.loading && model.faltaIniciar && tipoDeDato != 'image'">
    <button id="model_resultado_iniciar_2" class="btn btn--first order-1 order-sm-3" *ngIf="(model.canBeAutomatic || tipoDeDato === 'text' ) && !model.ensayoAutoIniciado" (click)="pendingInitiate()">Iniciar</button>
  </div>

</div>
