
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
    selector: '[maximumDecimal]',
    providers: [{provide: NG_VALIDATORS, useExisting: MaximumDecimalDirective, multi: true}]
})
export class MaximumDecimalDirective implements Validator {
    constructor(){}
    @Input() maximumDecimal:string='0';

    validate(control: AbstractControl): ValidationErrors | null {
        return  this.esValido(control.value)(control);
    }  
    
    esValido(input: string): ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          const forbidden = this.maximumDecimal &&  input !== '' ?  this.check(input,Number(this.maximumDecimal)) :false;
          return forbidden ? {maximumDecimal: {value: control.value}} : null;
        };
    }

    private check(value: string, decimals: number):boolean {
        if (decimals <= 0) {
            return value.indexOf('.')>-1;
        } else {
            var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
            var regexp=new RegExp(regExpString);
            return !regexp.test(value)
            ;
        }
    }
}