import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { BasicLayoutComponent } from './modules/layouts/basic-layout/basic-layout.component';
import { MainLayoutComponent } from './modules/layouts/main-layout/main-layout.component';
import { AuthGuard } from './shared/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./modules/login/login.module').then(
            (m) => m.LoginModule
          ),
      }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'external-content',
        loadChildren: () =>
          import('./modules/external-content/external-content.module').then(
            (m) => m.ExternalContentModule
          ),
      },
      {
        path: 'solicitud-analisis',
        canActivate: [AuthGuard], data: { roles: ["administrador", "encargado_laboratorio", "administrativo", "recepcionista", "analista_laboratorio"] },
        loadChildren: () =>
          import('./modules/solicitud-analisis/solicitud-analisis.module').then(
            (m) => m.SolicitudAnalisisModule
          ),
      },
      {
        path: 'facturacion',
        canActivate: [AuthGuard], data: { roles: ["administrador", "administrativo"] },
        loadChildren: () =>
          import('./modules/billing/billing.module').then(
            (m) => m.BillingModule
          ),
      },
      {
        path: 'clientes',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/clients/clients.module').then(
            (m) => m.ClientsModule
          ),
      },
      {
        path: 'lotes',
        canActivate: [AuthGuard], data: { roles: ["administrador", "encargado_laboratorio", "administrativo", "recepcionista", "analista_laboratorio"] },
        loadChildren: () =>
          import('./modules/batch/batch.module').then(
            (m) => m.BatchModule
          ),
      },
      {
        path: 'analisis',
        canActivate: [AuthGuard], data: { roles: ['administrador', "encargado_laboratorio", "analista_laboratorio"] },
        loadChildren: () =>
          import('./modules/plans/plans.module').then(
            (m) => m.PlansModule
          ),
      },
      {
        path: 'procedimiento',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo", "encargado_laboratorio", "analista_laboratorio"] },
        loadChildren: () =>
          import('./modules/analysis/analysis.module').then(
            (m) => m.AnalysisModule
          ),
      },
      {
        path: 'lubricantes',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/lubricants/lubricants.module').then(
            (m) => m.LubricantsModule
          ),
      },
      {
        path: 'protocolos',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/protocols/protocols.module').then(
            (m) => m.ProtocolsModule
          ),
      },
      {
        path: 'estado-muestra',
        canActivate: [AuthGuard], data: { roles: ['administrador', "encargado_laboratorio"] },
        loadChildren: () =>
          import('./modules/state-sample/state-sample.module').then(
            (m) => m.StateSampleModule
          ),
      },
      {
        path: 'laboratorios',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/maintainer-labs/maintainer-labs.module').then(
            (m) => m.MaintainerLabsModule
          ),
      },
      {
        path: 'comentarios',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/comments-maintainer/comments-maintainer.module').then(
            (m) => m.CommentsMaintainerModule
          ),
      },
      {
        path: 'precios',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/maintainer-prices/maintainer-prices.module').then(
            (m) => m.MaintainerPricesModule
          ),
      },
      {
        path: 'zonas',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/maintainer-zone/maintainer-zone.module').then(
            (m) => m.MaintainerZoneModule
          ),
      },
      {
        path: 'mantenedor',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/maintainer-clients/maintainer-clients.module').then(
            (m) => m.MaintainerClientsModule
          ),
      },
      {
        path: 'reports-preview',
        pathMatch: 'full',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./modules/reports-preview/reports-preview.module').then(
                (m) => m.ReportsPreviewModule
              ),
          }
        ]
      },
      {
        path: 'reportes',
        canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
        loadChildren: () =>
          import('./modules/reportes/reportes.module').then(
            (m) => m.ReportesModule
          ),
      },
      {
        path: 'muestra',
        loadChildren: () =>
          import('./modules/sample/sample.module').then(
            (m) => m.SampleModule
          ),
      },
      {
        path:'componentes',
        loadChildren: () =>
          import('./modules/container-componentes/container-componentes.module').then(
            (m) => m.ContainerComponentesModule
          ),
      }
    ]
  },
  {
    path: 'oauth-callback',
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/oauth/oauth.module').then(
            (m) => m.OauthModule
          ),
      }
    ]
  },
  {
    path: 'reports2',
    // pathMatch: 'full',
    canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/reports2/reports.module').then(
            (m) => m.ReportsModule
          ),
      }
    ]
  },
  {
    path: 'proforma',
    pathMatch: 'full',
    canActivate: [AuthGuard], data: { roles: ['administrador', "administrativo"] },
    component: BasicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/proforma/proforma-routing.module').then(
            (m) => m.ProformaRoutingModule
          ),
      }
    ]
  },

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy',
        preloadingStrategy: PreloadAllModules,
    }),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
