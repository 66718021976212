import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { PaginatorComponent } from "./components/paginator/paginator.component";

@NgModule({
    declarations: [
        PaginatorComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        PaginatorComponent
    ]
})
export class PaginatorModule { }