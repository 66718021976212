import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import { environment } from "src/environments/environment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MuestraProviderService } from 'src/app/shared/services/muestraProvider.service';
import { MatDialog } from '@angular/material/dialog';
import { SampleDetailComponent } from 'src/app/modules/sample/components/sample-detail/sample-detail.component';
import { Objects } from 'src/app/shared';
import { error } from 'console';

@Component({
    selector: 'ds-sidebar',
    styleUrls: ['./nav-menu.component.css'],
    templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {
    muestraId: string = "";
    searching: boolean = false;
    menu = new Map<string, Array<string>>();

    @Input() collapsed: boolean = false;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthenticationService,
        private snackBar: MatSnackBar,
        private muestraProviderService: MuestraProviderService
    ) {
        this.menu.set("home", []);
        this.menu.set("dashboard", ["administrador"]);
        this.menu.set("solicitud-analisis/recepcion", ["administrador", "encargado_laboratorio", "recepcionista", "administrativo", "analista_laboratorio"]);
        this.menu.set("solicitud-analisis/solicitudes", ["administrador", "encargado_laboratorio", "recepcionista", "administrativo", "analista_laboratorio"]);
        this.menu.set("lotes/list", ["administrador", "encargado_laboratorio", "recepcionista", "administrativo", "analista_laboratorio"]);
        this.menu.set("lotes/list/analisis", ["administrador", "encargado_laboratorio", "analista_laboratorio"]);
        this.menu.set("estado-muestra", ["administrador", "encargado_laboratorio"]);
        this.menu.set("mantenedor", ["administrador", "administrativo"]);
        this.menu.set("facturacion/documentos_ventas", ["administrador", "administrativo"]);
        this.menu.set("facturacion/informe_prefacturacion", ["administrador", "administrativo"])
    }

    isVisible(route: string): boolean {
        return this.menu.get(route)?.length == 0 || this.authService.currentUserValue.userInfo.roles.some(r => this.menu.get(route)?.some(xr => xr === r));
    }

    isActive(route: string): boolean {
        return route === this.router.url;
    }

    isActiveMulti(route: string[]): boolean {
        return route.some(r => this.router.url.startsWith(r));
    }

    openInstrumentos(): void {
        window.open(environment.external.instrumentos, "", "width=920,height=760")
    }



    showModalSample(muestra: Objects.RegistroMuestra): void {
        this.searching = false;
        this.dialog.open(SampleDetailComponent, {

            panelClass: 'ds-modal-lg',

            data: {

                muestra: muestra

            }

        });

    }

    async getSample() {

        if (this.muestraId.trim() != '') {
            this.searching = true;
            const request = {
                identificador: this.muestraId.trim()
            }

            this.muestraProviderService.GetMuestras(request).then(muestra => {
                if (muestra.data.length) {
                    this.showModalSample(muestra.data[0])
                } else {
                    this.snackBar.open(`No se encontraron resultados a tu búsqueda`, 'Cerrar', {
                        duration: 5000,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'right'
                    })
                    this.searching = false;
                }
            }).catch(error => {
                this.snackBar.open(`No se encontraron resultados a tu búsqueda`, 'Cerrar', {
                    duration: 5000,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right'
                });
            }).finally(() => {
                this.searching = false;
            });
        }
    }
}
