<div class="modal__contanier">
    <div class="modal__header">
        <h2 id="modal_ingresar_frasco_title">Ingresar frasco</h2>
        <a href="javascript:void(0);" [mat-dialog-close]="false">
            <em class="bi bi-x-lg"></em>
        </a>
    </div>
    <div class="modal__content">
        <form #newAnalysisForm="ngForm">
            <div class="d-flex flex-column gap-3">
                <div class="form__field">
                    <label class="label mb-1 d-block">Componente</label>
                    <ng-select class="ng-select" name="componente" placeholder="Seleccionar componente" [items]="model.componentes" [(ngModel)]="model.muestra.componente" bindLabel="componenteId" #componente="ngModel" [appendTo]="'body'" required>
                        <ng-template ng-label-tmp let-item="item">
                            <strong>{{item.componenteId}}</strong>
                        </ng-template>
                    </ng-select>
                    <div *ngIf="componente.invalid && (componente.dirty || componente.touched)" class="text-danger mt-1">
                        <div *ngIf="componente.errors?.required">
                            <span> Debe seleccionar un componente.</span>
                        </div>
                    </div>
                </div>
                <div class="form__field">
                    <label class="label mb-1 d-block">Número frasco</label>
                    <input class="input" type="text" [(ngModel)]="model.muestra.muestraId" (keydown)="change_muestraId()" maxlength="9" validaMuestraId decimals="0" name="muestraId" #muestraId="ngModel" required>
                    <div *ngIf="muestraId.invalid && (muestraId.dirty || muestraId.touched) || !model.validation.muestraId.isValid" class="text-danger mt-1">
                        <div *ngIf="muestraId.errors?.required">
                            <span> Debe ingresar un identificador de muestra.</span>
                        </div>
                        <div *ngIf="!model.validation.muestraId.isValid">
                            <span> Número de envase utilizado anteriormente.</span>
                        </div>
                        <div *ngIf="muestraId.value !=='' && muestraId.errors?.validacionRut">
                            Formato incorrecto.
                        </div>                        
                    </div>
                </div>
                <div class="form__field">
                    <label class="label mb-1 d-block">Fecha toma de muestra</label>
                    <input class="input" type="date" [(ngModel)]="model.muestra.fechaMuestreo" name="fechaMuestreo" #fechaMuestreo="ngModel" required
                    max="{{ todaysdate | date :'yyyy-MM-dd' }}" validarFecha >
                    <div *ngIf="fechaMuestreo.invalid && (fechaMuestreo.dirty || fechaMuestreo.touched)" class="text-danger mt-1">
                        <div *ngIf="fechaMuestreo.errors?.required">
                            <span> Debe ingresar la fecha de toma de muestra.</span>
                        </div>
                        <div *ngIf="fechaMuestreo.invalid && !fechaMuestreo.errors?.required ">
                            <span>Formato incorrecto.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal__foot justify-content-end"><button class="btn btn--small btn--first" [disabled]="newAnalysisForm.invalid" (click)="add_sample()">Ingresar</button></div>
        </form>
    </div>
</div>
