import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[numeric]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumericDirective, multi: true}]

})
export class NumericDirective  implements Validator {

    @Input('decimals') decimals: string = '0';

    validate(control: AbstractControl): ValidationErrors | null {
        return  this.esValido(control.value)(control);
    }  

    esValido(nro:number):ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          const forbidden = !this.validaNumero(nro);
          return forbidden ? {numeric: {value: control.value}} : null;
        };
      }


    private validaNumero(nro: number): boolean{
        
        if(!isNaN(Number(this.decimals)) && Number(this.decimals ) === 0 ){
            return this.isInt(nro)
        }else if(!isNaN(Number(this.decimals))){
            return !isNaN(Number(nro))
        }else{
            return false;
        }
  
    }

    private isInt(n:any){
        
        return !isNaN(Number(n)) && Number(n) % 1 === 0;
    }

    private isFloat(n:any){
        return  !isNaN(Number(n)) && Number(n) % 1 !== 0;
    }

    private check(value: string, decimals: number) {
        if (decimals <= 0) {
            return String(value).match(new RegExp(/^\d+$/));
        } else {
            var regExpString = "^\\s*((\\d+(\\.\\d{0," + decimals + "})?)|((\\d*(\\.\\d{1," + decimals + "}))))\\s*$"
            return String(value).match(new RegExp(regExpString));
        }
    }

    private specialKeys = [
        'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = this.el.nativeElement.value;
        let inValue: string = event.key;
        let next: string = current.concat(event.key);
        if (!inValue.trim() ||  !this.check(next, Number(this.decimals))) {
            event.preventDefault();
        }
    }
}