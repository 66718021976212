import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Directive, Input } from '@angular/core';
@Directive({
    selector: '[numericRange]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumericRangeDirective, multi: true}]
  })
  export class NumericRangeDirective implements Validator {
    
    @Input()min:number=0;
    @Input()max:number=100;

    
    validate(control: AbstractControl): ValidationErrors | null {
      return  this.esValido(control.value)(control);
    }  
    constructor(){

    }
    esValido(nro:number):ValidatorFn{
      return (control: AbstractControl): {[key: string]: any} | null => {
        const forbidden = !this.validaRango(nro);
        return forbidden ? {numericRange: {value: control.value}} : null;
      };
    }



    private validaRango(nro: number): boolean{

      return nro >= this.min && nro <= this.max

    }
     
  }