import { Component, EventEmitter, Inject, Input, Output, SimpleChanges } from "@angular/core";

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Output() callBackNextPage = new EventEmitter<number>();
  @Output() callBackTableResult = new EventEmitter<any[]>();
  @Output() callBackItemsPerPage = new EventEmitter<number>();
  @Input() list: any[] = [];
  @Input() paginatorParams: {
    refresh?: boolean,
    isLoading?: boolean,
    hasNext: boolean,
    registers?: number[],
    defaultNumRegisters?: number,
    labelPerPage?: string,
  } = {
    refresh: false,
    isLoading: false,
    hasNext: false,
    labelPerPage: "Items por página",
  }

  dictionary: { [id: string] : { date:Date,result:any[] }} = {};
  iterator: number = 1;
  maxPages: number = 1;
  pagesNumbers: number[] = [1];
  registers: number[] = [10, 20, 30, 50, 100];
  selectedNumRegister: number = 10;
  labelPerPage: string =  "Items por página";

  constructor() {} 

  ngOnInit(): void {
    if(this.paginatorParams.labelPerPage) {
      this.labelPerPage = this.paginatorParams.labelPerPage;
    }
    if(this.paginatorParams.registers){
      this.registers = this.paginatorParams.registers;
    }
    if(this.paginatorParams.defaultNumRegisters){
      this.selectedNumRegister = this.paginatorParams.defaultNumRegisters;
    }
  }
  
  ngOnChanges(changes: SimpleChanges) {
  
    if(changes['list']){
       this.dictionary[this.iterator]={ result: this.list,date:new Date()}
      this.callBackTableResult.next(this.dictionary[this.iterator].result);
    }

    if(this.paginatorParams.refresh){
      this.pagesNumbers = [1];
      this.iterator = 1;
      this.maxPages = 1;
      this.paginatorParams.hasNext = false;
      this.paginatorParams.refresh = false;
      this.selectedNumRegister = 5;
    }
  }

  backPage(): void {
    this.iterator--;
    this.callBackTableResult.next(this.dictionary[this.iterator].result);
  }

  nextPage(): void {
    this.callBackTableResult.next([]);
    this.iterator++;
    if(this.maxPages === 1 || this.iterator > this.maxPages){
      this.callBackNextPage.next(this.selectedNumRegister);
      this.maxPages++;
      this.pagesNumbers.push(this.iterator);
    }else{
      this.callBackTableResult.next(this.dictionary[this.iterator].result);
    }
  }

  changePage(page: number): void {
    this.iterator = page
    if(!this.paginatorParams.isLoading){
      this.callBackTableResult.next(this.dictionary[this.iterator].result);
    }
  }

  changeNumberPage(): void {
    this.callBackTableResult.next([]);
    this.pagesNumbers = [1];
    this.iterator = 1;
    this.maxPages = 1;
    this.paginatorParams.hasNext = false;
    this.paginatorParams.refresh = false;
    this.callBackItemsPerPage.next(this.selectedNumRegister);
  }
    
  reload(){
    this.pagesNumbers = [1];
    this.iterator = 1;
    this.maxPages = 1;
    this.paginatorParams.hasNext = false;
    this.paginatorParams.refresh = false;    
    this.callBackItemsPerPage.next();
  }

  clean() {
    this.pagesNumbers = [1];
    this.iterator = 1;
    this.maxPages = 1;
    this.paginatorParams.hasNext = false;
    this.paginatorParams.refresh = false;   
  }

  get load_date(){
    return this.dictionary[this.iterator]?.date
  }
  
  //errorSi:(msg:string,regla:any)=>void;

filter(input:{inputSearch:string,fn:(e:any)=>boolean}){
  
  if (this.dictionary && input.inputSearch != ''){
  
     let ids = Object.getOwnPropertyNames(this.dictionary)
     let filteredData: any[]=[];    
     ids.forEach(id=>{      
      let store= this.dictionary[id].result;
        let aux = store.filter(input.fn)        
        if(aux){
          filteredData=filteredData.concat(aux);
        }
     })
     this.callBackTableResult.next(filteredData);
  
   }else if(input.inputSearch ==''){
       this.callBackTableResult.next(this.dictionary[this.iterator].result);
   }
}

}