
import { Pipe, PipeTransform } from '@angular/core';
import { ReportsModels } from 'src/app/modules/reports-preview/models/reports.models';

@Pipe({ name: 'limite_personalizado_directive' })
export class LimitePersonalizadoDirective implements PipeTransform {


    transform(value: { ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra }) {
        return this.displayFormatNumberLimits(value.ensayo, value.muestra);
    }


    displayFormatNumberLimits(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): string {
        let result = '';
        let ultimoResultado = this.ultimoResultado(ensayo, muestra)
        let limites = ultimoResultado?.estado?.limites;
        if (!ultimoResultado || !limites) {
            return result;
        }
        let is_default = limites.is_default
        return this.createPersonalizadoTag(is_default);
    }

    createPersonalizadoTag(isDefault?: boolean, preereport?: boolean): string {
        if (!isDefault) {
            return 'p';
        }
        return '';
    }

    ultimoResultado(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): ReportsModels.Resultado | undefined {
        let resultados = muestra && muestra.resultados ? muestra.resultados : [];
        resultados = resultados.filter(r => r.ensayo.ensayoId === ensayo.ensayoId);
        resultados.sort((a, b) => {
            if (a.ensayo.ensayoId < b.ensayo.ensayoId) { return -1; }
            if (a.ensayo.ensayoId > b.ensayo.ensayoId) { return 1; }
            return 0;
        });

        let ultimoResultado = resultados.pop();
        return ultimoResultado;
    }

}

