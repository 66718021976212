import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudAnalisisModels } from '../../models/solicitud-analisis.models';
import { MatTableDataSource } from '@angular/material/table';
import { Objects, Enums } from 'src/app/shared';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReceiveBottleComponent } from '../../components/receive-bottle/receive-bottle.component';
import { SolicitudAnalisisService } from '../../services/solicitud-analisis.services';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';
import { ReceiveBottleSuccessComponent } from '../../components/receive-bottle-success/receive-bottle-success.component';
import { ReceiveBottleObservationComponent } from '../../components/receive-bottle-observation/receive-bottle-observation.component';
import { BatchModels } from 'src/app/modules/batch/models/batch.models';
import { BatchService } from 'src/app/modules/batch/services/batch.services';
import { SampleDetailComponent } from 'src/app/modules/sample/components/sample-detail/sample-detail.component';
import { AddBottleComponent } from '../../components/add-bottle/add-bottle.component';
import { BatchProvider } from '../../../../shared/services/batchProvider.service';
import { SnackBarService } from '../../../../shared/services/snackBar.service';
import { SubscribersTools } from '../../../../shared/tools/subscribers.tools';
import { StorageService } from '../../../../shared/services/storage.service';
import { LogService } from 'src/app/shared/services/log.service';
import { SolicitudAnalisisViewModels } from '../../models/solicitud-analisis.view.models';
import { Location } from '@angular/common';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
    is_request_call: boolean = false;

    displayedColumns: string[] = ['sample', 'numLabs', 'component', 'machine', 'state'];
    dataSource = new MatTableDataSource<Objects.RegistroMuestra>([]);
    lastBatchSelected: string = '';
    statusBatch: { [key: string]: string } = Enums.EstadosLote;
    statusJar: { [key: string]: string } = Enums.EstadosMuestra;

    batchs: BatchModels.LoteSkipSamples[] = [];
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;

    model: SolicitudAnalisisViewModels.ViewDetailModel = {
        solicitud: {
            estado: '',
            recibidoFecha: '',
            solicitud: {
                cliente: { clienteId: '', faena: { faenaId: '', nombre: '', numeroSap: '', transporte: '' }, nombre: '', numeroSap: '', razonSocial: '' },
                fechaCreacion: '',
                horaCreacion: '',
                muestras: [],
                solicitudAnalisisId: '',
                tipo: '',
                traces: [],
                usuario: { apellido: '', email: '', nombre: '', phone: '', rut: '', userId: '' },
            },
            transporte: { courier: '', numeroOT: '' }
        },
        cargandoSolicitud: true,
        usuario: { apellido: '', nombre: '', userId: '', roles: [] },
        solitudInput: '',
        puedeIngresarFrasco: false,
        puedeRecibirFrasco: false
    }

    subTools: SubscribersTools = new SubscribersTools();
    batchsOptions: { status: string; value: string; name: string; selected?: boolean }[] = [];

    constructor(
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private service: SolicitudAnalisisService,
        private authService: AuthenticationService,
        private batchService: BatchService,
        private batchProvider: BatchProvider,
        private router: Router,
        private dialogRef: MatDialogRef<DetailComponent>,
        private snackBarSrv: SnackBarService,
        public storageSrv: StorageService,
        private _logService: LogService,
        private location: Location,
        @Inject(MAT_DIALOG_DATA) public data: { solicitudAnalisisId: string, recepcionar: boolean }
    ) {
        // this.solicititudId=data.solicitudAnalisisId;
        this.model.usuario = {
            userId: this.authService.currentUserValue.userInfo.userId,
            apellido: this.authService.currentUserValue.userInfo.apellido,
            nombre: this.authService.currentUserValue.userInfo.nombre,
            roles: this.authService.currentUserValue.userInfo.roles
        };
        this.model.solitudInput = this.storageSrv.getData('solicitud')
        if (!this.model.solitudInput || !this.model.solitudInput.solicitud.solicitudAnalisisId) {
            this.router.navigate(['/solicitud-analisis/solicitudes']);
            return;
        }
        // this.model = solicitud;
        // this.init();
    }



    private init() {
        this.dataSource = new MatTableDataSource<Objects.RegistroMuestra>(this.model.solicitud.solicitud.muestras);
        // if (this.route.snapshot.queryParams.recepcionar) {
        //     this.openDialogRequestBottle();
        // }

        if (this.data.recepcionar) {
            this.openDialogRequestBottle();
        }

        this.model.solicitud.solicitud.muestras.sort((a, b) => {
            if (!b.correlativo || (a.correlativo && a.correlativo < b.correlativo)) { return -1; }
            if (!a.correlativo || b.correlativo && a.correlativo > b.correlativo) { return 1; }
            return 0;
        })
    }

    ngOnInit(): void {
        this.getSolicitudPromise();
        this.getBatchsOptions()
    }

    ngOnDestroy(): void {
        this.subTools.destroySubs();
    }

    getSolicitudPromise(): Promise<SolicitudAnalisisModels.GetSolicitudAnalisisResponse> {
        this.dataSource = new MatTableDataSource<Objects.RegistroMuestra>([]);
        this.model.cargandoSolicitud = true;
        return new Promise((resolve, reject) => {
            let req: SolicitudAnalisisModels.GetSolicitudAnalisisRequest = {
                identify: this.model.solitudInput.solicitud.solicitudAnalisisId || ''
            }
            this.service.GetSolicitud(req, { spinner: false }).then(response => {
                if (response.success && response.code == 200) {
                    this.model.solicitud = response.data
                    this.model.solicitud.solicitud.muestras.sort((a, b) => {
                        if (!b.correlativo || (a.correlativo && a.correlativo < b.correlativo)) { return -1; }
                        if (!a.correlativo || b.correlativo && a.correlativo > b.correlativo) { return 1; }
                        return 0;
                    })
                    this.init();
                    this.dataSource = new MatTableDataSource<Objects.RegistroMuestra>(this.model.solicitud.solicitud.muestras)
                    this.model.puedeIngresarFrasco = (this.model.usuario.roles?.includes('administrador') && this.esCreadaEnLaboratorio()) === true && this.model.solicitud.estado === 'registered';
                    this.model.puedeRecibirFrasco = (this.model && this.model.solicitud.solicitud.muestras.filter(m => !m.estado || m.estado === 'registered').length > 0 && (this.model.solicitud.estado === 'received' || this.model.solicitud.estado === 'received_observations')) === true;
                    this.model.cargandoSolicitud = false;
                } else {
                    this.router.navigate(['/solicitud-analisis/solicitudes']);
                    return;
                }
                resolve(response);
            }).catch(e => {
                reject(e);
            });

        })
    }

    contieneLubNuevo() {
        return this.model && this.model.solicitud.solicitud.muestras.filter(m => {
            return m.lubricante?.lubricanteId === '0';
        })?.length > 0;
    }

    noLlego(muestra: Objects.RegistroMuestra) {
        if (!this.model) {
            return;
        }
        muestra.estado = 'not_received';
        let req: SolicitudAnalisisModels.SetMuestraReceivedRequest = {
            userId: this.authService.currentUserValue.userInfo.userId,
            solicitudAnalisisId: this.model.solicitud.solicitud.solicitudAnalisisId,
            muestraId: muestra.muestraId,
            clienteId: this.model.solicitud.solicitud.cliente.clienteId,
            faenaId: this.model.solicitud.solicitud.cliente.faena.faenaId,
            estado: muestra.estado,
            observaciones: muestra.observaciones || ''
        };

        this.service.SetMuestraReceived(req).then(response => {
            if (response.code === 200) {

                let rq: Objects.LogInput = {
                    accion: 'Muestra no llego',
                    elemento: muestra.muestraId,
                    tipoElemento: 'Muestra',
                    valor: 'ot_received',
                    subElemento: this.model.solicitud.solicitud.solicitudAnalisisId,
                    tipoSubElemento: 'Solicitud analisis',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Back-office'
                }
                this._logService.createLog(rq, true);


            }
        });
    }

    showModalSample(muestra: Objects.RegistroMuestra): void {
        muestra.solicitudAnalisis = this.model.solicitud.solicitud.solicitudAnalisisId
        this.dialog.open(SampleDetailComponent, {
            panelClass: 'ds-modal-lg',
            data: {
                muestra: muestra
            }
        });
    }

    openDialogRequestBottle(msgError?: string) {
        // console.log("msg", msgError);
        const modal = this.dialog.open(ReceiveBottleComponent, {
            panelClass: 'ds-modal',
            data: { msgError: msgError },
            disableClose: true
        });
        modal.afterClosed().subscribe(muestraId => {
            this.data.recepcionar = false;
            if (muestraId) {
                this.openDialogReceiveBottle(muestraId);
            }
        });
    }

    openDialogAddBottle(msgError?: string) {
        const modal = this.dialog.open(AddBottleComponent, {
            panelClass: 'ds-modal',
            data: {
                msgError: msgError,
                solicitud: this.model?.solicitud.solicitud
            },
            disableClose: true

        });
        modal.afterClosed().subscribe(muestraId => {
            this.getSolicitudPromise();
            if (muestraId) {
                this.openDialogReceiveBottle(muestraId);
            }
        });
    }

    private openDialogReceiveBottle(muestraId: any) {
        muestraId = muestraId.trim();
        this.spinnerService.startLoading();
        this.is_request_call = true;

        let req: SolicitudAnalisisModels.GetSolicitudAnalisisRequest = {
            identify: this.model.solicitud.solicitud.solicitudAnalisisId
        }
        this.service.GetSolicitud(req, { spinner: true }).then(response => {
            if (response.success && response.data.estado !== "NOT_FOUND") {
                this.checkBottle(response.data, muestraId);
            } else {
                this.openDialogRequestBottle("El número de frasco no fue encontrado");
            }
        }).finally(() => {
            this.spinnerService.stopLoading();
            this.is_request_call = false;
        });
    }

    private checkBottle(solicitudAnalisis: SolicitudAnalisisModels.SolicitudAnalisis, muestraId: any) {

        let isAnother = solicitudAnalisis.solicitud.solicitudAnalisisId !== this.model.solicitud.solicitud.solicitudAnalisisId;
        let muestraDb = solicitudAnalisis.solicitud.muestras.find(m => {
            return m.muestraId.replace('-', '').toLowerCase() === muestraId.toLowerCase();
        });

        if (!muestraDb) {
            this.openDialogRequestBottle('No existe el número de frasco');
        } else if (!muestraDb.estado || muestraDb.estado.toUpperCase() === 'REGISTERED') {
            this.openDialogReceiveBottleObsv(solicitudAnalisis, muestraId, isAnother);

        } else {
            this.openDialogRequestBottle('El número de frasco se encuentra ingresado');
        }
    }

    private openDialogReceiveBottleObsv(solicitudAnalisis: SolicitudAnalisisModels.SolicitudAnalisis, muestraId: any, isAnother: boolean) {
        if (this.batchProvider.batchs_number.reqCounter.inProgress && !this.batchProvider.loteWithoutSamplesFinish) {
            this.snackBarSrv.warning('El sistema se encuentra cargando información, por favor espere.');

            setTimeout(() => {
                this.subTools.addSubscribe('wait', this.batchProvider.batchs_number.reqCounter.loading$.subscribe(loading => {
                    if (loading) {
                        this.snackBarSrv.warning('Cargando...');
                    } else {
                        this.snackBarSrv.updateSuccess('Información cargada.');
                        this.subTools.destroySubs('wait')
                        const modal = this.dialog.open(ReceiveBottleObservationComponent, {
                            panelClass: 'ds-modal',
                            data: { infoSolicitud: solicitudAnalisis, muestraId: muestraId, esDeOtraSolicitud: isAnother, batchsOptions: this.batchsOptions }
                        });
                        modal.afterClosed().subscribe(muestra => {
                            if (muestra) {
                                this.recepcionMuestra(muestra, solicitudAnalisis);
                            }
                        });
                    }
                }));
            }, 2000);
            return;
        } else {
            const modal = this.dialog.open(ReceiveBottleObservationComponent, {
                panelClass: 'ds-modal',
                data: { infoSolicitud: solicitudAnalisis, muestraId: muestraId, esDeOtraSolicitud: isAnother, batchsOptions: this.batchsOptions }
            });
            modal.afterClosed().subscribe(muestra => {
                if (muestra) {
                    //this.setMuestraReceive(muestra, solicitudAnalisis);
                    this.recepcionMuestra(muestra, solicitudAnalisis);
                }
            });
        }

    }
    private recepcionMuestra(muestra: SolicitudAnalisisModels.RegistroMuestra, solicitud: SolicitudAnalisisModels.SolicitudAnalisis) {

        let req: SolicitudAnalisisModels.MuestraRecepcionRequest = {
            clienteId: muestra.cliente.clienteId,
            faenaId: muestra.cliente.faena.faenaId,
            laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
            muestraId: muestra.muestraId,
            observaciones: muestra.observaciones,
            solicitudAnalisisId: solicitud.solicitud.solicitudAnalisisId,
            userId: this.authService.currentUserValue.userInfo.userId,
            loteId: muestra.loteId === '-1' ? undefined : muestra.loteId
        }
        try {
            this.spinnerService.startLoading();
            this.is_request_call = true;

            this.service.RegisterMuestra(req).subscribe(response => {
                this.spinnerService.stopLoading();
                this.is_request_call = false;
                if (response.success) {                    
                    let rq: Objects.LogInput = {
                        accion: 'Recibir Muestra',
                        elemento: muestra.muestraId,
                        tipoElemento: 'Muestra',
                        subElemento: solicitud.solicitud.solicitudAnalisisId,
                        tipoSubElemento: 'Solicitud Analisis',
                        usuario: this.authService.usuario.nombre,
                        usuarioId: this.authService.usuario.userId,
                        ambiente: 'Back-office'
                    }
                    this._logService.createLog(rq, true);
                    let esLoteNuevo = muestra.loteId === '-1';
                    muestra.loteId = response.data.loteId
                    this.lastBatchSelected = muestra.loteId;

                    if (esLoteNuevo) {
                        this.batchsOptions.push({ name: muestra.loteId + " (1 muestras)", value: muestra.loteId, status: 'available', selected: true })
                        let batch: BatchModels.LoteSkipSamples = {
                            cliente: { clienteId: '', faena: { faenaId: '', nombre: '', numeroSap: '', transporte: '' }, numeroSap: '', razonSocial: '' },
                            estado: "available",
                            fecha: '',
                            laboratorioId: '',
                            loteId: muestra.loteId,
                            muestras: [muestra.muestraId],
                            traces: [],
                            usuario: { nombre: '', userId: '' }
                        }
                        this.batchProvider.updateBatchsWithoutSamples(batch);
                    } else {
                        let lote = this.batchs.filter(lote => String(lote.loteId) === muestra.loteId);

                        let muestrasAux = this.batchs.filter(lote => String(lote.loteId) === muestra.loteId).map(lote => {
                            return lote.muestras.map(muestra => muestra)
                        })

                        let muestras = muestrasAux && muestrasAux.length > 0 ? muestrasAux[0].filter((value, index, self) => {
                            return self.indexOf(value) === index;
                        }) : [];
                        muestras.push(muestra.muestraId)
                        lote[0].muestras = muestras;
                        this.batchProvider.updateBatchsWithoutSamples(lote[0]);}
                        this.getSolicitudPromise()
                        this.batchProvider.reloadBatchById(this.lastBatchSelected)
                        const modal = this.dialog.open(ReceiveBottleSuccessComponent, {
                            panelClass: 'ds-modal',
                            data: { infoSolicitud: response.data, muestra: muestra, nuevoLote: esLoteNuevo }
                        });
                        modal.afterClosed().subscribe(opcion => {
                            switch (opcion) {
                                case 'ingresar-otro-frasco':
                                    this.openDialogRequestBottle();
                                    break;
                                case 'ver-lote-analisis':
                                    this.router.navigate(['/lotes/' + muestra.loteId]);
                                     this.dialog.closeAll();
                                this.dialogRef.close();
                                    break;
                                case 'ver-solicitud-analisis':
                                default:
                                    break;
                            }
                        });
                        //this.getSolicitudesPromise()

                } else (this.snackBarSrv.warning("Ocurrio un problema al asignar frasco lote"))
            })
        }
        catch (e) {
            this.snackBarSrv.warning("Ocurrio un problema al asignar frasco lote")

        }

    }



    private setMuestraReceive(muestra: SolicitudAnalisisModels.RegistroMuestra, solicitud: SolicitudAnalisisModels.SolicitudAnalisis) {
        this.setMuestraReceivePromise(muestra, solicitud).then(response => {
            if (response.success) {


                let rq: Objects.LogInput = {
                    accion: 'Recibir Muestra',
                    elemento: muestra.muestraId,
                    tipoElemento: 'Muestra',
                    subElemento: solicitud.solicitud.solicitudAnalisisId,
                    tipoSubElemento: 'Solicitud Analisis',
                    usuario: this.authService.usuario.nombre,
                    usuarioId: this.authService.usuario.userId,
                    ambiente: 'Back-office'
                }
                this._logService.createLog(rq, true);
                this.setJarToBatchPromise(muestra).then(responseSetJar => {
                    if (responseSetJar.code == 200 && responseSetJar.success) {
                        let esLoteNuevo = muestra.loteId === '-1';
                        muestra.loteId = responseSetJar.data.loteId;
                        this.lastBatchSelected = muestra.loteId;

                        const modal = this.dialog.open(ReceiveBottleSuccessComponent, {
                            panelClass: 'ds-modal',
                            data: { infoSolicitud: response.data, muestra: muestra, nuevoLote: esLoteNuevo }
                        });
                        if (esLoteNuevo) {
                            this.batchsOptions.push({ name: muestra.loteId + " (1 muestras)", value: muestra.loteId, status: 'available', selected: true })
                            let batch: BatchModels.LoteSkipSamples = {
                                cliente: { clienteId: '', faena: { faenaId: '', nombre: '', numeroSap: '', transporte: '' }, numeroSap: '', razonSocial: '' },
                                estado: "available",
                                fecha: '',
                                laboratorioId: '',
                                loteId: muestra.loteId,
                                muestras: [muestra.muestraId],
                                traces: [],
                                usuario: { nombre: '', userId: '' }
                            }
                            this.batchProvider.updateBatchsWithoutSamples(batch);
                        }
                        let lote = this.batchs.filter(lote => String(lote.loteId) === muestra.loteId);


                        this.batchProvider.updateBatchsWithoutSamples(lote[0]);
                        modal.afterClosed().subscribe(opcion => {
                            switch (opcion) {
                                case 'ingresar-otro-frasco':
                                    this.openDialogRequestBottle();
                                    break;
                                case 'ver-lote-analisis':
                                    this.router.navigate(['/lotes/' + muestra.loteId]);
                                     this.dialog.closeAll();
                                this.dialogRef.close();
                                    break;
                                case 'ver-solicitud-analisis':
                                default:
                                    break;
                            }
                        });
                        //this.getSolicitudesPromise()
                        this.getSolicitudPromise()
                        this.batchProvider.reloadBatchById(this.lastBatchSelected)
                    } else (this.snackBarSrv.warning("Ocurrio un problema al asignar frasco lote"))
                }).catch(e => {
                    this.snackBarSrv.warning("Ocurrio un problema al asignar frasco lote")

                })
            } else {
            }
        });
    }

    private getBatchsOptions() {
        this.subTools.addSubscribe('getObserverBatchs', this.batchProvider.getBatchSkipSamples().subscribe(lotes => {
            this.batchs = lotes;

            // Mapea lotes al arreglo de options, removiendo posibles duplicados
            this.batchsOptions = lotes.map(lote => ({
                status: this.statusBatch[lote.estado].toLowerCase(),
                value: lote.loteId,
                name: lote.loteId + ' (' + lote.muestras.length + ' muestras)',
                selected: this.lastBatchSelected === lote.loteId
            })).filter((batch, index, array) => array.findIndex(b => b.value === batch.value) === index);

            this.batchsOptions.push({ name: 'Nuevo lote', value: '-1', status: 'nuevo' })
        }));
    }

    private setJarToBatchPromise(muestra: SolicitudAnalisisModels.RegistroMuestra): Promise<BatchModels.SetBatchResponse> {

        return new Promise((resolve, reject) => {

            let lote = this.batchs.filter(lote => String(lote.loteId) === muestra.loteId);

            let muestrasAux = this.batchs.filter(lote => String(lote.loteId) === muestra.loteId).map(lote => {
                return lote.muestras.map(muestra => muestra)
            })

            let muestras = muestrasAux && muestrasAux.length > 0 ? muestrasAux[0].filter((value, index, self) => {
                return self.indexOf(value) === index;
            }) : [];
            muestras.push(muestra.muestraId)

            let req = {
                laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
                muestras: muestras,
                usuario: this.model.usuario,
                loteId: muestra.loteId === '-1' ? undefined : muestra.loteId
            }

            //
            this.batchService.SetBatch(req).toPromise().then(responseSetBatch => {
                if (responseSetBatch.code === 200) {
                    if (!req.loteId || req.loteId === '-1') {
                        let batch: BatchModels.LoteSkipSamples = {
                            cliente: { clienteId: '', faena: { faenaId: '', nombre: '', numeroSap: '', transporte: '' }, numeroSap: '', razonSocial: '' },
                            estado: "available",
                            fecha: '',
                            laboratorioId: '',
                            loteId: responseSetBatch.data.loteId,
                            muestras: [muestra.muestraId],
                            traces: [],
                            usuario: { nombre: '', userId: '' }
                        }
                        this.batchProvider.updateBatchsWithoutSamples(batch);
                    } else {
                        lote[0].muestras = muestras;
                        this.batchProvider.updateBatchsWithoutSamples(lote[0]);
                    }

                    resolve(responseSetBatch)
                } else {
                    reject(responseSetBatch)
                }
                this.batchProvider.getBatchSkipSamples();
            }).catch(error => {
                reject(error);
            });

        })
    }


    private setMuestraReceivePromise(muestra: SolicitudAnalisisModels.RegistroMuestra, solicitud: SolicitudAnalisisModels.SolicitudAnalisis) {
        let req: SolicitudAnalisisModels.SetMuestraReceivedRequest = {
            userId: this.authService.currentUserValue.userInfo.userId,
            solicitudAnalisisId: solicitud.solicitud.solicitudAnalisisId,
            muestraId: muestra.muestraId,
            clienteId: solicitud.solicitud.cliente.clienteId,
            faenaId: solicitud.solicitud.cliente.faena.faenaId,
            estado: muestra.estado || 'Recepcionada',
            observaciones: muestra.observaciones || ''
        };

        return this.service.SetMuestraReceived(req)

    }

    esCreadaEnLaboratorio() {
        return this.model.solicitud.solicitud.tipo === "backoffice_create";
    }

    // get puedeIngresarFrasco():boolean{
    //     return true;
    //     // return  (this.usuario.roles?.includes('administrador') && this.esCreadaEnLaboratorio())===true  && this.model?.estado === 'registered'
    // }

    // get puedeRecibirFrasco():boolean{
    //    return (  this.model &&  this.model.solicitud.solicitud.muestras.filter(m=>!m.estado || m.estado==='registered' ).length > 0 && (this.model.solicitud.estado === 'received' || this.model.solicitud.estado  ==='received_observations')) === true
    // }

    goBack() {
        this.location.historyGo();
    }


}
