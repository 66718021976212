import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/auth/authentication.service';
import {MatMenuTrigger} from '@angular/material/menu';

@Component({
    selector: 'ds-header',
    templateUrl: './nav-header.component.html'
})
export class NavHeaderComponent {

  @Output() toggle = new EventEmitter<boolean>();
  collapsed:boolean = false;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;

  constructor(
    private router: Router,
    public authService: AuthenticationService) {
  }

  logOut(): void {
    this.authService.logout();
  }

  handToggle() {
    this.collapsed = !this.collapsed;
    this.toggle.emit(this.collapsed);
  }
}
