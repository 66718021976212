<div class="bar-container" [ngbTooltip]="tipContent" container="body">
  <span class="bar-state waiting" [style.flex-basis.%]="pendingApprovalSize"></span>
  <span class="bar-state analysis" [style.flex-basis.%]="inAnalysisSize"></span>
  <span class="bar-state reported" [style.flex-basis.%]="reportedSize"></span>
</div>

<ng-template #tipContent>
  <div class="d-flex flex-column align-items-start text-start p-1">
    <div class="d-flex align-items-center gap-2"><span class="dot waiting"></span>{{ states.pending_approval }} esperando aprobación</div>
    <div class="d-flex align-items-center gap-2"><span class="dot analysis"></span>{{ states.in_analysis }} en análisis</div>
    <div class="d-flex align-items-center gap-2"><span class="dot reported"></span>{{ states.reported }} reportados</div>
  </div>
</ng-template>
