<div [class]="{'bloqueado': is_request_call }">
  <mat-spinner matSuffix [diameter]="18" class="center" *ngIf="is_request_call"></mat-spinner>

  <div class="content__head align-items-start" *ngIf="model">
    <div class="content__letterhead mb-0 mb-md-0">
      <h2 class="d-flex gap-2 align-items-center mb-0">
        <!-- <em class="ico ico-sample-2 me-2"></em> -->
        <b>Solicitud de análisis</b>
      </h2>
    </div>
    <div class="content__tracking">
      <a class="link link--icon" [mat-dialog-close]="true">
        <em class="bi bi-x-lg"></em>
      </a>
    </div>
  </div>

  <div class="content__head align-items-start" *ngIf="model">
    <div class="content__letterhead mb-1 mb-md-0">
      <span class="c-gray">Cliente</span>
      <ng-container *ngIf="!model.cargandoSolicitud">
        <strong class="mb-2">{{model.solicitud.solicitud.cliente.nombre}}</strong>
        <small class="c-gray mb-1">Faena {{model.solicitud.solicitud.cliente.faena.nombre}} (código {{model.solicitud.solicitud.cliente.faena.numeroSap}})</small>
        <small class="c-gray">el {{model.solicitud.solicitud.fechaCreacion }} a las {{model.solicitud.solicitud.horaCreacion }} hrs</small>
      </ng-container>
      <ng-container *ngIf="model.cargandoSolicitud">
        <div class="d-flex">
          Cargando solicitud...<mat-spinner matSuffix [diameter]="18"></mat-spinner>
        </div>
      </ng-container>
    </div>
    <div class="content__tracking">
      <div class="border rounded p-3 d-flex flex-column align-items-center ms-md-3">
        <span class="mb-2">Muestras</span>
        <strong class="text-md" *ngIf="!model.cargandoSolicitud">{{model.solicitud.solicitud.muestras.length}}</strong>
        <mat-spinner matSuffix [diameter]="18" *ngIf="model.cargandoSolicitud"></mat-spinner>
      </div>
      <div class="border rounded p-3 d-flex flex-column align-items-center ms-3">
        <span class="mb-2">Solicitud de Análisis</span>
        <strong class="text-md" *ngIf="!model.cargandoSolicitud">{{model.solicitud.solicitud.solicitudAnalisisId}}</strong>
        <mat-spinner matSuffix [diameter]="18" *ngIf="model.cargandoSolicitud"></mat-spinner>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between flex-nowrap-align-items-center">
    <div class="content__search flex-md-fill">
      <input class="input input--search input--clear" type="text" placeholder="Buscar">
    </div>
    <div class="content__actions flex-md-fill justify-content-md-end gap-3">
      <a class="btn btn--small btn--first" href="#" [routerLink]="" *ngIf="model.puedeIngresarFrasco" (click)="openDialogAddBottle()">Ingresar Frasco</a>
      <a class="btn btn--small btn--first" href="#" [routerLink]="" *ngIf="model.puedeRecibirFrasco" (click)="openDialogRequestBottle()">Recibir Frasco</a>
    </div>
  </div>
  <div class="content__container mat-mdc-table mt-1">
    <div class="table-responsive my-3 p-1  ">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2 w-100 ">
        <ng-container matColumnDef="sample">
          <th mat-header-cell *matHeaderCellDef>Frasco</th>
          <td mat-cell *matCellDef="let muestra">
            <div class="d-flex align-items-center">
              <span class="fig me-2">
                <em class="ico ico-sample ico--small"></em>
                <span class="fig__badge fig__badge--warning" *ngIf="muestra.lubricante?.lubricanteId === '0'">
                  <em class="bi bi-exclamation-triangle-fill ico--small"></em>
                </span>
              </span>
              <div class="d-flex flex-column">
                <a href="javascript:void(0);" (click)="showModalSample(muestra);" [routerLink]="" class="link">{{muestra.muestraId}}</a>

                <span class="c-gray" *ngIf="muestra.lubricante?.lubricanteId !== '0'">{{muestra.lubricante?.lubricante || ''}}</span>
                <span class="c-gray" *ngIf="muestra.lubricante?.lubricanteId === '0'">{{muestra.otroLubricante}}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="numLabs">
          <th mat-header-cell *matHeaderCellDef> Número de laboratorio </th>
          <td mat-cell *matCellDef="let muestra">
            <b>{{muestra.correlativo}}</b>
          </td>
        </ng-container>

        <ng-container matColumnDef="component">
          <th mat-header-cell *matHeaderCellDef> Componente </th>
          <td mat-cell *matCellDef="let muestra">
            <div class="d-flex flex-column">
              <b>{{muestra.componente.componenteId}}</b>
              <span class="c-gray">{{muestra.componente.tipoComponente.nombre}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="machine">
          <th mat-header-cell *matHeaderCellDef> Equipo </th>
          <td mat-cell *matCellDef="let muestra">
            <div class="d-flex flex-column">
              <b>{{muestra.componente.componenteId}}</b>
              <span class="c-gray">{{muestra.componente.equipo?.tipoEquipo?.nombre || ''}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex justify-content-between">
              <span class="me-3">{{element.observaciones?statusJar['received_observations'] :statusJar[element.estado] || 'Registrada'}}</span>
              <em class="bi bi-exclamation-triangle-fill text--warning" *ngIf="element.observaciones" [matTooltip]="element.observaciones"></em>
              <em class="bi bi-x-lg text--error" *ngIf="element.estado == 'not_received'"></em>
              <em class="bi bi-check-lg text--success" *ngIf="element.estado == 'Recepcionada'"></em>
              <!-- <button class="btn btn--icon-only p-0 c-danger" *ngIf="!element.correlativo">
              <em class="bi bi-trash-fill ico--small"></em>
            </button> -->
              <a href="javascript:void(0);" mat-button [matMenuTriggerFor]="options" #menuTrigger *ngIf="!element.estado || element.estado.toUpperCase() == 'REGISTRADA'">
                <em class="bi bi-three-dots-vertical"></em>
              </a>
              <mat-menu #options="matMenu">
                <mat-selection-list #shoes [multiple]="false">
                  <mat-list-option>
                    <div mat-line (click)="noLlego(element)">No llegó</div>
                  </mat-list-option>
                </mat-selection-list>
              </mat-menu>
            </div>
          </td>
        </ng-container>        

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="9">
            <div class="d-flex justify-content-center" *ngIf="model.cargandoSolicitud && model.solicitud.solicitud.muestras.length == 0">
              Cargando...&nbsp;<mat-spinner matSuffix [diameter]="18"></mat-spinner>
            </div>
            <div class="d-flex justify-content-center" *ngIf="!model.cargandoSolicitud">
              No se han encontrado frascos...
            </div>
          </td>
        </tr>
      </table>
    </div>

  </div>
  <div class="d-flex align-items-center" *ngIf="contieneLubNuevo()">
    <span class="fig me-3">
      <em class="ico ico-sample"></em>
      <span class="fig__badge fig__badge--warning">
        <em class="bi bi-exclamation-triangle-fill"></em>
      </span>
    </span>
    <small class="c-gray">Corresponde a una nueva marca o variante de lubricante que no existe actualmente en los sistemas de Copec.</small>
  </div>

</div>