import { HttpContextToken } from "@angular/common/http";

export namespace Enums {

  export const SPINNER_ENABLED = new HttpContextToken<boolean>(() => true);

  export enum MaintainerView {
    List = 0,
    New = 1,
    Edit = 2
  };
  export enum EstadosSolicitud {
    in_process = 'En proceso',
    registered = 'Registrada',
    pending = 'Pendiente',
    pending_ot = 'Pendiente OT',
    courier = 'En courier',
    received = 'Recibida',
    received_observations = 'Recibida c/ observaciones',
    scheduled = 'Programada',
    reported = 'Reportada'
  }

  export enum EstadosMuestra {
    registered = 'Registrada',
    received = 'Recepcionada',
    received_observations = 'Recepcionada c/ observaciones',
    not_received = 'No recepcionada',
    assigned_batch = 'Asignada a lote',
    in_analysis = 'En análisis',
    pending_approval = 'Pendiente de aprobación',
    reported = 'Reportada',
    repeat_analysis = 'En repetición de análisis',
    stored = 'Almacenada',
    final_disposition = 'Disposición final',
    in_process = 'En proceso',
    scheduled = 'Programada',
  }

  export enum EstadosLote {
    available = 'Disponible',
    in_analysis = 'En análisis',
    reported = 'Reportado',
    stored = 'Reportado',
    final_disposition = 'Disposición final',
    pending = 'Pendiente',
    created = 'Creado',
    sending = 'Enviado',
    registered = 'Registrado'
  }

  export enum EstadosMuestraEnLote {
    pending = 'Pendiente',
    in_analysis = 'En análisis',
    reported = 'Reportado',
    created = 'Creado',
    sending = 'Enviado',
    registered = 'Registrado',
    assigned_batch = 'Ingresado al lote',
    pending_approval = 'Pendiente de aprobación',
    repeat_analysis = 'En repetición de análisis'


  }

  export enum ResultadoGeneralEnsayo {
    true = 'Detecta',
    false = 'No Detecta'
  }

  export enum EstadosFechaMuestra {
    in_analysis = 'Enviada al laboratorio',
    assigned_batch = 'Frasco ingresado al lote',
    received = 'Frasco recepcionado',
    received_observations = 'Frasco recepcionado',
    pending_approval = 'Análisis finalizado',
    reported = 'Reportado'
  }

  export enum EstadosDeResultado {
    analisis = 'En análisis',
    normal = 'Normal',
    precaucion = 'Precaución',
    alerta = 'En alerta',
  }

  export enum CourierEnum {
    correoschile = 'Correos de Chile',
    chileexpress = 'Chilexpress',
    jaacs = 'Transportes Jaacs',
  }

  export enum StatusValueSample {
    no_iniciado = 'No iniciado',
    iniciado = 'Iniciado',
    manual = 'Ingreso manual',
    detenido = 'Detenido',
    falta_iniciar = 'Falta iniciar',
    cargado = 'Cargado',
    no_realizable = 'Cancelado',
  }

}
