import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { StorageTools } from '../tools/storage.tools';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService, 
        private storage: StorageTools,
        private oauthService: OAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        if (this.oauthService.hasValidAccessToken() && request.url.includes(environment.apiUrl) && !request.url.endsWith('/token') && !request.url.endsWith('/userinfo') && !request.url.endsWith('/openid-configuration')) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.oauthService.getAccessToken()}`
                }
            });
        } else {            
            // this.authenticationService.logout();
        }
        return next.handle(request);
    }
}
