import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModalConfirmModels } from '../ng-modal-confirm-models/ng-modal-confirm.models';

/**
 * @title Dialog Overview
 */
@Component({
  selector: 'ng-modal-confirm',
  templateUrl: './ng-modal-confirm.component.html',
  styleUrls: ['./ng-modal-confirm.component.css']
})
export class NgModalConfirmComponent {
  @Input() public data:NgModalConfirmModels.NgModalConfirmData={title:'',msg:'',titleButtonOK:'Confirmar',buttonClassname:'btn-danger'};


  constructor(
    public dialogRef: NgbActiveModal){}

    cancelar() {
      this.closeModal();
    }

    confirmar(){
      this.dialogRef.close(true);
    }
    
    closeModal() {
      this.dialogRef.close(false);
    }
  
  }

