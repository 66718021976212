import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ds-state-bar',
    templateUrl: './state-bar.component.html',
    styleUrls: ['./state-bar.component.css'],
})
export class StateBarComponent implements OnInit {
    constructor() {}
    @Input() states: any = [];
    reportedSize: number = 0;
    inAnalysisSize: number = 0;
    pendingApprovalSize: number = 0;

    ngOnInit(): void {
        const calculatePercentage = (numerator: number, denominator: number) =>
            numerator && denominator ? (numerator / denominator) * 100 : 0;
        this.reportedSize = calculatePercentage(this.states?.reported, this.states?.total);
        this.inAnalysisSize = calculatePercentage(this.states?.in_analysis, this.states?.total);
        this.pendingApprovalSize = calculatePercentage(this.states?.pending_approval, this.states?.total);
    }
}
