import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-selector-state',
    templateUrl: './selector-state.component.html',
    styleUrls: ['./selector-state.component.css'],
})
export class SelectorStateComponent {
    @Input() disabled!: boolean;
    @Input() estado!: boolean;
    @Output() estadoChange = new EventEmitter<boolean>();

    onToggleChange(event: MatSlideToggleChange): void {
        this.estado = event.checked;
        this.estadoChange.emit(this.estado);
    }
}
