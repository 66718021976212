
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
    selector: '[emailValidation]',
    providers: [{provide: NG_VALIDATORS, useExisting: EmailValidationDirective, multi: true}]
})
export class EmailValidationDirective implements Validator {
    constructor(){}

    validate(control: AbstractControl): ValidationErrors | null {
        return  this.esValido(control.value)(control);
    }  
    
    esValido(input: string): ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          const forbidden =  input !== '' ?  this.check(input) :false;
          return forbidden ? {emailValidation: {value: control.value}} : null;
        };
    }

    private check(value: string,):boolean {
            let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return !regexp.test(value);
 
    }
}