import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchService } from 'src/app/modules/batch/services/batch.services';
import { SampleModels } from '../../models/sample.models';
import { SampleViewModels } from '../../models/sample.view.models';
import { ManualEntryResultsComponent } from '../manual-entry-results/manual-entry-results.component';
import { SampleService } from '../../services/sample.services';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../shared/auth/authentication.service';
import { Enums, Objects } from 'src/app/shared';
import { E, O, R } from '@angular/cdk/keycodes';
import * as moment from 'moment';
import { EventEmitterService } from '../../../../shared/services/event-emitter.service';
import { Constants } from 'src/app/shared/models/constants';
import { BatchModels } from 'src/app/modules/batch/models/batch.models';
import { EditSampleComponent } from '../../views/edit-sample/edit-sample.component';
import { NavigationExtras, Router } from '@angular/router';
import { DetailComponent } from 'src/app/modules/solicitud-analisis/view/detail/detail.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Sampletools } from 'src/app/shared/tools/sample.tools';

@Component({
    selector: 'app-sample-detail',
    templateUrl: './sample-detail.component.html',
    styleUrls: ['./sample-detail.component.css'],
})
export class SampleDetailComponent implements OnInit, AfterViewInit {
    refreshDetailBatch: boolean = false;
    is_request_call: boolean = false;
    load = false;
    sampleOpenState = false;
    stepOpenState = false;
    planOpenState = false;
    visibleCollpase: boolean = false;
    subscribers: { name: string; sub: Subscription; active?: boolean }[] = [];
    value: string = '0';
    current_color: String = '#C5D0D5';
    sampleTools: Sampletools = new Sampletools();

    model: SampleViewModels.ViewModel = {
        plansAnalisis: {},
        registroFrasco: {
            enviadoLaboratorio: '',
            ingresadoLote: '',
            analisisFinalizado: '',
        },
        analysis: [],
        results: [],
        traduccion: {
            resultadoEnsayo: Enums.ResultadoGeneralEnsayo,
        },
        fechaActual: {
            fecha: '',
            tipo: '',
        },
        enumFechas: Enums.EstadosFechaMuestra,
        planResultados: {},
    };

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<SampleDetailComponent>,
        private servicePlan: BatchService,
        private serviceSample: SampleService,
        public storageSrv: StorageService,
        public authService: AuthenticationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private eventSrv: EventEmitterService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            muestra: SampleModels.RegistroMuestra;
        }
    ) {
        this.model.planResultados[this.data.muestra.muestraId] = this.data.muestra.planAnalisis;
        this.checkAnalysis();
    }

    ngOnInit(): void {
        this.eventSrv.event(this.eventSrv.eventNames.UPDATED_SAMPLE).subscribe((sample) => {
            if (this.data.muestra.muestraId === sample.muestraId && this.model.plansAnalisis) {
                this.data.muestra = sample;
                this.joinData();
            }
        });
        this.loadData();
    }

    private loadData() {
        let promises = [];
        this.is_request_call = true;
        let showSpinner = this.organizeInputResults();
        promises.push(this.getPlans({ force: true, spinner: showSpinner, ignoreStore: true }));
        promises.push(this.getResults({ force: true, spinner: showSpinner, ignoreStore: true }));
        promises.push(this.getSample({ force: true, spinner: showSpinner, ignoreStore: true }));

        Promise.all(promises)
            .then(() => {
                this.joinData();
            })
            .finally(() => {
                this.is_request_call = false;
            });

        this.data.muestra.traces?.forEach((t) => {
            if (t.fecha.indexOf('T') === -1) {
                t.fecha = moment(t.fecha, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DDTHH:mm');
            }
        });
        this.model.registroFrasco.enviadoLaboratorio = !this.data.muestra.traces?.find((t) => t.estado == 'in_analysis')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'in_analysis')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.model.registroFrasco.ingresadoLote = !this.data.muestra.traces?.find((t) => t.estado == 'assigned_batch')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'assigned_batch')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.model.registroFrasco.recibido = !this.data.muestra.traces?.find(
            (t) => t.estado == 'received' || t.estado == 'received_observations'
        )?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'received' || t.estado == 'received_observations')?.fecha).format(
                'YYYY-MM-DDTHH:mm'
            );
        this.model.registroFrasco.analisisFinalizado = !this.data.muestra.traces?.find((t) => t.estado == 'pending_approval')?.fecha
            ? ''
            : moment(this.data.muestra.traces?.find((t) => t.estado == 'pending_approval')?.fecha).format('YYYY-MM-DDTHH:mm');
        this.getLastDate();
    }

    private organizeInputResults() {
        let containsResults: boolean = true;
        containsResults = !!(this.data.muestra.resultados && this.data.muestra.planAnalisis.ensayos);
        let showSpinner = !containsResults;
        if (containsResults) {
            this.data.muestra.planAnalisis.ensayos?.forEach((ensayo) => {
                let resultados = this.data.muestra.resultados?.filter((r) => r.ensayo.ensayoId === ensayo.ensayoId);
                if (resultados && resultados.length >= 1) {
                    let ultimoResultado = resultados[resultados.length - 1];
                    ensayo.result = ultimoResultado;
                } else {
                    ensayo.result = this.data.muestra.resultados?.find((r) => r.ensayo.ensayoId === ensayo.ensayoId);
                }
            });
        }
        return showSpinner;
    }

    ngOnDestroy(): void {
        this.destroySubs();
    }

    getSample(
        opts: {
            force: boolean;
            fnCallback?: () => {};
            spinner: boolean;
            ignoreStore?: boolean;
            fnContinue?: (requests: SampleModels.GetMuestraRequest[]) => boolean;
        } = { force: true, spinner: false, ignoreStore: true }
    ) {
        return new Promise((resolve) => {
            let request: SampleModels.GetMuestraRequest = {
                laboratorioId: this.authService.currentUserValue.userInfo.dependency[0].laboratorioId,
                muestraId: this.data.muestra.muestraId,
            };
            opts.fnContinue = (requests: SampleModels.GetMuestraRequest[]) => {
                return !requests.find((req) => req.muestraId === request.muestraId);
            };

            this.addSubscribe(
                'muestras',
                this.serviceSample.GetMuestras(request, opts).subscribe((response) => {
                    if (response.code === 200 && response.data.length > 0 && response.cacheReq.muestraId === request.muestraId) {
                        // console.log("si corresponde")

                        let muestra = response.data[0];
                        muestra.planAnalisis = this.data.muestra.planAnalisis;
                        this.data.muestra = muestra;
                        //this.eventSrv.sendResult(this.eventSrv.eventNames.UPDATED_SAMPLE,muestra);
                    } else {
                        // console.log("no corresponde")
                    }
                    if (response.code !== -1) {
                        resolve(response.success);
                    }
                })
            );
        });
    }

    getPlans(
        opts: { force: boolean; fnCallback?: () => {}; spinner: boolean; ignoreStore?: boolean; queue?: boolean } = {
            force: false,
            spinner: true,
        }
    ) {
        return new Promise((resolve) => {
            opts.queue = true;
            this.addSubscribe(
                'plans',
                this.servicePlan
                    .GetPlanAnalisis(
                        {
                            planAnalisisId: this.data.muestra.planAnalisis.planAnalisisId,
                        },
                        opts
                    )
                    .subscribe((response) => {
                        if (response.code == 200 && response.cacheReq.planAnalisisId === this.data.muestra.planAnalisis.planAnalisisId) {
                            this.model.plansAnalisis = {};
                            response.data.forEach((plan) => {
                                this.model.plansAnalisis[plan.planAnalisisId + ''] = plan;
                            });
                            resolve(response.success);
                        }
                    })
            );
        });
    }

    getResults(
        opts: { force: boolean; fnCallback?: () => {}; spinner: boolean; ignoreStore: boolean; queue?: boolean } = {
            force: false,
            spinner: true,
            ignoreStore: true,
        }
    ) {
        return new Promise((resolve) => {
            opts.queue = true;
            this.addSubscribe(
                'results',
                this.serviceSample.GetResultados({ muestraId: this.data.muestra.muestraId }, opts).subscribe((response) => {
                    if (response.code === 200 && response.cacheReq.muestraId === this.data.muestra.muestraId) {
                        this.model.results = response.data;
                        resolve(response.success);
                    }
                })
            );
        });
    }

    joinData(): void {
        if (!this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId]) {
            return;
        }
        this.model.planResultados[this.data.muestra.muestraId] = Object.assign(
            [],
            this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId]
        );

        //this.data.muestra.planAnalisis.ensayos =  Object.assign([],this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId].ensayos);

        /** si la muestra se encuentra en estado reportada se filtran los ensayos a solo los que se utilizaron */
        this.checkAnalysis();
        this.model.planResultados[this.data.muestra.muestraId].ensayos?.forEach((ensayo) => {
            // ensayo.result = this.model.results.find(result=>result.ensayo.ensayoId===ensayo.ensayoId)
            let resultados = this.data.muestra.resultados?.filter((r) => r.ensayo.ensayoId === ensayo.ensayoId);
            if (resultados && resultados.length >= 1) {
                let ultimoResultado = resultados[resultados.length - 1];
                ensayo.result = ultimoResultado;
            } else {
                ensayo.result = this.data.muestra.resultados?.find((r) => r.ensayo.ensayoId === ensayo.ensayoId);
            }
        });
        this.load = true;
    }

    /**
     * Verifica si los ensayos estan de acuerdo al estado de la muestra, de ser reportada solo considera ensayos con resultados.
     */
    private checkAnalysis() {
        if (this.data.muestra.estado === Constants.Muestra.estado.reported) {
            this.model.planResultados[this.data.muestra.muestraId].ensayos = this.model.planResultados[
                this.data.muestra.muestraId
            ].ensayos?.filter((e) => this.data.muestra.resultados?.some((r) => r.ensayo.ensayoId == e.ensayoId));
            this.data.muestra.resultados?.forEach((r) => {
                if (
                    !this.model.planResultados[this.data.muestra.muestraId].ensayos?.some((ensayo) => ensayo.ensayoId === r.ensayo.ensayoId)
                ) {
                    this.model.planResultados[this.data.muestra.muestraId].ensayos?.push(r.ensayo);
                }
            });
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.visibleCollpase = true;
        }, 200);
    }

    showModalResultEntry(ensayo: SampleModels.Ensayo) {
        if (!this.isAdmin) {
            return;
        }
        // console.log(this.authService.currentUserValue.userInfo.roles)
        if (this.data.muestra.estado && ['in_analysis'].indexOf(this.data.muestra.estado) !== -1) {
            this.is_request_call = true;
            delete ensayo.muestras;
            const modal = this.dialog.open(ManualEntryResultsComponent, {
                panelClass: 'ds-submodal',
                disableClose: true,
                data: {
                    numero: 1,
                    total: 1,
                    muestras: [this.data.muestra],
                    ensayo: ensayo,
                },
            });
            modal.afterClosed().subscribe((response) => {
                // console.log('response.status:',response.status);
                this.is_request_call = false;
                switch (response.status) {
                    case 'saved':
                        this.is_request_call = true;
                        this.refreshDetailBatch = true;
                        setTimeout(() => {                            
                            this.getSample({ force: true, spinner: true, ignoreStore: true }).finally(() => {
                                this.model.planResultados[this.data.muestra.muestraId].ensayos?.forEach((ensayo) => {
                                    let muestra_resultados = this.data.muestra.resultados?.filter((r) => r.ensayo.ensayoId === ensayo.ensayoId);
                                    if (muestra_resultados && muestra_resultados.length >= 1) {
                                        let ultimo_Resultado = muestra_resultados[muestra_resultados.length - 1];
                                        ensayo.result = ultimo_Resultado;
                                    } else {
                                        ensayo.result = this.data.muestra.resultados?.find((r) => r.ensayo.ensayoId === ensayo.ensayoId);
                                    }
                                });
                                this.is_request_call = false;
                            });
                        }, 3000);
                        break;
                    case 'cancel':
                        this.loadData();
                        break;

                    default:
                        break;
                }
                this._changeDetectorRef.detectChanges();
            });
        }
    }

    close() {
        //this.data.muestra.resultados = this.model.results;
        this.dialogRef.close({ data: this.data, refresh: this.refreshDetailBatch });
    }

    private getLastDate(): void {
        let fechas = this.data.muestra.traces?.filter((t) => t.estado == this.data.muestra.estado);
        if (fechas && fechas.length != 0) {
            if (fechas[0].estado === 'reported') {
                this.model.fechaActual = {
                    fecha: moment(this.data.muestra.fechaInforme).format('YYYY-MM-DDTHH:mm'),
                    tipo: fechas[0].estado,
                };
            } else {
                this.model.fechaActual = {
                    fecha: (fechas[0].fecha.indexOf('T') === -1
                        ? moment(fechas[0].fecha, 'DD/MM/YYYY HH:mm:ss')
                        : moment(fechas[0].fecha)
                    ).format('YYYY-MM-DDTHH:mm'),
                    tipo: fechas[0].estado,
                };
            }
        }
    }

    private addSubscribe(name: string, sub: Subscription) {
        this.destroySubs(name);
        this.subscribers.push({ name: name, sub: sub });
    }
    private destroySubs(name?: string) {
        this.subscribers
            .filter((s) => !name || s.name === name)
            .forEach((s) => {
                s.sub.unsubscribe();
                s.active = false;
            });
    }
    get percent(): number {
        let total = this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter((e) => e.result?.stamp !== 'descartar').length;
        let resolved = this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter(
            (e) => e.result && (e.result.stamp == 'cargado' || e.result.stamp == 'no_realizable')
        ).length;

        let percent: number = 0;
        0;
        if (total && resolved) {
            percent = (resolved * 100) / total;
        }
        return percent;
    }

    get isAdmin() {
        return (
            this.authService.currentUserValue.userInfo.roles.filter(
                (rol) => ['encargado_laboratorio', 'analista_laboratorio'].indexOf(rol) > -1
            ).length > 0
        );
    }

    edit() {
        const navigationExtras: NavigationExtras = {
            queryParams: { id: this.data.muestra.muestraId },
            skipLocationChange: true,
        };

        // Navegación a la ruta de edición con la ID
        this.router.navigate(['/muestra/editar'], navigationExtras);
        this.dialog.closeAll();
    }

    childsTabReport: { [ref: string]: { bottleNumber: string; tab: Window; sample?: SampleModels.RegistroMuestra } } = {};

    public getPdf(): void {
        const ref = new Date().getTime();
        const url = '/reports2?ref=' + ref;
        const muestra: SampleModels.RegistroMuestra = this.data.muestra;
        let tab = window.open(url, '_blank');
        if (tab) {
            this.childsTabReport[ref] = { tab: tab, bottleNumber: muestra.muestraId, sample: muestra };
        }
        window.focus();
    }

    @HostListener('window:message', ['$event'])
    onMessage(e: any) {
        if (e.data.action === 'showReport' && e.origin === window.location.origin) {
            switch (e.data.event) {
                case 'init':
                    let ref = this.childsTabReport[e.data.ref];
                    // console.log("ref", ref)
                    if (ref) {
                        ref.tab.window.postMessage(
                            { action: 'loadReport', bottleNumber: ref.bottleNumber, sample: ref.sample },
                            window.location.origin
                        );
                    }
                    break;
                default:
                    break;
            }
        }
    }

    verSolicitud() {
        const solicitud: any = { solicitud: { solicitudAnalisisId: this.data.muestra.solicitudAnalisis } };
        this.storageSrv.setData('solicitud', solicitud);
        this.dialog.open(DetailComponent, {
            disableClose: true,
            panelClass: 'icon-outside',
            // panelClass: 'full-screen-modal',
            maxWidth: '90vw',
            width: '90%',
            data: { solicitudAnalisisId: this.data.muestra.solicitudAnalisis },
        });
    }

    verLote() {
        this.router.navigate([`/lotes/${this.data.muestra.loteId}`]);
        this.dialogRef.close();
    }

    resultadoEnPlanActual(ensayo: SampleModels.Ensayo) {
        const ensa = this.model.plansAnalisis[this.data.muestra.planAnalisis.planAnalisisId].ensayos?.find(
            (e) => e.ensayoId === ensayo.ensayoId
        );
        if (!ensa) {
            return false;
        }

        let result = this.data.muestra.resultados?.filter((re) => re.ensayo.ensayoId == ensayo.ensayoId) ?? [];

        result.sort((a, b) => {
            return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
        });
        if (result.length && result[result.length - 1].stamp == 'descartar' && this.data.muestra.estado != 'reported') {
            return false;
        }
        return true;
    }
    getFilteredEnsaysLength(): number {
        if (!this.model.planResultados[this.data.muestra.muestraId]?.ensayos) {
            return 0; // or whatever default value you want to return
        }

        return this.model.planResultados[this.data.muestra.muestraId].ensayos?.filter((e) => e.result?.stamp !== 'descartar').length ?? 0;
    }
}
