import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SubscribersTools } from "../tools/subscribers.tools";
import { RequestCounterTools } from '../tools/request-counter.tools';

import { StateSampleModels } from "src/app/modules/state-sample/models/state-sample.models";
import { StateSampleService } from "src/app/modules/state-sample/services/state-sample.services";

@Injectable({
    providedIn: 'root'
})
export class MuestraAnalisisProvider {
    subsTools: SubscribersTools = new SubscribersTools();

    muestras_analisis: {
        data: StateSampleModels.Muestra[];
        reqCounter: RequestCounterTools;
        subject: Subject<StateSampleModels.Muestra[]>
    };

    muestras_analisis_list: StateSampleModels.Muestra[] = [];
    muestrasAnalisisFinish: boolean = false;
    muestrasAnalisisAux: StateSampleModels.Muestra[] = [];

    constructor(
        private service: StateSampleService,
    ) {
        this.muestras_analisis = {
            data: [],
            reqCounter: new RequestCounterTools(),
            subject: new BehaviorSubject(this.muestras_analisis_list)
        }
    }

    public getMuestrasAnalisis(laboratorioId: string, refresh: boolean = false) {
        let rq: StateSampleModels.GetMuestrasPorEstadoRequest = {
            estado: "in_analysis",
            laboratorioId: laboratorioId,
            page_size: 50
        }
        if (refresh) {
            this.muestras_analisis.data = [];
            this.muestras_analisis.subject.next(this.muestras_analisis.data)
        }
        if (!this.muestras_analisis.reqCounter.inProgress) {
            this.muestrasAnalisis(rq, refresh);
        }
        return this.muestras_analisis.subject;
    }

    private muestrasAnalisis(rq: StateSampleModels.GetMuestrasPorEstadoRequest, refresh: boolean = false) {
        this.muestras_analisis.reqCounter.startRequest();
        let key = "";
        this.subsTools.addSubscribe('getMuestrasEnAnalisis', this.service.GetMuestrasEnAnalisis(rq, {
            force: true, spinner: false, ignoreStore: true
            , fnCallback: () => {
                if (key != "") {
                    rq.page_key = key;
                    this.muestrasAnalisis(rq);
                } else {
                    this.muestrasAnalisisFinish = true;
                    this.setMuestrasAnalisisStorage(this.muestrasAnalisisAux);
                    this.muestrasAnalisisAux = [];
                }
                this.muestras_analisis.reqCounter.endRequest();
            }
        })
            .subscribe(r => {
                if (r.code == 200) {
                    this.muestrasAnalisisAux = [...this.muestrasAnalisisAux, ...r.data.muestras];

                    if (!this.muestrasAnalisisFinish || this.muestras_analisis.data.length == 0) {
                        this.setMuestrasAnalisisStorage(this.muestras_analisis.data.concat(r.data.muestras));
                    }

                    if (r.data.page_key) {
                        key = r.data.page_key;
                    }
                }
            }));
    }

    private setMuestrasAnalisisStorage(sol: StateSampleModels.Muestra[]) {
        this.muestras_analisis.data = sol.sort((a, b) => {
            return (Number(b.correlativo) - Number(a.correlativo))
        });
        this.muestras_analisis.subject.next(this.muestras_analisis.data);
    }

}