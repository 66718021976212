export const environment = {
  production: false,
  fusionAuth: {
    uri: 'https://copec-sa.fusionauth.io',
    clientID: '7f3ece02-ed3d-46fa-aed4-5692bbe43aa8',
    redirectURI: window.location.origin + "/oauth-callback",
  },
  authConfig: {
    issuer: 'https://copec-sa.fusionauth.io',
    clientId: '7f3ece02-ed3d-46fa-aed4-5692bbe43aa8',
    redirectUri: window.location.origin + "/oauth-callback",
    responseType: 'code',
    requireHttps: true,
    timeoutFactor: 0.9,
    scope: 'openid profile email offline_access',
    strictDiscoveryDocumentValidation: true,
    showDebugInformation: false,
    oidc: true,
    disableAtHashCheck: true,
    nonceStateSeparator: '&&',
    customQueryParams : { 'flag_go': 'g', 'locale': 'ES_CL'}

  },
  apiUrl: 'https://as3elvhaj7.execute-api.us-west-2.amazonaws.com/stg/api/v1',
  routes: {
    getMuestraInfo: '/muestra/info',
    token: '/token',
    getUserInfo: '/userinfo',
    getClientes: '/clientes',
    solicitudAnalisisList: '/solicitudanalisis/list',
    solicitudAnalisisListAdm: '/solicitudanalisis/list/adm',
    solicitudAnalisis: '/solicitudanalisis',
    solicitudAnalisisReceived: '/solicitudanalisis/received',
    solicitudAnalisisRegister: '/solicitudanalisis/register',
    // muestraReceived: '/muestra/received',
    prioridades: '/prioridades',
    componentes: '/componentes',
    moveComponentes: '/componente/move',
    solicitudAnalisisUpload: '/solicitudanalisis/upload',
    muestraValid: '/muestra/validid',
    batchs: '/batchs',
    ensayos: '/ensayos',
    plananalisis: '/plananalisis',
    tipocomponentes: '/tipocomponentes',
    tipoequipos: '/tipoequipo',
    equipos: '/equipo',
    lubricantes: '/lubricantes',
    categorias: '/lubricantes/categorias',
    muestraResultados: '/muestra/resultados',
    muestraResultadosGroup: '/muestra/resultados/group',
    muestraResultadosBool: '/muestra/resultados/bool',
    protocolos: '/protocolos',
    protocolospersonalizado: '/protocolos/personalizado',
    protocolospersonalizadoReporte:'/protocolo/personalizado/reporte',
    muestra: '/muestra',
    muestra_recepcion: '/muestra/recepcion',
    reportHistorico: '/muestra/reporte/historico',
    componenteReporte: '/componente/reporte',
    rubros: '/rubros',
    usuario:'/usuario',
    usersAdmin: '/usuarios/admin',
    usersCopec: '/usuarios/copec',
    laboratorios: '/laboratorios',
    comentarios: '/comentarios',
    imageSave: '/file/urlpresigned',
    prereport: '/prereport',
    report: '/report',
    updateSample: '/muestra/update',
    prereportAprove: '/prereport/approve',
    precios: '/facturacion/precios',
    zonas: '/zonas',
    getReglaCancelacionAuto:'/regla-cancelacion-auto',
    log: '/activity-log',
    facturacion: {
      get_muestras_dv: '/facturacion/muestras-dv',
      documento_ventas: '/facturacion/documento-ventas',
      inf_prefacturacion: '/facturacion/inf-prefacturacion',
      pedido_liberar: '/facturacion/pedido-liberar',
      reporte_docs_vtas: '/facturacion/reporte-documento-ventas',
      send_documento_ventas: '/facturacion/send-documento-ventas',
      reporte_de_provision: '/facturacion/reporte-de-provision',


    },
    solicitantes: '/solicitantes',
    buscarMuestra: '/muestra/buscar'
  },
  external: {
    instrumentos: 'https://coqainstlub.copec.cl/conectividadlaboratorio/secure?username=1',
    // tableau: 'https://tableau.copec.cl/#/signin?redirect=%2Fsite%2FLaboratorioLubricantes%2Fviews%2FLubricanteLaboratoriofiltrousuario%2FPortada%3F:iid%3D1',
    tableau: 'https://tableau.copec.cl/t/LaboratorioLubricantes/views/PortadaPrueba/Portada?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link'
  },
  datadog: {
    app: 'edf64606-9050-48b3-bc74-26c8ef52e7db',
    token: 'pubf6de7f958353598da7b2620262cba46e',
    service: 'npl',
    env: 'stg',
    enabled: false,
  },
  config: {
    min_date: "2000-12-31",
    api: {
      tiempoGeneralEspera: 120000
    }
  }
};
