import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Enums, Tools } from "src/app/shared";
import { ServiceSubjectTimeProvide } from "src/app/shared/tools/service-subject-provider.tools";
import { ServiceSubjectTime } from "src/app/shared/tools/service-subject-time.tools";
import { environment } from "src/environments/environment";
import { ClientsChoresModels } from "../models/clientsChores.model";

@Injectable({
    providedIn: 'root'
})
export class ClientsChoresService {

    subjetGetListClients: ServiceSubjectTime<ClientsChoresModels.GetClientsChoresRequest, ClientsChoresModels.GetClientsChoresResponse>;
    subjetGetListClientesFaenas: ServiceSubjectTime<ClientsChoresModels.GetClientsChoresRequest, ClientsChoresModels.GetClientsChoresResponse>;
    subjetGetClientById: ServiceSubjectTime<ClientsChoresModels.GetClientsChoresRequest, ClientsChoresModels.GetClientsChoresResponse>;
    subjetGetListClientsIds: ServiceSubjectTime<ClientsChoresModels.GetClientsChoresRequest, ClientsChoresModels.GetClientsChoresResponse>;
    subjetGetListPlans: ServiceSubjectTime<ClientsChoresModels.GetPlanesAnalisisRequest,ClientsChoresModels.GetPlanesAnalisisResponse>;
    subjetGetListRubros: ServiceSubjectTime<ClientsChoresModels.GetRubrosRequest,ClientsChoresModels.GetRubrosResponse>;
    subjetGetListZones: ServiceSubjectTime<ClientsChoresModels.GetZonesRequest,ClientsChoresModels.GetZonesResponse>
    subjetGetFaenas: ServiceSubjectTime<ClientsChoresModels.GetFaenasRequest, ClientsChoresModels.GetFaenasResponse>;

    constructor(
        serviceSubject: ServiceSubjectTimeProvide,
        private http: HttpClient,
        private tools: Tools,
    ) {
        this.subjetGetListClients = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getClientes}`);
        this.subjetGetListClientesFaenas = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getClientes}`);
        this.subjetGetClientById = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getClientes}`);
        this.subjetGetListClientsIds = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.getClientes}`);
        this.subjetGetListPlans = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.plananalisis}`);
        this.subjetGetListRubros = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.rubros}`);
        this.subjetGetListZones = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.zonas}`);
        this.subjetGetFaenas = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.solicitantes}`);

    }

    GetClientes(request: ClientsChoresModels.GetClientsChoresRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetClientsChoresResponse> {
        return this.subjetGetListClients._httpGet(request, {force: forceRefresh, spinner: false,ignoreStore: forceRefresh});
    }

    GetClientesFaenas(request: ClientsChoresModels.GetClientsChoresRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<ClientsChoresModels.GetClientsChoresResponse> {
        return this.subjetGetListClientesFaenas._httpGet(request,opts);
    }

    GetClienteById(request: ClientsChoresModels.GetClientsChoresRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetClientsChoresResponse> {
        return this.subjetGetClientById._httpGet(request, {force: forceRefresh, spinner: false,ignoreStore: forceRefresh});
    }

    GetClientesIds(request: ClientsChoresModels.GetClientsChoresRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetClientsChoresResponse> {
        return this.subjetGetListClientsIds._httpGet(request, {force: forceRefresh, spinner: false,ignoreStore: forceRefresh});
    }

    GetFaenas(request: ClientsChoresModels.GetFaenasRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetFaenasResponse> {
        return this.subjetGetFaenas._httpGet(request, {force: forceRefresh, spinner: false,ignoreStore: forceRefresh});
    }

    SetCliente(request: ClientsChoresModels.SetClientsChoresRequest, opts: { spinner: boolean } = { spinner: true }): Promise<ClientsChoresModels.SetClientsChoresResponse> {
        return this.http.post<ClientsChoresModels.SetClientsChoresResponse>(`${environment.apiUrl}${environment.routes.getClientes}`, request.cliente, { context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    DeleteCliente(request: ClientsChoresModels.DelClientRequest, opts: { spinner: boolean } = { spinner: true }): Promise<ClientsChoresModels.DelClientResponse> {
        return this.http.delete<ClientsChoresModels.DelClientResponse>(`${environment.apiUrl}${environment.routes.getClientes}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise();
    }

    //Planes
    GetPlanesAnalisis(request: ClientsChoresModels.GetPlanesAnalisisRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetPlanesAnalisisResponse> {
        return this.subjetGetListPlans._httpGet(request, {force: forceRefresh, spinner: false});
    }

    //Rubros
    GetRubros(request: ClientsChoresModels.GetPlanesAnalisisRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetRubrosResponse> {
        return this.subjetGetListRubros._httpGet(request, {force: forceRefresh, spinner: false});
    }


    GetZones(request: ClientsChoresModels.GetZonesRequest, forceRefresh: boolean = false): Observable<ClientsChoresModels.GetZonesResponse> {
      return this.subjetGetListZones._httpGet(request, {force: forceRefresh, spinner: false});
    }

}