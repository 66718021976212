import { LOCALE_ID, NgModule } from '@angular/core';
import es from '@angular/common/locales/es';

import { CommonModule, APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MomentModule } from 'ngx-moment';

import { MaterialModule } from './material.module';

// Aqui comienza los import propios
import { JwtInterceptor } from './shared/auth/jwt.interceptor';
import { ErrorInterceptor } from './shared/auth/error.interceptor';
import { StorageTools } from './shared/tools/storage.tools';
import { Tools } from './shared';
import { Pkce } from './shared/tools/pkce.tools';
import { MatModalConfirmComponent } from './shared/components/mat-dialog-content/mat-dialog-content.component';
import { NgModalConfirmComponent } from './shared/components/ng-modal-confirm/ng-modal-confirm-views/ng-modal-confirm.component';
import { ToastsContainer } from './shared/components/ng-toast/toasts-container.component';
import { MatSpinnerOverlayComponent } from './shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';
import { BasicLayoutModule } from './modules/layouts/basic-layout/basic-layout.module';
import { MainLayoutModule } from './modules/layouts/main-layout/main-layout.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SharedModule } from './shared/shared.module';
import { PaginatorModule } from './shared/paginatorModule.module';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { LocalStorageOAuthStorage } from './shared/auth/local-storage-oauth-storage';

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    MatSpinnerOverlayComponent,
    MatModalConfirmComponent,
    NgModalConfirmComponent,
    ToastsContainer
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MomentModule,
    ZXingScannerModule,
    MomentModule,
    PaginatorModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BasicLayoutModule,
    MainLayoutModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true,
      },
    })
  ],
  providers: [
    { provide: OAuthStorage, useClass: LocalStorageOAuthStorage },
    { provide: LOCALE_ID, useValue: 'es-*' },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    Storage,
    Tools,
    Pkce,
    StorageTools
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
