import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-cancel-auto-info',
  templateUrl: './modal-cancel-auto-info.html',
  styleUrls: ['./modal-cancel-auto-info.css']
})
export class ModalCancelAutoInfo{

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    public dialogRef: MatDialogRef<ModalCancelAutoInfo>,
    @ Inject(MAT_DIALOG_DATA) public data:string[]
  ) { }



  confirmUpdateSample(){


    this.dialogRef.close('si');
  }

}
