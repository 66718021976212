import { Directive, HostListener, Input } from '@angular/core';
@Directive({
    selector: '[idDirective]'
})
export class IdDirective {

    regexStr = /^[-A-Z_0-9]*$/g;
    
    @HostListener('keypress', ['$event']) onKeyPress(event:any) {
        return new RegExp(this.regexStr).test(event.key);
    }
    @HostListener('paste', ['$event']) onPaste(e:any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if(!new RegExp(this.regexStr).test(pasteData)){
            e.preventDefault();
        }
    }       
}