<div class="modal__contanier">
  <div class="modal__header">
    <h2 id="modal_recepcion_frasco_title">Recibir frasco</h2>
    <a href="javascript:void(0);" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content">
    <div class="d-flex mb-5 flex-column align-items-center">
      <p>Número del frasco.</p>
      <form class="form" action="">
        <input id="modal_recepcion_frasco_muestra_id" type="text" class="input input--large" maxlength="9" name="digitOne" #digitOne (keyup)="onDigitInput($event)"  [(ngModel)]="model.digitOne" validaMuestraId decimals="0">
      </form>

      <div class="mt-3" *ngIf="data && data.msgError"><p class="text-danger">{{data.msgError}}</p></div>
    </div>
    <div class="alert alert--info mb-3">
      <figure class="alert__figure"><em class="bi bi-upc-scan"></em></figure>
      <div class="alert__message"><span class="d-block mb-2">Si dispones de un scanner de códigos, te recomendamos utilizarlo ahora para evitar errores.</span><span>El ingreso manual del código no permite que lo pegues desde el porta papeles, por seguridad.</span></div>
    </div>
  </div>
  <div class="modal__foot justify-content-end"><button id="modal_recepcion_frasco_continuar" class="btn btn--small btn--first"  (click)="continuar()">continuar</button></div>
</div>
