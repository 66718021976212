import { Directive, HostListener, Input } from '@angular/core';
@Directive({
    selector: '[nameDirective]'
})
export class NameDirective {

    regexStr = /^[-a-zA-Z_0-9 ñÑ  ()]*$/g;
    
    @HostListener('keypress', ['$event']) onKeyPress(event:any) {
        return new RegExp(this.regexStr).test(event.key);
    }
    @HostListener('paste', ['$event']) onPaste(e:any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if(!new RegExp(this.regexStr).test(pasteData)){
            e.preventDefault();
        }
    }       
}