<mat-slide-toggle
  color="primary"
  class="custom-slide-toggle"
  [disabled]="disabled"
  [(ngModel)]="estado"
  (change)="onToggleChange($event)"
>
  <span class="custom-textInactivo" *ngIf="!estado"> {{" No" }}</span>
  <span class="custom-textActivo" *ngIf="estado">{{ "Si" }}</span>

</mat-slide-toggle>
