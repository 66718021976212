import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Enums, Tools } from "src/app/shared";
import { environment } from "src/environments/environment";
import { SolicitudAnalisisModels } from "../models/solicitud-analisis.models";
import { ServiceSubjectTime } from '../../../shared/tools/service-subject-time.tools';
import { ServiceSubjectTimeProvide } from '../../../shared/tools/service-subject-provider.tools';

@Injectable({
    providedIn: 'root'
})
export class SolicitudAnalisisService {

    subjetGeSolicitudes:ServiceSubjectTime<SolicitudAnalisisModels.GetSolicitudesListRequest,SolicitudAnalisisModels.GetSolicitudesListResponse>;
    subjetGetSolicitudesAdm:ServiceSubjectTime<SolicitudAnalisisModels.GetSolicitudesAdmRequest, SolicitudAnalisisModels.GetSolicitudAnalisisAdmResponse>;
    subjetGetSolicitudesReceived:ServiceSubjectTime<SolicitudAnalisisModels.GetSolicitudesListRequest, SolicitudAnalisisModels.GetSolicitudesStatusResponse>;
    subjetGetSolicitudesReceivedObs:ServiceSubjectTime<SolicitudAnalisisModels.GetSolicitudesListRequest, SolicitudAnalisisModels.GetSolicitudesStatusResponse>;

    value: SolicitudAnalisisModels.GetSolicitudesListResponse | undefined;
    sub: Subject<SolicitudAnalisisModels.GetSolicitudesListResponse>;
    lastTime: number = new Date().getTime();
    maxInterval: number = environment.config.api.tiempoGeneralEspera;

    constructor(
        private http: HttpClient,
        private tools: Tools,
        serviceSubject:ServiceSubjectTimeProvide

    ) {
        this.value = { code: -1, data: [], message: '', success: true };
        this.sub = new BehaviorSubject(this.value);
        this.subjetGeSolicitudes = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.solicitudAnalisisList}`);
        this.subjetGetSolicitudesAdm = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.solicitudAnalisisListAdm}`);
        this.subjetGetSolicitudesReceived = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.solicitudAnalisisList}`);
        this.subjetGetSolicitudesReceivedObs = serviceSubject.getServiceSubject(`${environment.apiUrl}${environment.routes.solicitudAnalisisList}`);
    }

    GetSolicitudesList(request: SolicitudAnalisisModels.GetSolicitudesListRequest, opts:{force:boolean, fnCallback?:()=>{}, spinner: boolean,ignoreStore?:boolean } = {force: false, spinner: false}): Observable<SolicitudAnalisisModels.GetSolicitudesListResponse> {
        return this.subjetGeSolicitudes._httpGet(request,opts)
    }


    GetSolicitud(request: SolicitudAnalisisModels.GetSolicitudAnalisisRequest,opts:{spinner:boolean}={spinner:true}) : Promise<SolicitudAnalisisModels.GetSolicitudAnalisisResponse> {
        return this.http.get<SolicitudAnalisisModels.GetSolicitudAnalisisResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisis}`, { params: this.tools.toHttpParams(request),context: new HttpContext().set(Enums.SPINNER_ENABLED, opts.spinner) }).toPromise()
        ;
    }


    SetSolictudReceived(request: SolicitudAnalisisModels.SetSolicitudAnalisisReceivedRequest) : Promise<SolicitudAnalisisModels.SetSolicitudAnalisisReceivedResponse> {
        return this.http.post<SolicitudAnalisisModels.SetSolicitudAnalisisReceivedResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisisReceived}`, request).toPromise()
        ;
    }

  
    SetMuestraReceived(request: SolicitudAnalisisModels.SetMuestraReceivedRequest) : Promise<SolicitudAnalisisModels.SetMuestraReceivedResponse> {
        return this.http.post<SolicitudAnalisisModels.SetMuestraReceivedResponse>(`${environment.apiUrl}${environment.routes.muestra_recepcion}`, request).toPromise()
        ;
    }

    RegisterSolicitudAnalisis(request: SolicitudAnalisisModels.RegisterSolicitudAnalisisRequest): Promise<SolicitudAnalisisModels.RegisterSolicitudAnalisisResponse> {
        return this.http.post<SolicitudAnalisisModels.RegisterSolicitudAnalisisResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisisRegister}`, request).toPromise();
    }

    // GetSolicitudesStatus(request: SolicitudAnalisisModels.GetSolicitudesListRequest, forceRefresh: boolean = false): Promise<SolicitudAnalisisModels.GetSolicitudesStatusResponse> {
    //     return this.http.get<SolicitudAnalisisModels.GetSolicitudesStatusResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisisList}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    // }

    GetPrioridades(request: SolicitudAnalisisModels.GetPriorityRequest): Promise<SolicitudAnalisisModels.GetPriorityResponse> {
        return this.http.get<SolicitudAnalisisModels.GetPriorityResponse>(`${environment.apiUrl}${environment.routes.prioridades}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    SetSolictudAnalisisUpload(request: SolicitudAnalisisModels.SetSolicitudAnalisisUploadRequest) : Promise<SolicitudAnalisisModels.SetSolicitudAnalisisUploadResponse> {
        return this.http.post<SolicitudAnalisisModels.SetSolicitudAnalisisUploadResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisisUpload}`, request).toPromise()
        ;
    }

    setMuestraValid(request:SolicitudAnalisisModels.SetMuestraValidRequest): Observable<SolicitudAnalisisModels.SetMuestraValidResponse> {
        return this.http.post<SolicitudAnalisisModels.SetMuestraValidResponse>(`${environment.apiUrl}${environment.routes.muestraValid}`, request);
    }


    isMaxTimeStore():boolean{
        let rest =(new Date()).getTime()-this.lastTime;
        return this.maxInterval <rest;
    }

    GetSolicitudesAdm(request: SolicitudAnalisisModels.GetSolicitudesAdmRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Promise<SolicitudAnalisisModels.GetSolicitudAnalisisAdmResponse> {
        // return this.subjetGetSolicitudesAdm._httpGet(request,opts)
        return this.http.get<SolicitudAnalisisModels.GetSolicitudAnalisisAdmResponse>(`${environment.apiUrl}${environment.routes.solicitudAnalisisListAdm}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) }).toPromise();
    }

    GetSolicitudesReceived(request: SolicitudAnalisisModels.GetSolicitudesListRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<SolicitudAnalisisModels.GetSolicitudesStatusResponse> {
        return this.subjetGetSolicitudesReceived._httpGet(request,opts)
    }

    GetSolicitudesReceivedObs(request: SolicitudAnalisisModels.GetSolicitudesListRequest,opts:{ force: boolean, fnCallback?: () => void, spinner: boolean, ignoreStore?: boolean } = {force: false, spinner: false, ignoreStore: false}): Observable<SolicitudAnalisisModels.GetSolicitudesStatusResponse> {
        return this.subjetGetSolicitudesReceivedObs._httpGet(request,opts)
    }

    RegisterMuestra(request : SolicitudAnalisisModels.MuestraRecepcionRequest):Observable<SolicitudAnalisisModels.MuestraRecepcionResponse> {
        return this.http.post<SolicitudAnalisisModels.MuestraRecepcionResponse>(`${environment.apiUrl}${environment.routes.muestra_recepcion}`, request,{context: new HttpContext().set(Enums.SPINNER_ENABLED, true) });
    }
}