import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SnackBarService{


    constructor(
        private _snackBar: MatSnackBar,
    ){

    }

    
    public updateSuccess(msg:string){
        this.success(msg)

    }

    public success(msg:string){
        this._snackBar.open(msg, 'X', {
            duration: 5000,
            panelClass: [`success-snackBar`],
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        });
    }

    public deleteSuccess(msg:string){
           this.danger(msg)
    }

    public danger(msg:string){
        this._snackBar.open(msg, 'X', {
            duration: 5000,
            panelClass: [`danger-snackBar`],
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        });
    }

    public warning(msg:string, duration: number = 5000){

        this._snackBar.open(msg, 'X', {
            duration: duration,
            panelClass: [`warning-snackBar`],
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        });
    }
        
}