<div class="modal__contanier">
  <div class="modal__header">
    <h2>Cancelar ingreso</h2>
    <a href="#" [routerLink]="" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content justify-content-center d-flex">
    <div class="mw-50 text-center">
      <p>¿Deseas cancelar el ingreso del resultado para este y los siguientes ensayos?</p>
      <p>Los resultados que ya ingresaste ya quedaron guardados.</p>
    </div>
  </div>
  <div class="modal__foot px-4 pb-4 pt-0 justify-content-center align-items-center flex-nowrap justify-content-md-end flex-column flex-md-row gap-3">
    <a class="link d-flex align-items-center  order-1 order-md-0" href="javascript:void(0)" [mat-dialog-close]="false" (click)="continue()">
      <span>NO, CONTINUAR</span>
    </a>
    <a class="btn btn--icon btn--small order-0 order-md-1 btn--danger" href="javascript:void(0)" (click)="cancel()">SI, CANCELAR</a>
  </div>
</div>
