import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Enums, Tools } from 'src/app/shared';
import { Observable,  Subject, BehaviorSubject } from 'rxjs';
import { ComponenteModels } from '../models/componente.models';

@Injectable({
    providedIn: 'root'
})
export class ComponenteService {
    
       value:ComponenteModels.GetComponentesResponse|undefined;
      lastTime:number=new Date().getTime();

    constructor(
        private http: HttpClient,
        private tools: Tools) {
    }

    GetComponentes(request: ComponenteModels.GetComponentesRequest, forceRefresh: boolean = false): Observable<ComponenteModels.GetComponentesResponse> {            
       return  this.http.get<ComponenteModels.GetComponentesResponse>(`${environment.apiUrl}${environment.routes.componentes}`, { params: this.tools.toHttpParams(request), context: new HttpContext().set(Enums.SPINNER_ENABLED, false) });
    }
    
  
}
