import { Component, OnInit } from '@angular/core';
import { Objects } from 'src/app/shared';
import { ReglasCancelacionAutoService } from 'src/app/shared/services/reglas-cancelacion-auto.service';
import { StorageTools } from 'src/app/shared/tools/storage.tools';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {

  collapsed:boolean = false;

  constructor(private reglasCancelAutoService: ReglasCancelacionAutoService,private storage: StorageTools) { }

  ngOnInit(): void {
    this.getRelasCancelacion();
  }
  getToggle(value:boolean){
    this.collapsed = value;
  }

  getRelasCancelacion(){
    let req: Objects.GetReglaCancelacionRequest = {
    }
    this.reglasCancelAutoService.GetReglas(req).subscribe((response) => {
        if (response.code === 200) {
            this.storage.setItem('reglasCancelacion', response.data);
        }
    });
  }

}
