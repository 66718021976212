
import { Pipe, PipeTransform } from '@angular/core';
import { ReportsModels } from 'src/app/modules/reports-preview/models/reports.models';

@Pipe({ name: 'limit_report' })
export class FormatLimitDirective implements PipeTransform {


  transform(value: { ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra }) {
    return this.displayFormatNumberLimits(value.ensayo, value.muestra);
  }


  displayFormatNumberLimits(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): string {
    let result = '';
    let ultimoResultado = this.ultimoResultado(ensayo, muestra)
    let limites = ultimoResultado?.estado?.limites;
    if (!ultimoResultado || !limites) {
      return result;
    }
    let min = Number(limites.min);
    let max = Number(limites.max)

    switch (ensayo.code) {
      case '11':
      case '3':
        result += min ? this.numberWithDot(min) : '';
        break;
      default:
        result = this.generalFormatLimit(min, max);
        break;
    }

    return result;
  }

  generalFormatLimit(min: number, max: number): string {
    let op = min > 0 && max > 0 ? 'LIMITS' : min > 0 ? 'ONLY_MIN' : 'ONLY_MAX';
    let result = '';
    switch (op) {
      case 'ONLY_MIN':
        result +=this.numberWithDot(min);
        break;
      case 'ONLY_MAX':
        result += this.numberWithDot(max);
        break;
      case 'LIMITS':
        //result += this.reduceNumber(min) + ' | ' + this.reduceNumber(max);
        result += this.numberWithDot(min) + ' | ' + this.numberWithDot(max);
        break;
      default:
        break;
    }
    return result;
  }

  ultimoResultado(ensayo: ReportsModels.Ensayo, muestra?: ReportsModels.Muestra): ReportsModels.Resultado | undefined {
    let resultados = muestra && muestra.resultados ? muestra.resultados : [];
    resultados = resultados.filter(r => r.ensayo.ensayoId === ensayo.ensayoId);
    let ultimoResultado = resultados?.sort((a, b) => {
      if (a.ensayo.ensayoId < b.ensayo.ensayoId) { return -1; }
      if (a.ensayo.ensayoId > b.ensayo.ensayoId) { return 1; }
      return 0;
    }).pop();
    return ultimoResultado
  }

  reduceNumber(value: number): string {
    let G = 1000000000
    let M = 1000000;
    let K = 1000
    let result = '';


    if (value >= K) {
      if (value >= M) {
        if (value >= G) {
          result = String(value / G) + 'G'
        } else {
          result = String(value / M) + 'M'
        }
      } else {
        result = String(value / K) + 'K'
      }
    } else {
      result = String(value);
    }
    return result;

  }

  numberWithDot(x:number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}

 