import { Injectable } from '@angular/core';
import { Enums, Objects } from 'src/app/shared';
import { Constants } from 'src/app/shared/models/constants';

@Injectable({ providedIn: 'root' })
export class Sampletools {

    enumStatus: { [key: string]: string } = Enums.EstadosDeResultado;

    public statusSample(sample: Objects.RegistroMuestra): { label: string, key: string } {

        let state: string = sample.resultados?.length === 0 ? 'analisis' : 'normal';
        let countAlert: number = 0;
        let countCaution: number = 0;

        sample.resultados?.filter(r => r.stamp === 'cargado').forEach(r => {
            countAlert = r.estado?.status === 'alerta' ? countAlert + 1 : countAlert;
            countCaution = r.estado?.status === 'precaucion' ? countCaution + 1 : countCaution;
        })

        state = countAlert > 0 ? 'alerta' : countCaution > 0 ? 'precaucion' : state;

        return { key: state, label: this.enumStatus[state] };
    }

    /**
     * Marca los lotes y muestras que tienen repeticion de ensayos
     * @param lotes
     * @param planesAnalisis
     */
    public checkRepeatAnalysisByBatchs(lotesAny: any[], planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        let lotes = <Objects.Lote[]>lotesAny;
        lotes.forEach(lote => {
            lote.repiteEnsayos = lote.muestras.some(muestra => {
                muestra = this.checkRepeatAnalysis(muestra, planesAnalisis)
                if (muestra.repiteEnsayos) {
                    //// console.log(`muestra ${muestra.correlativo} repite`)
                }
                return muestra.repiteEnsayos;
            })
            if (lote.repiteEnsayos) {
                //// console.log(`lote ${lote.loteId} tiene repetición`)
            }
        })
    }

    /**
     * Marca la muestra si tiene repeticion de ensayos
     * @param muestra
     * @param planesAnalisis
     * @returns
     */
    public checkRepeatAnalysis(muestra: Objects.RegistroMuestra, planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        muestra.repiteEnsayos = muestra.estado !== Constants.Muestra.estado.reported && planesAnalisis[muestra.planAnalisis.planAnalisisId].ensayos.some(ensayo => {
            let repite = this.hasRepetition(muestra, ensayo);
            if (repite) {
                //// console.log(`muestra: ${muestra.muestraId} repite ensayo ${ensayo.nombre}`)
            }
            return repite;

        });
        return muestra;
    }


    public checkRepeatResult(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo) {


       return this.hasRepetition(muestra,ensayo);
    }


    public checkRepeatAnalysisByBatchsLastNotLoaded(lotesAny: any[], planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        let lotes = <Objects.Lote[]>lotesAny;
        lotes.forEach(lote => {
            lote.repiteEnsayos = lote.muestras.some(muestra => {
                muestra = this.checkRepeatAnalysisLastNotLoaded(muestra, planesAnalisis)
                if (muestra.repiteEnsayos) {
                    //// console.log(`muestra ${muestra.correlativo} repite`)
                }
                return muestra.repiteEnsayos;
            })
            if (lote.repiteEnsayos) {
                //// console.log(`lote ${lote.loteId} tiene repetición`)
            }
        })
    }


    public checkRepeatAnalysisLastNotLoaded(muestra: Objects.RegistroMuestra, planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        muestra.repiteEnsayos = muestra.estado !== Constants.Muestra.estado.reported && planesAnalisis[muestra.planAnalisis.planAnalisisId].ensayos.some(ensayo => {
            let repite = this.hasRepetitionLastNotLoaded(muestra, ensayo);
            if (repite) {
                //// console.log(`muestra: ${muestra.muestraId} repite ensayo ${ensayo.nombre}`)
            }
            return repite;

        });
        return muestra;
    }

    public checkRepeatResultLastNotLoaded(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo) {


        return this.hasRepetitionLastNotLoaded(muestra, ensayo);
    }

    public checkRepeatAnalysisByBatchsLastLoaded(lotesAny: any[], planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        let lotes = <Objects.Lote[]>lotesAny;
        lotes.forEach(lote => {
            lote.repiteEnsayos = lote.muestras.some(muestra => {
                muestra = this.checkRepeatAnalysisLastLoaded(muestra, planesAnalisis)
                if (muestra.repiteEnsayos) {
                    //// console.log(`muestra ${muestra.correlativo} repite`)
                }
                return muestra.repiteEnsayos;
            })
            if (lote.repiteEnsayos) {
                //// console.log(`lote ${lote.loteId} tiene repetición`)
            }
        })
    }


    public checkRepeatAnalysisLastLoaded(muestra: Objects.RegistroMuestra, planesAnalisis: { [key: string]: Objects.PlanAnalisisEnsayos }) {
        muestra.repiteEnsayos = muestra.estado !== Constants.Muestra.estado.reported && planesAnalisis[muestra.planAnalisis.planAnalisisId].ensayos.some(ensayo => {
            let repite = this.hasRepetitionLastLoaded(muestra, ensayo);
            if (repite) {
                //// console.log(`muestra: ${muestra.muestraId} repite ensayo ${ensayo.nombre}`)
            }
            return repite;

        });
        return muestra;
    }

    public checkRepeatResultLastLoaded(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo) {


        return this.hasRepetitionLastLoaded(muestra, ensayo);
    }


    /**
     * Busca si el ensayo de la muestra se encuentra en estado por repetir
     * @param muestra
     * @param ensayo
     * @returns
     */

    private hasRepetitionLastNotLoaded(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo): boolean {
        let result = this.getFilterResult(ensayo, muestra);
        return result.all.length > 1 && result.last?.stamp !== 'cargado' && result.last?.stamp !== 'no_realizable';
    }

    private hasRepetitionLastLoaded(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo): boolean {
        let result = this.getFilterResult(ensayo, muestra);
        return result.all.length > 1 && result.last?.stamp === 'cargado' && result.all.filter(r => r?.stamp === 'cargado').length > 1;
    }


    private hasRepetition(muestra: Objects.RegistroMuestra, ensayo: Objects.Ensayo): boolean {
        let result = this.getFilterResult(ensayo, muestra);
        return result.all.length > 1 && !(result.last?.stamp === 'cargado' && result.all.filter(r => r?.stamp === 'cargado').length <= 1) && result.last?.stamp !== 'no_realizable';
    }

    public getFilterResult(ensayo: Objects.Ensayo, muestra: Objects.RegistroMuestra) {
        let resultados: Objects.Resultado[] = [];
        let resultadosClone: Objects.Resultado[] = [];
        if (ensayo && muestra) {
            resultados = muestra.resultados?.filter(r => r.ensayo.ensayoId === ensayo.ensayoId) || [];
            resultados.forEach(r => {
                if (resultadosClone.findIndex((x: Objects.Resultado) => x === r) === -1) {
                    resultadosClone.push(r);
                }
            });        }
        let ultimoResultado = resultadosClone.sort((a, b) => {
            if (a.resultadoId < b.resultadoId) { return -1; }
            if (a.resultadoId > b.resultadoId) { return 1; }
            return 0;
        }).pop();
        return { last: ultimoResultado, all: resultados };
    }
}
