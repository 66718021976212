import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SolicitudAnalisisModels } from '../../models/solicitud-analisis.models';

@Component({
  selector: 'app-receive-bottle',
  templateUrl: './receive-bottle.component.html',
  styleUrls: ['./receive-bottle.component.css']
})
export class ReceiveBottleComponent implements OnInit, AfterViewInit {
  @ViewChild('digitOne') digitOne: ElementRef | undefined;
   
   model:SolicitudAnalisisModels.solicitudIdDigits={
    digitOne:'',
    digitTwo:'',
    digitThree:'',
    digitFour:'',
    digitFive:'',
    digitSix:'',
    digitSeven:'',
    digitEight:''
  }
  constructor(
    public dialogRef: MatDialogRef<ReceiveBottleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {msgError:string}
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(()=>{ 
      this.digitOne?.nativeElement.focus();
    },600);  
  }
  onDigitInput(event:any){
    let element;

    if( -1 !== ['Enter','NumpadEnter'].indexOf(event.code)  && this.muestraId.length>0){
      this.continuar();
    }

    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace')
      element = event.srcElement.previousElementSibling;
    
   
    if(element == null || event.code  === 'Space' || !event.target.value  )
      return;
    else
      element.focus();
  }

  continuar(){
      this.dialogRef.close(this.muestraId);
  }

 
  get muestraId():string{
    return this.model.digitOne +  
    this.model.digitTwo +
    this.model.digitThree +
    this.model.digitFour + 
    this.model.digitFive +
    this.model.digitSix + 
    this.model.digitSeven + 
    this.model.digitEight 
  }



}
