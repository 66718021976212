import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';
@Directive({
    selector: '[rutValidation]',
    providers: [{provide: NG_VALIDATORS, useExisting: RutValidationDirective, multi: true}]
  })
  export class RutValidationDirective implements Validator {
   
    regexStr = /([0-9]|-|k|K)/g;
    @HostListener('keypress', ['$event']) onKeyPress(event:any) {
        return  new RegExp(this.regexStr).test(event.key);
    }
    @HostListener('paste', ['$event']) onPaste(e:any) {
        const pasteData = e.clipboardData.getData('text/plain');
        if(!new RegExp(this.regexStr).test(pasteData)){
            e.preventDefault();
        }
    }   
    
    validate(control: AbstractControl): ValidationErrors | null {
      return  this.esValido(control.value)(control);
    }  
    constructor(){

    }
    esValido(nroRut:string):ValidatorFn{
      console.log(nroRut);
      return (control: AbstractControl): {[key: string]: any} | null => {
        const forbidden = !this.validaRut(nroRut);
        return forbidden ? {validacionRut: {value: control.value}} : null;
      };
    }



    private validaRut(rut: string): boolean{
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rut ))
          return false;

      var tmp = rut.replace(/\./gi, '').split('-');
      var digv = tmp[1]; 
      var cuerpo = tmp[0];
      if (digv == 'K') digv = 'k' ;    
        return (this.getDv(cuerpo) == digv );
      }
    
      private getDv(T: any){
          var M=0,S=1;
          for(;T;T=Math.floor(T/10))
            S=(S + T % 10 * (9-M++ % 6)) % 11;
          return S ? S-1 : 'k';
      }
  }