<div class="modal__contanier">
  <div class="modal__header">
    <h2><b>¿Estás seguro?</b></h2>
    <a href="javascript:void(0);" [mat-dialog-close]="false">
      <em class="bi bi-x-lg"></em>
    </a>
  </div>
  <div class="modal__content">
    <p *ngFor="let item of data">{{item}}</p>
  </div>
  <div class="modal__foot pt-4 justify-content-between align-items-center flex-row gap-3 mx-2 mb-2">
    <a class="btn btn--icon btn--small btn--second order-1 order-md-0" href="javascript:void(0)" [mat-dialog-close]="false">CANCELAR</a>
    <a class="btn btn--icon btn--small btn--first order-0 order-md-1" href="javascript:void(0)" (click)="confirmUpdateSample();">CONFIRMAR</a>
  </div>
</div>
