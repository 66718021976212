import { Injectable } from '@angular/core';
import { Subject,  Subscription } from 'rxjs';
import { StateSampleModels } from 'src/app/modules/state-sample/models/state-sample.models';
import { StateSampleService } from '../../modules/state-sample/services/state-sample.services';
import { AuthenticationService } from '../auth/authentication.service';
import { BatchService } from '../../modules/batch/services/batch.services';
import { BatchModels } from 'src/app/modules/batch/models/batch.models';
import { SolicitudAnalisisService } from '../../modules/solicitud-analisis/services/solicitud-analisis.services';

@Injectable({
    providedIn: 'root'
})
export class CallRootService {

 
    constructor(
        public authService: AuthenticationService,
        private sampleService:StateSampleService,
        private batchService:BatchService,
        private analisysListService: SolicitudAnalisisService,


    ){

    }

    callServices(){    
       // this.getMuestras();
        // this.getBatchs();
        // this.getSolicitudes();

    }
    

    private getMuestras(){ 
         let request:StateSampleModels.GetMuestraRequest={
            laboratorioId:this.authService.currentUserValue.userInfo.dependency[0].laboratorioId      
          }
           this.sampleService.GetMuestrasPorAprobar(request).toPromise();
    }

    private getBatchs() {
        let req: BatchModels.GetLotesRequest = {
            laboratorioId:this.authService.currentUserValue.userInfo.dependency[0].laboratorioId      
        };
        this.batchService.GetBatchs(req).toPromise();
        this.batchService.GetPrioridades({});
        this.batchService.GetPlanesAnalisis({});  
    }

    private getSolicitudes(){
        this.analisysListService.GetSolicitudesList({});
    }

}